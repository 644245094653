import React, { useEffect, useRef, useState } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, CircularProgress, IconButton, Paper, Grid, Typography } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { POST } from "../../../../config/Api";
import { Constants, userRoles } from "../../../../config/Constants";
import { isLearnerView, useQueryParams } from "../../../../utils";
import ButtonMain from "../../../ButtonMain";
import ArtView from "../ArtView/ArtView";
import BlockActions from "../BlockActions/BlockActions";
import CancelIcon from "@mui/icons-material/Cancel";
import "./FillView.scss";
import { ToastNotify } from "../../../SnackBar/ToastNotify";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LearnerProperties from "../../LearnerProperties";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import sendDataToFirehose from "../../../../utils/aws-utils";
import clickEvent from "../../../../utils/logging-event-utils";
import { ReactComponent as DragDrop } from "../../../../assets/dragDrop.svg";

const MenuIconWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 10,
  right: 10,
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));
const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "right",
  justifyContent: "right",
  color: theme.palette.common.white,
}));

const AdvancedFillView = ({
  fill,
  courseDetails,
  blockUserActivities,
  setmodalOpen,
  setblockModOpen,
  block,
  disableBlock,
  handleBlockAnswer,
  isHighlight,
}) => {
  let UserData = useSelector((state) => state.userLogin);
  const moduleDetails = useSelector((state) => state.savedModuleDetail);
  const { cid } = useQueryParams();
  const themes = useTheme();
  const isMobile = useMediaQuery(themes.breakpoints.down("sm")); // Check for mobile view
  const [showActions, setShowActions] = useState(false);
  const { courseOne, completedModulesData, userDetails } = useOutletContext() || {};

  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(block?.is_hidden);
  const [disable, setDisable] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [checkUserInputs, setcheckUserInputs] = useState(false);
  const [fillsValue, setFillsValue] = useState([]);
  const [blockActivity, setBlockActivity] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [blanksValue, setBlanksValue] = useState(
    Array(fillsValue.length).fill(null), // Create an array with the same length as options
  );

  const handleDragEnd = (result) => {
    if (!result.destination) return; // Exit if dropped outside a valid destination.

    const { source, destination } = result;

    // Dragging from options to a blank
    if (source.droppableId === "options" && destination.droppableId.startsWith("blank")) {
      const blankIndex = parseInt(destination.droppableId.split("-")[1], 10);
      const draggedOption = fillsValue[source.index];

      // Get the existing option in the blank to return it to the options list
      const previousOptionInBlank = blanksValue[blankIndex];

      // Update blanksValue with the new option
      const updatedBlanks = [...blanksValue];
      updatedBlanks[blankIndex] = draggedOption;
      setBlanksValue(updatedBlanks);

      // Update options list: remove the dragged option and add back the previous one (if any)
      const updatedOptions = [...fillsValue.filter((_, index) => index !== source.index)];
      if (previousOptionInBlank) {
        updatedOptions.push(previousOptionInBlank);
      }
      setFillsValue(updatedOptions);
    }

    // Dragging from one blank to another
    if (source.droppableId.startsWith("blank") && destination.droppableId.startsWith("blank")) {
      const sourceIndex = parseInt(source.droppableId.split("-")[1], 10);
      const destinationIndex = parseInt(destination.droppableId.split("-")[1], 10);

      const updatedBlanks = [...blanksValue];
      const draggedOption = blanksValue[sourceIndex];

      // Swap between blanks
      updatedBlanks[destinationIndex] = draggedOption;
      updatedBlanks[sourceIndex] = null;
      setBlanksValue(updatedBlanks);
    }
  };
  const open = Boolean(anchorEl);
  const handleBlockProperties = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const toastRef = useRef();
  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};
  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);
  let location = useLocation();

  useEffect(() => {
    if (
      location.pathname.startsWith("/course/createblock") ||
      location.state?.userData?.role === userRoles.REVIEWER
    ) {
      setDisable(true);
    }
  }, [location]);

  useEffect(() => {
    const data = blockUserActivities?.data?.find((item) => item?.block_id === fill?._id);
    setBlockActivity(data);

    // Initialize fillsValue with all options from fill.fills
    const allFills = fill?.fills?.map((item) => {
      return { ...item, value: item?.value || "" };
    });

    // Initialize blanksValue with options from blockActivity (if any)
    const initialBlanks = Array(fill?.fills?.length).fill(null);
    if (data) {
      allFills.forEach((fillItem) => {
        const index = data.fills.findIndex((item) => item._id === fillItem._id);
        if (index !== -1) {
          initialBlanks[index] = { ...fillItem, text: fillItem.value || fillItem.text };
        }
      });
    }

    // Filter out options that are already in blanksValue
    const availableFills = allFills.filter(
      (item) => !initialBlanks.some((blank) => blank?._id === item._id),
    );

    setFillsValue(availableFills);
    setBlanksValue(initialBlanks);

    if (data) {
      setcheckUserInputs(true);
    } else {
      setcheckUserInputs(false);
    }
  }, [fill, blockUserActivities]);

  // useEffect(() => {
  //   const data = blockUserActivities?.data?.find((item) => item?.block_id === fill?._id);
  //   setBlockActivity(data);

  //   const newFills = fillsValue.map((item) => {
  //     const value = data?.fills.find((el) => el._id !== item._id);
  //     return { ...item, value: value?.value || value?.text };
  //   });

  //    setFillsValue(newFills);
  //   setBlanksValue(newFills);
  //   if (data) {
  //     setcheckUserInputs(true);
  //   } else {
  //     setcheckUserInputs(false);
  //   }
  // }, [blockUserActivities]);

  const handleFillSubmit = async () => {
    const result = blanksValue
      .filter((item) => item !== null) // Filter out null values (unfilled blanks)
      .map((item) => ({
        _id: item._id, // Use the _id from the dropped option
        value: item.text, // Use the text as the value
      }));

    if (isLoggedIn) {
      try {
        setDisable(true);
        setLoading(true);
        let body = {
          user_id: UserData?.data?.user?.id,
          course_id: cid,
          activity: "advancedFill",
          fills_value: result,
        };
        //Fill in the blank submit fill value
        await POST(`${Constants.saveAndUpdateUserActivities}/${fill?._id}`, body);
        toastRef.current.showToastMessage(
          "Your response has been submitted successfully.",
          "success",
        );
        triggerUserEvent(`${checkUserInputs ? "update" : "save"}`);
        setcheckUserInputs(true);
        handleBlockAnswer(fill._id);
        setDisable(false);
        setLoading(false);
        //save user last activity
        await POST(
          `${Constants.userActivity}?activity=${
            checkUserInputs ? "blockActivityUpdate" : "blockActivitySave"
          }`,
          {
            user: UserData?.data?.user,
          },
        );
      } catch (error) {
        toastRef.current.showToastMessage("Something went wrong.", "error");
        setLoading(false);
      }
    } else {
      window.location.replace(
        `${process.env.REACT_APP_AUTH_URL_FE}?referrer=${window.location.href}`,
      );
    }
  };

  const triggerUserEvent = (action) => {
    if (user?.email && moduleDetails?.data?.containerTitle && isLearnerView(location.pathname)) {
      const eventData = new clickEvent();
      eventData.event = "user_input";
      eventData.user_unique_id = user.email;
      eventData.parent_container_id = moduleDetails.data.id;
      eventData.block_id = block._id;
      eventData.course_id = courseOne._id;
      eventData.cohort_id = completedModulesData?.cohortDetails?._id;
      eventData.course_name = courseOne?.title;
      eventData.course_type = courseOne?.privacy;
      eventData.cohort_name = completedModulesData?.cohortDetails?.title;
      eventData.cohort_type = completedModulesData?.cohortDetails?.type;
      eventData.block_title = moduleDetails?.data?.containerTitle;
      eventData.block_action = action;
      eventData.user_response = fillsValue.map((item) => item.value).join(",");
      eventData.block_type = block?.type;

      // Ensure event tracking is enabled before sending
      if (completedModulesData?.cohortDetails?.eventTracking) {
        eventData.validateAndSendEvent(sendDataToFirehose);
      }
    }
  };

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setShowActions(!showActions);
  };

  const handleRemoveFromOption = (index) => {
    const removedOption = blanksValue[index];

    if (removedOption) {
      // Add the removed option back to the fillsValue list
      setFillsValue((prevOptions) => [...prevOptions, removedOption]);

      // Clear the corresponding blank
      const updatedBlanks = [...blanksValue];
      updatedBlanks[index] = null;
      setBlanksValue(updatedBlanks);
    }
  };

  const renderTextWithBlanks = () => {
    const parts = fill?.text.split(" _________________");
    return parts.map((part, index) => (
      <React.Fragment key={index}>
        <span dangerouslySetInnerHTML={{ __html: part }} />
        {index < parts.length - 1 && (
          <Droppable droppableId={`blank-${index}`}>
            {(provided) => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
                sx={{
                  display: "inline-block",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "250px",
                  height: "40px",
                  borderBottom: "2px dashed #ccc",
                  verticalAlign: "middle",
                  mx: 1,
                }}
              >
                {blanksValue[index] ? (
                  // Display the dragged option with a remove button
                  <>
                    <Typography
                      sx={{
                        ml: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        flex: 2,
                      }}
                    >
                      {blanksValue[index]?.text}
                      <IconButton
                        onClick={() => handleRemoveFromOption(index)}
                        sx={{
                          background:
                            "transparent url('img/Icon ion-close-circle.png') 0% 0% no-repeat padding-box",
                          opacity: 1,
                          color: "#cc23c",
                          p: 0,
                          mr: 1,
                          ml: 2,
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </Typography>
                  </>
                ) : (
                  // Allow typing the answer
                  "Drop Your answer here"
                )}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        )}
      </React.Fragment>
    ));
  };

  return (
    <>
      {(isModerator === -1 || isModerator === undefined) && block?.is_hidden ? (
        ""
      ) : (
        <Box
          position={"relative"}
          className={` container-block fill-block ${isHighlight && "highlightBlock"} 
      ${fill?.theme ? fill?.theme : "postBgColor"}
    `}
          style={{
            background: `${fill?.theme_type === "color" ? fill?.color : ""}`,
          }}
          onMouseOver={handleHover}
          onMouseLeave={handleMouseLeave}
        >
          {isModerator !== -1 && isLoggedIn && (isHovering || showActions) && (
            <BlockActions
              blockId={fill?._id}
              block={fill}
              setmodalOpen={setmodalOpen}
              setblockModOpen={setblockModOpen}
              hidden={hidden}
              setHidden={setHidden}
            />
          )}

          {isModerator !== -1 && block?.carousel_container && (
            <Image>
              <Tooltip title="This Block Tagged to Carousel Block">
                <LocalOfferRoundedIcon fontSize="large" color="primary" />
              </Tooltip>
            </Image>
          )}

          {isMobile && (
            <MenuIconWrapper onClick={handleMenuClick}>
              <MoreVertIcon fontSize="large" color="action" />
            </MenuIconWrapper>
          )}

          <DragDropContext onDragEnd={handleDragEnd}>
            <Box sx={{ p: { xs: 0, sm: 2, mb: 1 } }}>
              <Typography variant="h6" sx={{ mt: 1 }}>
                {fill?.is_required && <span className="required">*</span>}
                {fill?.title}
              </Typography>
              <Typography
                sx={{
                  textAlign: "left",
                  font: "italic normal normal 15px/24px Segoe UI",
                  letterSpacing: "0px",
                  color: "#9E9E9E",
                  mb: 1,
                }}
              >
                {" "}
                (Drag and drop your answer into the appropriate area below)
              </Typography>

              {fill?.text && <Typography sx={{ flex: 1 }}>{renderTextWithBlanks()}</Typography>}

              <Droppable droppableId="options" direction="horizontal">
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    sx={{
                      width: "100%",
                      mt: 2,
                    }}
                  >
                    <Grid container sx={{ justifyContent: "center" }}>
                      {fillsValue?.map((option, index) => (
                        <Draggable key={option._id} draggableId={option._id} index={index}>
                          {(provided) => (
                            <Grid
                              item
                              sm={3}
                              xs={6}
                              key={index}
                              sx={{
                                display: "flex",
                                flexDirection: "column", // Stack option and input box vertically
                                alignItems: "center",
                                p: 1,
                              }}
                            >
                              <Paper
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                sx={{
                                  cursor: "pointer",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  borderRadius: "10px",
                                  width: "100%",
                                  height: 50,
                                  background: "#E4EEFA 0% 0% no-repeat padding-box",
                                  boxShadow: "0px 2px 3px #00000029",
                                  opacity: 1,
                                }}
                              >
                                <Box
                                  {...provided.dragHandleProps}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "28px",
                                    height: "100%",
                                    backgroundColor: "#B1C6DF", // White background for the handle
                                    borderRadius: "4px 0 0 4px", // Rounded corners on the left
                                    mr: 1, // Margin to separate handle from text
                                  }}
                                >
                                  <DragDrop />
                                </Box>
                                {/* Text */}
                                <Typography
                                  variant="body2"
                                  sx={{
                                    flex: 1,
                                    textAlign: "left",
                                    ml: 1,
                                  }}
                                >
                                  {option?.text}
                                </Typography>
                              </Paper>
                            </Grid>
                          )}
                        </Draggable>
                      ))}
                    </Grid>
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>

              {/* <Grid container spacing={2} sx={{ mt: 2 }}>
                {blanksValue.map((blank, index) => (
                  <Droppable key={index} droppableId={`blank-${index}`}>
                    {(provided) => (
                      <Grid item xs={6} sm={3}>
                        <Box
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          sx={{
                            width: "100%",
                            height: 70,
                            border: "2px dashed #ccc",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            background: blank ? "#e9cae7b8" : "transparent",
                            overflow: "hidden",
                          }}
                        >
                          {blank ? (
                            <>
                              <Typography
                                sx={{
                                  ml: 1,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis", // Add ellipsis for overflowing text
                                  whiteSpace: "nowrap", // Prevent wrapping of text
                                  flex: 2, // Allow text to take remaining space
                                }}
                              >
                                {blank?.text}
                              </Typography>
                              <IconButton
                                onClick={() => handleRemoveFromOption(index)} // Remove individual item
                                sx={{
                                  ml: 1,
                                  background:
                                    "transparent url('img/Icon ion-close-circle.png') 0% 0% no-repeat padding-box",
                                  opacity: 1,
                                  color: "#D20000",
                                  p: 0,
                                  mr: 1,
                                }}
                              >
                                <CancelIcon />
                              </IconButton>
                            </>
                          ) : (
                            <Typography color="text.secondary" sx={{ ml: 2 }}>
                              Please Drop option here
                            </Typography>
                          )}

                          {provided.placeholder}
                        </Box>
                      </Grid>
                    )}
                  </Droppable>
                ))}
              </Grid> */}
            </Box>
          </DragDropContext>

          <ButtonMain
            onClick={handleFillSubmit}
            disabled={disable || disableBlock || !isLoggedIn}
            style={{ padding: `${loading ? "5px 25px" : ""}` }}
          >
            {loading ? <CircularProgress size={17} /> : checkUserInputs ? "Update" : "Save"}
          </ButtonMain>
          {userDetails?.role === userRoles.REVIEWER && (
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleBlockProperties}
              sx={{ float: "right" }}
            >
              <MoreVertIcon />
            </IconButton>
          )}
          <ArtView art={(block || fill)?.art} />
          <ToastNotify ref={toastRef} duration={3000} />
          {userDetails?.role === userRoles.REVIEWER && (
            <LearnerProperties
              anchorEl={anchorEl}
              open={open}
              handleClose={() => setAnchorEl(null)}
              obtainedMark={blockActivity?.credit}
              maxMark={block?.score || 0}
              blockType={block?.type}
              attempts={blockActivity?.NoOfAttempts}
              keywords={block?.keywords}
              scoreType={block?.typeOfBlock}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default AdvancedFillView;
