import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { cloudfrontUrl } from "../../../config/BaseUrl";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const CourseOverView = ({ courseOne }) => {
  const navigate = useNavigate();

  return (
    <Box className="course-overview">
      <Grid container spacing={2} className="grid-continer">
        {/* Text Content */}
        <Grid
          item
          xs={12}
          md={10}
          className="text-content-grid"
          sx={{
            mb: {
              xs: "10px",
              md: 0,
            },
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "600",
            }}
          >
            Course Overview
          </Typography>
          <Typography
            dangerouslySetInnerHTML={{ __html: courseOne?.tagline }}
            variant="body1"
            sx={{
              margin: "0px",
              fontSize: "1.125rem",
            }}
          ></Typography>
          <Button
            variant="contained"
            sx={{
              alignSelf: "flex-start",
              textTransform: "none",
              backgroundColor: "#ffffff",
              fontSize: "1.125rem",
              marginBottom: "15px",
              color: "#1266d2",
              "&:hover": {
                backgroundColor: "#ffffff",
              },
            }}
            onClick={() => navigate(`/course/${courseOne?.slug}?cid=${courseOne._id}`)}
            endIcon={<ArrowForwardIcon />}
          >
            Access to the Course
          </Button>
        </Grid>

        {/* Course Image */}
        <Grid item xs={12} md={2} className="course-image-grid">
          <Box
            sx={{
              width: "100%",
              maxWidth: "fit-content", // Set consistent max width for the image
              height: "auto", // Keep aspect ratio
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "6px",
            }}
          >
            <img
              src={
                courseOne?.image?.l?.startsWith(cloudfrontUrl)
                  ? courseOne?.image?.l
                  : cloudfrontUrl + "/" + courseOne?.image?.l
              }
              alt="Course Overview"
              style={{
                width: "100%",
                height: "auto",
                objectFit: "contain", // Ensure the image scales proportionally
                objectPosition: "center",
                borderRadius: "6px",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CourseOverView;
