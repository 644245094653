import { Box, Typography } from "@mui/material";
import LeftBorder from "./LeftBorder";

const CardHeading = ({ image, title }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
      }}
    >
      <LeftBorder />
      <img
        src={image}
        alt="Course Details"
        style={{
          width: 40,
          height: 40,
          padding: "7px",
          boxShadow: "0px 0px 8px 2px rgba(0, 0, 0, 0.1)",
          border: "1px solid #1266D2",
          backgroundColor: "#F7FBFF",
          borderRadius: "6px",
        }}
      />
      <Typography variant="h5" sx={{ fontWeight: 600 }}>
        {title}
      </Typography>
    </Box>
  );
};

export default CardHeading;
