import React from "react";
import { Box } from "@mui/material";
import backgroundImage from "../../../assets/list-icon.svg";
import CourseOutcomesIcon from "../../../assets/course-outcomes.svg";
import CardHeading from "./CardHeading";

export const CourseOutcomes = ({ courseOne }) => {
  return (
    <Box
      sx={{
        marginTop: 4,
        borderRadius: 2,
        boxShadow: "0px 0px 8px 2px rgba(0, 0, 0, 0.15)",
        padding: "24px 24px 24px 0",
      }}
    >
      {/* Heading Section with Image */}
      <CardHeading image={CourseOutcomesIcon} title={"Course Outcomes"} />
      <Box
        sx={{
          padding: 3,
        }}
      >
        {courseOne?.outcomes?.map((item, index) => (
          <Box
            key={index}
            sx={{
              marginBottom: 1.5,
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              padding: "7px",
              backgroundColor: "#F9F9F9",
              border: "1px solid #dcd9d9",
            }}
          >
            <Box
              sx={{
                borderRadius: "50%",
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "40px",
                width: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "black",
                fontWeight: "bold",
                flexShrink: 0,
              }}
            >
              <span style={{ position: "relative", top: "2px", fontSize: " 20px", right: "2px" }}>
                {index + 1}
              </span>
            </Box>
            <Box sx={{ fontSize: "1.125rem" }}>{item}</Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
