import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Backdrop,
  Box,
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

import { POST } from "../../../config/Api";

import FMModal from "../../ModalMain/CourseModals/FMModal";
import { InputBox } from "../../InputBox/InputBox";
import ButtonMain from "../../ButtonMain";
import { Constants } from "../../../config/Constants";
import { isValidEmail, useQueryParams } from "../../../utils";
import { ToastNotify } from "../../SnackBar/ToastNotify";
import PreviewCertificateModal from "./PreviewCertificateModal";

const StepperModal = ({
  modalOpen,
  closeModal,
  setCompletedModulesData,
  handleUnlockModule,
  savedCourseDetail,
  getUserCourseActivity,
  completedModulesData,
  triggerUserEvent,
  showIncorrectTab,
}) => {
  let UserData = useSelector((state) => state.userLogin);
  const { cid } = useQueryParams();

  const [nameOnCertificate, setNameOnCertificate] = useState("");
  const [confNameOnCertificate, setConfNameOnCertificate] = useState("");
  const [nameError, setnameError] = useState("");
  const [confNameError, setConfNameError] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [enableUnlock, setEnableUnlock] = useState(false);
  const [checkFailed, setCheckFailed] = useState(false);
  const [certOpen, setCertOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [confEmail, setConfEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [confEmailErr, setConfEmailErr] = useState("");

  const courseDetails = useSelector((state) => state.savedCourseDetail);
  let assessmentData = courseDetails?.data?.assessmentDashboard?.blocksHighlight;

  const toastRef = useRef();

  const steps = ["Evaluation Started", "Completeness Validation", "Score Evaluation"];
  useEffect(() => {
    handleCheckForCertificate();
  }, []);

  const updateEmailOnUserCourseActivity = async () => {
    let payload = {
      course_id: savedCourseDetail?._id,
      cohort_id: completedModulesData?.cohort_id,
      certificateEmail: email,
      courseName: savedCourseDetail?.title,
      name: nameOnCertificate.trim(),
    };
    try {
      //send certificate email
      const res = await POST(`${Constants.sendCertificateEmail}`, payload);
      const updatedUserCourseActivity = res.data.updatedUserCourseActivity;
      setCompletedModulesData((prev) => ({
        ...prev,
        ...updatedUserCourseActivity,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleGenerateCertificateV2 = async () => {
    try {
      if (!nameOnCertificate.trim()) {
        setnameError("Please enter your name.");
      } else if (!confNameOnCertificate.trim()) {
        setConfNameError("Confirm Name is required.");
      } else if (nameOnCertificate.trim() !== confNameOnCertificate.trim()) {
        setConfNameError("Name and Confirm Name should be same.");
      } else if (!email.trim()) {
        setEmailErr("Email is required");
      } else if (!isValidEmail(email.trim())) {
        setEmailErr("Please enter valid email.");
      } else if (!confEmail.trim()) {
        setConfEmailErr("Confirm Email is required");
      } else if (email.trim() !== confEmail.trim()) {
        setConfEmailErr("Email and Confirm Email should be same.");
      } else {
        setLoading(true);
        //update user course activity
        const res = await POST(`${Constants.updateUserCourseActivity}`, {
          course_id: cid,
          cohort_id: completedModulesData?.cohort_id,
          name: nameOnCertificate.trim(),
        });

        if (res?.data?.success) {
          triggerUserEvent(
            "efc_clicked",
            completedModulesData?.cohortDetails?._id,
            completedModulesData?.cohortDetails?.title,
            completedModulesData?.cohortDetails?.type,
            completedModulesData?.cohortDetails?.eventTracking,
            res?.data?.updatedActivity?.isCertificateGenerated,
          );
          await updateEmailOnUserCourseActivity();
          setLoading(false);
          //save user last activity
          await POST(`${Constants.userActivity}?activity=evaluteCertificateClicked`, {
            user: UserData?.data?.user,
          });
          toastRef.current.showToastMessage(res?.data?.message, "success");
          setCertOpen(true);
        } else {
          setLoading(false);
          toastRef.current.showToastMessage(res?.data?.message, "error");
        }
      }
    } catch (error) {
      setLoading(false);
      toastRef.current.showToastMessage(error, "error");
    }
  };

  const handleCheckForCertificate = async () => {
    try {
      //course validation
      const res = await POST(`${Constants.checkCertificate}/validateCourse`, {
        course_id: cid,
        cohort_id: "",
        user_id: UserData?.data?.user?.id,
      });
      if (res?.data?.success) {
        setActiveStep(1);
        toastRef.current.showToastMessage(res?.data?.message, "success");
        try {
          //check for course completeness
          const res = await POST(`${Constants.checkCertificate}/validateCompleteness`, {
            course_id: cid,
            cohort_id: "",
            user_id: UserData?.data?.user?.id,
          });

          if (res?.data?.success) {
            setActiveStep(2);
            toastRef.current.showToastMessage(res?.data?.message, "success");
            try {
              //check for score validation
              const res = await POST(`${Constants.checkCertificate}/validateScore`, {
                course_id: cid,
                cohort_id: "",
                user_id: UserData?.data?.user?.id,
              });

              if (res?.data?.isPassed) {
                setActiveStep(3);
                toastRef.current.showToastMessage(res?.data?.message, "success");
                if (!savedCourseDetail?.certification) {
                  setTimeout(() => {
                    getUserCourseActivity();
                    closeModal();
                  }, 2000);
                }
              } else {
                if (assessmentData) {
                  showIncorrectTab(cid);
                }
                setCheckFailed(true);
                setEnableUnlock(true);

                toastRef.current.showToastMessage(res?.data?.message, "error");
                //closeModal();
              }
            } catch (error) {
              if (assessmentData) {
                showIncorrectTab(cid);
              }
              toastRef.current.showToastMessage(res?.data?.message, "error");
            }
          } else {
            if (assessmentData) {
              showIncorrectTab(cid);
            }
            setCheckFailed(true);
            toastRef.current.showToastMessage(res?.data?.message, "error");

            //closeModal();
            // if(res?.data?.modulesAttempted){
            //   setEnableUnlock(true)
            // }
          }
        } catch (error) {
          if (assessmentData) {
            showIncorrectTab(cid);
          }
          toastRef.current.showToastMessage(res?.data?.message, "error");
        }
      } else {
        if (assessmentData) {
          showIncorrectTab(cid);
        }
        setCheckFailed(true);
        toastRef.current.showToastMessage(res?.data?.message, "error");

        // closeModal();
      }
    } catch (error) {
      toastRef.current.showToastMessage(error, "error");
    }
  };

  return (
    <>
      <FMModal
        modalOpen={modalOpen}
        modalClose={closeModal}
        modalHeader={"Certificate"}
        className="confirm_modal"
        headerTextColor={"header_text_black"}
        backgroundImage={{
          backgroundImage: "linear-gradient(#a1c4fd, #c2e9fb)",
        }}
      >
        {loading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: 999 }}
            open={loading}
            onClick={() => setLoading(false)}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Box p={3}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            className={checkFailed ? "checks_failed" : ""}
          >
            {steps.map((label, i) => (
              <Step key={label}>
                <StepLabel className="step_icon">{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        {activeStep === 3 && (
          <>
            {savedCourseDetail?.certification ? (
              <>
                <Typography my={2} variant="body1">
                  Congratulations, you have completed the course! Please download the certificate.
                </Typography>
                <Typography my={2} variant="body2">
                  *Name (Which you want to display on the certificate)
                </Typography>
                <InputBox
                  placeholder={"Name (Use English / Latin Script) *"}
                  value={nameOnCertificate}
                  onChange={(e) => {
                    setNameOnCertificate(e.target.value.replace(/[^A-Za-z0-9\s]/g, ""));
                    setnameError("");
                  }}
                  errorMsg={nameError}
                />
                <Box sx={{ marginTop: "5px" }}>
                  <InputBox
                    placeholder={"Confirm Name (Use English / Latin Script) *"}
                    value={confNameOnCertificate}
                    onChange={(e) => {
                      setConfNameOnCertificate(e.target.value.replace(/[^A-Za-z0-9\s]/g, ""));
                      setConfNameError("");
                    }}
                    errorMsg={confNameError}
                  />
                </Box>
                <Typography my={2} variant="body2">
                  *Email (Which you want to share the certificate on)
                </Typography>
                <Box sx={{ marginTop: "5px" }}>
                  <InputBox
                    placeholder={"Enter Email *"}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailErr("");
                    }}
                    errorMsg={emailErr}
                  />
                </Box>
                <Box sx={{ marginTop: "5px" }}>
                  <InputBox
                    placeholder={"Enter Confirm Email*"}
                    value={confEmail}
                    onChange={(e) => {
                      setConfEmail(e.target.value);
                      setConfEmailErr("");
                    }}
                    errorMsg={confEmailErr}
                  />
                </Box>
                <Typography
                  my={2}
                  variant="body2"
                  sx={{
                    color: (theme) => theme.palette.warning.main,
                  }}
                >
                  Warning: Provide your full name as it should appear on the certificate. You cannot
                  change your name for the certificate again. Please type it carefully and check
                  your name's spelling.
                </Typography>
                <ButtonMain className={"confirm_name_button"} onClick={handleGenerateCertificateV2}>
                  Confirm
                </ButtonMain>
              </>
            ) : (
              <Typography my={2} variant="body1">
                Course Successfully Completed.
              </Typography>
            )}
          </>
        )}
        {enableUnlock && (
          <>
            <ButtonMain className={"confirm_name_button"} onClick={handleUnlockModule}>
              Unlock Modules
            </ButtonMain>
          </>
        )}
      </FMModal>
      <ToastNotify ref={toastRef} duration={4000} />
      <PreviewCertificateModal
        certOpen={certOpen}
        setCertOpen={setCertOpen}
        name={nameOnCertificate}
        closeModal={closeModal}
      />
    </>
  );
};

export default StepperModal;
