import React from "react";
import { useOutletContext } from "react-router-dom";
import { Box } from "@mui/material";
import CourseOverView from "./CourseOverView";
import CourseDetails from "./CourseDetails";
import { CourseLearnings } from "./CourseLearnings";
import { CourseModules } from "./CourseModules";
import { CourseOutcomes } from "./CourseOutcomes";
import "./index.scss";

const CourseIntroduction = () => {
  const { courseOne, modDetails } = useOutletContext();

  return (
    <Box mt={2}>
      <CourseOverView courseOne={courseOne} />
      <CourseDetails courseOne={courseOne} />
      {courseOne?.skills?.length > 0 && <CourseLearnings courseOne={courseOne} />}
      {courseOne?.outcomes?.length > 0 && <CourseOutcomes courseOne={courseOne} />}
      {modDetails?.length > 0 && <CourseModules modDetails={modDetails} />}
    </Box>
  );
};

export default CourseIntroduction;
