import React, { useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Box,
  Chip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";

const AdvanceCourseInfo = ({
  courseData,
  setcourseData,
  handleChange,
  courseDataError,
  setcourseDataError,
}) => {
  const { outcomes = [], skills = [] } = courseData;
  const [outcomeInput, setOutcomeInput] = useState("");
  const [skillInput, setSkillInput] = useState("");

  // Handle adding a new outcome
  const handleAddOutcome = () => {
    const trimmedOutcome = outcomeInput.trim();
    if (trimmedOutcome === "") {
      setcourseDataError((prev) => ({ ...prev, outcome: "Please enter a valid outcome." }));
      return;
    }
    if (outcomes.includes(trimmedOutcome)) {
      setcourseDataError((prev) => ({ ...prev, outcome: "This outcome already exists." }));
      return;
    }
    setcourseData({
      ...courseData,
      outcomes: [...outcomes, trimmedOutcome],
    });
    setOutcomeInput("");
    setcourseDataError((prev) => ({ ...prev, outcome: "" }));
  };

  // Handle removing an outcome
  const handleRemoveOutcome = (index) => {
    const updatedOutcomes = outcomes.filter((_, i) => i !== index);
    setcourseData({
      ...courseData,
      outcomes: updatedOutcomes,
    });
  };

  // Handle adding a new skill
  const handleAddSkill = () => {
    const trimmedSkill = skillInput.trim();
    if (trimmedSkill === "") {
      setcourseDataError((prev) => ({ ...prev, skill: "Please enter a valid skill." }));
      return;
    }
    if (skills.includes(trimmedSkill)) {
      setcourseDataError((prev) => ({ ...prev, skill: "This skill already exists." }));
      return;
    }
    setcourseData({
      ...courseData,
      skills: [...skills, trimmedSkill],
    });
    setSkillInput("");
    setcourseDataError((prev) => ({ ...prev, skill: "" }));
  };

  // Handle removing a skill
  const handleRemoveSkill = (index) => {
    const updatedSkills = skills.filter((_, i) => i !== index);
    setcourseData({
      ...courseData,
      skills: updatedSkills,
    });
  };

  // Handle change for course direction
  const handleDirectionChange = (event) => {
    setcourseData({
      ...courseData,
      direction: event.target.value,
    });
    setcourseDataError((prev) => ({ ...prev, direction: "" }));
  };

  return (
    <Grid container spacing={3} my={2}>
      {/* Course Duration */}
      <Grid item lg={4} xs={12}>
        <TextField
          variant="outlined"
          name="durationHour"
          type="number"
          label="Course Duration (Hr)"
          fullWidth
          placeholder="Course Duration (Hr)"
          value={courseData.durationHour || ""}
          onChange={(e) =>
            setcourseData({
              ...courseData,
              durationHour: Math.max(0, parseFloat(e.target.value) || 0), // Ensure non-negative float values
            })
          }
          error={!!courseDataError.duration}
          helperText={courseDataError.duration || ""}
          InputProps={{
            inputProps: {
              min: 0, // Ensure hour cannot be negative
            },
          }}
        />
      </Grid>

      {/* Course Direction */}
      <Grid item lg={4} xs={12}>
        <FormControl fullWidth variant="outlined" error={!!courseDataError.direction}>
          <InputLabel>Course Direction*</InputLabel>
          <Select
            name="direction"
            value={courseData.direction || ""}
            onChange={handleDirectionChange}
            label="Course Direction*"
          >
            <MenuItem value="" disabled>
              Select Direction
            </MenuItem>
            <MenuItem value="Self Directed">Self Directed</MenuItem>
            <MenuItem value="Facilitated">Facilitated</MenuItem>
            <MenuItem value="Self Directed & Facilitated">Self Directed & Facilitated</MenuItem>
          </Select>
          {courseDataError.direction && (
            <Typography variant="body2" color="error" component="span">
              {courseDataError.direction}
            </Typography>
          )}
        </FormControl>
      </Grid>

      {/* Outcomes Section */}
      <Grid item lg={4} xs={12}>
        <Box>
          <Box display="flex" alignItems="center" mb={2}>
            <TextField
              variant="outlined"
              fullWidth
              type="text"
              placeholder="Add an Outcome"
              label="Add an Outcome"
              value={outcomeInput}
              onChange={(e) => {
                setOutcomeInput(e.target.value);
                if (e.target.value.trim() !== "")
                  setcourseDataError((prev) => ({ ...prev, outcome: "" }));
              }}
              error={!!courseDataError.outcome}
              helperText={courseDataError.outcome || ""}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddOutcome}
              style={{ marginLeft: "8px" }}
            >
              Add
            </Button>
          </Box>
          <Box display="flex" flexWrap="wrap" gap={1}>
            {outcomes.map((outcome, index) => (
              <Chip
                key={index}
                label={outcome}
                onDelete={() => handleRemoveOutcome(index)}
                variant="outlined"
              />
            ))}
          </Box>
        </Box>
      </Grid>

      {/* Skills Section */}
      <Grid item lg={4} xs={12}>
        <Box>
          <Box display="flex" alignItems="center" mb={2}>
            <TextField
              variant="outlined"
              fullWidth
              type="text"
              placeholder="Add a Skill"
              label="Add a Skill"
              value={skillInput}
              onChange={(e) => {
                setSkillInput(e.target.value);
                if (e.target.value.trim() !== "")
                  setcourseDataError((prev) => ({ ...prev, skill: "" }));
              }}
              error={!!courseDataError.skill}
              helperText={courseDataError.skill || ""}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddSkill}
              style={{ marginLeft: "8px" }}
            >
              Add
            </Button>
          </Box>
          <Box display="flex" flexWrap="wrap" gap={1}>
            {skills.map((skill, index) => (
              <Chip
                key={index}
                label={skill}
                onDelete={() => handleRemoveSkill(index)}
                variant="outlined"
              />
            ))}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AdvanceCourseInfo;
