import React from "react";
import ResetPasswordLayout from "../../components/ResetPassword/Reset";

// import './resetPassword.scss'

const ResetPassword = () => {
  return (
    <div>
      <ResetPasswordLayout />
    </div>
  );
};

export default ResetPassword;
