import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import Divider from "@mui/material/Divider";
import SourceOutlinedIcon from "@mui/icons-material/SourceOutlined";
//icons
import TitleIcon from "@mui/icons-material/Title";
import SmartButtonIcon from "@mui/icons-material/SmartButton";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import PhotoOutlinedIcon from "@mui/icons-material/PhotoOutlined";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import AudiotrackOutlinedIcon from "@mui/icons-material/AudiotrackOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import GifBoxOutlinedIcon from "@mui/icons-material/GifBoxOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import DragHandleOutlinedIcon from "@mui/icons-material/DragHandleOutlined";
import TextRotateVerticalOutlinedIcon from "@mui/icons-material/TextRotateVerticalOutlined";
import SettingsVoiceOutlinedIcon from "@mui/icons-material/SettingsVoiceOutlined";
import VideoCameraBackOutlinedIcon from "@mui/icons-material/VideoCameraBackOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import CodeOutlinedIcon from "@mui/icons-material/CodeOutlined";
import PersonalVideoTwoToneIcon from "@mui/icons-material/PersonalVideoTwoTone";
import MarkUnreadChatAltOutlinedIcon from "@mui/icons-material/MarkUnreadChatAltOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import SourceIcon from "@mui/icons-material/Source";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import MoodBadIcon from "@mui/icons-material/MoodBad";
import InsertPageBreakIcon from "@mui/icons-material/InsertPageBreak";
import SportsKabaddiOutlinedIcon from "@mui/icons-material/SportsKabaddiOutlined";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import Box from "@mui/material/Box";
import TabContents from "./TabContents";
import JoinInnerIcon from "@mui/icons-material/JoinInner";
import RateReviewIcon from "@mui/icons-material/RateReview";

export default function DesktopVertical({
  setblockModOpen,
  setHeadingText,
  courseDetails,
  setCourseBlocks,
  courseOne,
  blockModalOpen,
  type,
}) {
  const [openStates, setOpenStates] = React.useState({
    basicBlocks: true,
    mediaBlocks: false,
    userInputBlocks: false,
    advancedBlocks: false,
    advancedInputBlocks: false,
  });
  const [activeTab, setActiveTab] = useState("Rich Text");

  const handleClick = (panel) => {
    setOpenStates((prevOpenStates) => ({
      ...Object.fromEntries(
        Object.entries(prevOpenStates).map(([key, value]) => [key, key === panel ? !value : false]),
      ),
    }));
  };

  const handleActiveTab = (tab) => {
    setActiveTab(tab);
    setHeadingText(tab);
  };

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <Box
        sx={{
          width: "20%",
          flexShrink: 0,
          bgcolor: "background.paper",
        }}
      >
        <List
          sx={{ width: "100%", bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton onClick={() => handleClick("basicBlocks")}>
            <ListItemText primary="Basic Blocks" />
            {openStates.basicBlocks ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openStates.basicBlocks} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4, backgroundColor: activeTab === "Container" ? "#ccc" : "transparent" }}
                onClick={() => handleActiveTab("Container")}
              >
                <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                  <Inventory2OutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Module Container" sx={{ margin: "4px" }} />
              </ListItemButton>

              <ListItemButton
                sx={{
                  pl: 4,
                  backgroundColor: activeTab === "Rich Text" ? "#ccc" : "transparent",
                }}
                onClick={() => handleActiveTab("Rich Text")}
              >
                <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                  <TitleIcon />
                </ListItemIcon>
                <ListItemText primary="Rich Text" sx={{ margin: "4px" }} />
              </ListItemButton>

              <ListItemButton
                sx={{ pl: 4, backgroundColor: activeTab === "List" ? "#ccc" : "transparent" }}
                onClick={() => handleActiveTab("List")}
              >
                <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                  <FormatListBulletedOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="List" sx={{ margin: "4px" }} />
              </ListItemButton>

              <ListItemButton
                sx={{
                  pl: 4,
                  backgroundColor: activeTab === "Toggle List" ? "#ccc" : "transparent",
                }}
                onClick={() => handleActiveTab("Toggle List")}
              >
                <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                  <RadioButtonCheckedOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Toggle List" sx={{ margin: "4px" }} />
              </ListItemButton>

              <ListItemButton
                sx={{ pl: 4, backgroundColor: activeTab === "Divider" ? "#ccc" : "transparent" }}
                onClick={() => handleActiveTab("Divider")}
              >
                <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                  <MoreHorizOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Divider" sx={{ margin: "4px" }} />
              </ListItemButton>

              <ListItemButton
                sx={{
                  pl: 4,
                  backgroundColor: activeTab === "Block Carousel" ? "#ccc" : "transparent",
                }}
                onClick={() => handleActiveTab("Block Carousel")}
              >
                <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                  <SourceOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Block Carousel" sx={{ margin: "4px" }} />
              </ListItemButton>
            </List>
          </Collapse>
          <Divider component="li" />
          <ListItemButton onClick={() => handleClick("mediaBlocks")}>
            <ListItemText primary="Media Blocks" />
            {openStates.mediaBlocks ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openStates.mediaBlocks} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4, backgroundColor: activeTab === "Images" ? "#ccc" : "transparent" }}
                onClick={() => handleActiveTab("Images")}
              >
                <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                  <PhotoOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Images" sx={{ margin: "4px" }} />
              </ListItemButton>
              <ListItemButton
                sx={{
                  pl: 4,
                  backgroundColor: activeTab === "Web Bookmarks" ? "#ccc" : "transparent",
                }}
                onClick={() => handleActiveTab("Web Bookmarks")}
              >
                <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                  <InsertLinkOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Web Bookmarks" sx={{ margin: "4px" }} />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4, backgroundColor: activeTab === "Video" ? "#ccc" : "transparent" }}
                onClick={() => handleActiveTab("Video")}
              >
                <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                  <PlayCircleOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Video" sx={{ margin: "4px" }} />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4, backgroundColor: activeTab === "Audio" ? "#ccc" : "transparent" }}
                onClick={() => handleActiveTab("Audio")}
              >
                <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                  <AudiotrackOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Audio" sx={{ margin: "4px" }} />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4, backgroundColor: activeTab === "File" ? "#ccc" : "transparent" }}
                onClick={() => handleActiveTab("File")}
              >
                <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                  <AttachFileOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="File" sx={{ margin: "4px" }} />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4, backgroundColor: activeTab === "GIF" ? "#ccc" : "transparent" }}
                onClick={() => handleActiveTab("GIF")}
              >
                <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                  <GifBoxOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="GIF" sx={{ margin: "4px" }} />
              </ListItemButton>
            </List>
          </Collapse>
          <Divider component="li" />

          {type !== "landing" && (
            <>
              <ListItemButton onClick={() => handleClick("userInputBlocks")}>
                <ListItemText primary="User Input Blocks" />
                {openStates.userInputBlocks ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openStates.userInputBlocks} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4, backgroundColor: activeTab === "MCQ" ? "#ccc" : "transparent" }}
                    onClick={() => handleActiveTab("MCQ")}
                  >
                    <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                      <HelpOutlineOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="MCQ" sx={{ margin: "4px" }} />
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      pl: 4,
                      backgroundColor: activeTab === "Fill in the blanks" ? "#ccc" : "transparent",
                    }}
                    onClick={() => handleActiveTab("Fill in the blanks")}
                  >
                    <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                      <IndeterminateCheckBoxOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Fill in the blanks" sx={{ margin: "4px" }} />
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      pl: 4,
                      backgroundColor: activeTab === "Match the following" ? "#ccc" : "transparent",
                    }}
                    onClick={() => handleActiveTab("Match the following")}
                  >
                    <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                      <DragHandleOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Match the following" sx={{ margin: "4px" }} />
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      pl: 4,
                      backgroundColor: activeTab === "Text Response" ? "#ccc" : "transparent",
                    }}
                    onClick={() => handleActiveTab("Text Response")}
                  >
                    <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                      <TextRotateVerticalOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Text Response" sx={{ margin: "4px" }} />
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      pl: 4,
                      backgroundColor: activeTab === "AI Text Response" ? "#ccc" : "transparent",
                    }}
                    onClick={() => handleActiveTab("AI Text Response")}
                  >
                    <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                      <AutoAwesomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="AI Text Response" sx={{ margin: "4px" }} />
                  </ListItemButton>
                  {/* <ListItemButton
                    sx={{
                      pl: 4,
                      backgroundColor:
                        activeTab === "AI Text Response with Feedback" ? "#ccc" : "transparent",
                    }}
                    onClick={() => handleActiveTab("AI Text Response with Feedback")}
                  >
                    <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                      <TipsAndUpdatesIcon />
                    </ListItemIcon>
                    <ListItemText primary="AI Text Response with Feedback" sx={{ margin: "4px" }} />
                  </ListItemButton> */}
                  <ListItemButton
                    sx={{
                      pl: 4,
                      backgroundColor: activeTab === "Audio Recording" ? "#ccc" : "transparent",
                    }}
                    onClick={() => handleActiveTab("Audio Recording")}
                  >
                    <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                      <SettingsVoiceOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Audio Recording" sx={{ margin: "4px" }} />
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      pl: 4,
                      backgroundColor: activeTab === "Video Recording" ? "#ccc" : "transparent",
                    }}
                    onClick={() => handleActiveTab("Video Recording")}
                  >
                    <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                      <VideoCameraBackOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Video Recording" sx={{ margin: "4px" }} />
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      pl: 4,
                      backgroundColor: activeTab === "Upload File" ? "#ccc" : "transparent",
                    }}
                    onClick={() => handleActiveTab("Upload File")}
                  >
                    <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                      <CloudDownloadOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Upload File" sx={{ margin: "4px" }} />
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      pl: 4,
                      backgroundColor: activeTab === "FS Form" ? "#ccc" : "transparent",
                    }}
                    onClick={() => handleActiveTab("FS Form")}
                  >
                    <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                      <FormatAlignCenterIcon />
                    </ListItemIcon>
                    <ListItemText primary="FS Form" sx={{ margin: "4px" }} />
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      pl: 4,
                      backgroundColor: activeTab === "Survey Monkey" ? "#ccc" : "transparent",
                    }}
                    onClick={() => handleActiveTab("Survey Monkey")}
                  >
                    <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                      <MoodBadIcon />
                    </ListItemIcon>
                    <ListItemText primary="Survey Monkey" sx={{ margin: "4px" }} />
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      pl: 4,
                      backgroundColor: activeTab === "Game" ? "#ccc" : "transparent",
                    }}
                    onClick={() => handleActiveTab("Game")}
                  >
                    <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                      <SportsKabaddiOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Empathy Game" sx={{ margin: "4px" }} />
                  </ListItemButton>
                </List>
              </Collapse>
              <Divider component="li" />

              <ListItemButton onClick={() => handleClick("advancedInputBlocks")}>
                <ListItemText primary="Advanced Input Blocks" />
                {openStates.advancedInputBlocks ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openStates.advancedInputBlocks} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{
                      pl: 4,
                      backgroundColor:
                        activeTab === "Advanced Fill in the blanks" ? "#ccc" : "transparent",
                    }}
                    onClick={() => handleActiveTab("Advanced Fill in the blanks")}
                  >
                    <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                      <IndeterminateCheckBoxOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Advanced Fill in the blanks" sx={{ margin: "4px" }} />
                  </ListItemButton>
                  <ListItemButton
                    sx={{
                      pl: 4,
                      backgroundColor:
                        activeTab === "Advanced Match the following" ? "#ccc" : "transparent",
                    }}
                    onClick={() => handleActiveTab("Advanced Match the following")}
                  >
                    <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                      <JoinInnerIcon />
                    </ListItemIcon>
                    <ListItemText primary="Advanced Match the following" sx={{ margin: "4px" }} />
                  </ListItemButton>
                </List>
              </Collapse>
              <Divider component="li" />
            </>
          )}

          <ListItemButton onClick={() => handleClick("advancedBlocks")}>
            <ListItemText primary="Advanced Blocks" sx={{ margin: "4px" }} />
            {openStates.advancedBlocks ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openStates.advancedBlocks} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{
                  pl: 4,
                  backgroundColor: activeTab === "Paste a block" ? "#ccc" : "transparent",
                }}
                onClick={() => handleActiveTab("Paste a block")}
              >
                <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                  <ContentCopyOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Paste a block" sx={{ margin: "4px" }} />
              </ListItemButton>

              <ListItemButton
                sx={{
                  pl: 4,
                  backgroundColor: activeTab === "GhandiBot" ? "#ccc" : "transparent",
                }}
                onClick={() => handleActiveTab("GhandiBot")}
              >
                <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                  <RateReviewIcon />
                </ListItemIcon>
                <ListItemText primary="GhandiBot" sx={{ margin: "4px" }} />
              </ListItemButton>

              <ListItemButton
                sx={{ pl: 4, backgroundColor: activeTab === "Grid" ? "#ccc" : "transparent" }}
                onClick={() => handleActiveTab("Grid")}
              >
                <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                  <GridViewOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Grid" sx={{ margin: "4px" }} />
              </ListItemButton>
              <ListItemButton
                sx={{
                  pl: 4,
                  backgroundColor: activeTab === "Comic Strip" ? "#ccc" : "transparent",
                }}
                onClick={() => handleActiveTab("Comic Strip")}
              >
                <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                  <ForumOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Comic Strip" sx={{ margin: "4px" }} />
              </ListItemButton>

              <ListItemButton
                sx={{ pl: 4, backgroundColor: activeTab === "Button" ? "#ccc" : "transparent" }}
                onClick={() => handleActiveTab("Button")}
              >
                <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                  <SmartButtonIcon />
                </ListItemIcon>
                <ListItemText primary="Button" sx={{ margin: "4px" }} />
              </ListItemButton>

              <ListItemButton
                sx={{
                  pl: 4,
                  backgroundColor: activeTab === "native-embed" ? "#ccc" : "transparent",
                }}
                onClick={() => handleActiveTab("native-embed")}
              >
                <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                  <PersonalVideoTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary="Native Embed" sx={{ margin: "4px" }} />
              </ListItemButton>

              <ListItemButton
                sx={{
                  pl: 4,
                  backgroundColor: activeTab === "Embeded Code" ? "#ccc" : "transparent",
                }}
                onClick={() => handleActiveTab("Embeded Code")}
              >
                <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                  <CodeOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Embeded Code" sx={{ margin: "4px" }} />
              </ListItemButton>

              <ListItemButton
                sx={{
                  pl: 4,
                  backgroundColor: activeTab === "Section Break" ? "#ccc" : "transparent",
                }}
                onClick={() => handleActiveTab("Section Break")}
              >
                <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                  <InsertPageBreakIcon />
                </ListItemIcon>
                <ListItemText primary="Section Break" sx={{ margin: "4px" }} />
              </ListItemButton>

              <ListItemButton
                sx={{ pl: 4, backgroundColor: activeTab === "SCORM" ? "#ccc" : "transparent" }}
                onClick={() => handleActiveTab("SCORM")}
              >
                <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                  <SourceIcon />
                </ListItemIcon>
                <ListItemText primary="SCORM" sx={{ margin: "4px" }} />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4, backgroundColor: activeTab === "Discussion" ? "#ccc" : "transparent" }}
                onClick={() => handleActiveTab("Discussion")}
              >
                <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                  <MarkUnreadChatAltOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Discussion" sx={{ margin: "4px" }} />
              </ListItemButton>
            </List>
          </Collapse>
          <Divider component="li" />
        </List>
      </Box>
      <Box sx={{ flex: 1, bgcolor: "#eff6ff", mx: 4, mt: 4 }}>
        <TabContents
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
          activeTab={activeTab}
        />
      </Box>
    </Box>
  );
}
