import React, { useEffect, useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  Box,
  Paper,
  FormControl,
  Button,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import ButtonMain from "../../../../ButtonMain";
import { CheckboxInput, CustomInputFile, Dropdown, InputBox } from "../../../../InputBox/InputBox";
import CustomTextArea from "../../../../TextArea/CustomTextArea";
import { Constants, creditPoints } from "../../../../../config/Constants";
import { POST } from "../../../../../config/Api";
import CustomizedSnackbars from "../../../../SnackBar/CustomizedSnackbars";
import { useSelector } from "react-redux";
import { useQueryParams } from "../../../../../utils";
import { cloudfrontUrl } from "../../../../../config/BaseUrl";
import { Divider, MenuItem, Typography } from "@mui/material";
import CustomAccordion from "../../../../Accrodion/Accordion";
import SourceTextSwitcher from "../../../../SourceTextSwitcher/SourceTextSwitcher";
import { useOutletContext } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "##1A2027",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#111333",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const AddAdvancedMatch = ({
  courseDetails,
  blockDetails,
  type,
  updateOrder,
  setblockModOpen,
  setCourseBlocks,
  blockModalOpen,
}) => {
  const [match, setMatch] = useState({
    title: blockDetails?.title || "",
    description: blockDetails?.text || "",
    score: blockDetails?.typeOfBlock || "attempt",
    credit: blockDetails?.score || 5,
    checked: false,
    assessment: "",
    is_required: blockDetails?.is_required || false,
    mtf_style: blockDetails?.mtf_style || "Horizontal",
  });
  const [optText, setoptText] = useState({
    leftText: "",
    leftId: "",
    rightText: "",
    rightId: "",
  });
  const [disable, setdisable] = useState(false);
  const [checked, setChecked] = useState(false);
  const [block, setBlock] = useState({});
  const [blockToBeAdded, setBlockToBeAdded] = useState({});
  const [selectedLeftImage, setSelectedLeftImage] = useState(null);
  const [selectedRightImage, setSelectedRightImage] = useState(null);

  const [leftUrlPath, setLefturlPath] = useState({});
  const [rightUrlPath, setRighturlPath] = useState({});

  const [errText, seterrText] = useState("");
  const [open, setOpen] = useState(false);

  const [leftOption, setleftOption] = useState([]);
  const [rightOption, setrightOption] = useState([]);

  const [rightButtonDisable, setrightButtonDisable] = useState(false);

  const { setCourseOne } = useOutletContext();

  const blockidRef = useRef(block?._id || blockDetails?._id); // Use a ref to store block id
  const [disableButn, setDisableButton] = useState(false);

  useEffect(() => {
    if (blockDetails) {
      let left = blockDetails?.options?.filter((item) => item?.is_optionb === false);
      setleftOption(left);
      let right = blockDetails?.options?.filter((item) => item?.is_optionb === true);
      setrightOption(right);
    }
  }, []);

  let UserData = useSelector((state) => state.userLogin);
  const { cid, mid } = useQueryParams();

  const handleNextClick = async () => {
    if (match.title === "") {
      setOpen(true);
      seterrText("Please Enter Question Title.");
    } else if (match.description === "") {
      setOpen(true);
      seterrText("Please Enter Description.");
    } else {
      try {
        setDisableButton(true);
        setdisable(true);
        let body = {
          _id: blockidRef?.current ? blockidRef?.current : "",
          user_id: UserData?.data?.user?.id,
          course: cid,
          container: mid,
          order: blockModalOpen?.addBelow
            ? blockModalOpen?.currentBlockOrder + 1
            : updateOrder || 1,
          title: match.title,
          text: match.description,
          mtf_style: match?.mtf_style,
          type: type,
          assessment: match.assessment,
          is_required: match.is_required,
          highlightIfIncorrect: match.checked,
          typeOfBlock: match.score,
          score: match.credit,
        };
        //create match block
        const response = await POST(
          `${Constants.addBlock}/advancedMatch?_id=${blockidRef.current}`,
          body,
        );
        const newBlock = response?.data;
        setBlock(newBlock);
        setdisable(false);
        setDisableButton(false);
        // setBlockToBeAdded((prev) => ({ ...prev, ...response?.data }));
        setCourseBlocks((prevBlocks) => {
          const updatedBlocks = prevBlocks.map((prevBlock) =>
            prevBlock._id === (blockDetails?._id || newBlock?._id) ? newBlock : prevBlock,
          );
          return updatedBlocks;
        });
        setCourseOne((prev) => ({ ...prev, published: false }));
        blockidRef.current = newBlock?._id;
        return newBlock;
      } catch (error) {
        setDisableButton(false);
        console.log(error);
      }
    }
  };
  const handleLeftFileChange = async (event) => {
    setSelectedLeftImage(event.target.files[0]);
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    let header = "multipart/form-data";
    try {
      //upload image
      const response = await POST(`${Constants.addBlock}/uploadImage`, formData, header);
      setLefturlPath(response?.data?.image);
    } catch (error) {
      console.log(error);
    }
  };
  const handleRightFileChange = async (event) => {
    setSelectedRightImage(event.target.files[0]);
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    let header = "multipart/form-data";
    try {
      //upload image
      const response = await POST(`${Constants.addBlock}/uploadImage`, formData, header);
      setRighturlPath(response?.data?.image);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddOption = async () => {
    if (optText.leftText === "" && Object.keys(leftUrlPath).length === 0) {
      setOpen(true);
      seterrText("Please Enter Left Option Text or image.");
    } else if (optText.rightText === "" && Object.keys(rightUrlPath).length === 0) {
      setOpen(true);
      seterrText("Please Enter Right Option Text or image.");
    } else if (optText?.leftId && optText?.rightId) {
      await handleAddRightOption(optText?.rightId);
      await handleAddLeftOption(optText?.rightId, optText?.leftId);
    } else {
      const rightOptresponse = await handleAddRightOption();
      if (rightOptresponse) {
        // Extract the last option from the right options
        const lastRightOption = rightOptresponse?.pop();

        // Pass the last right option to left option creation
        await handleAddLeftOption(lastRightOption?._id);
      }
    }
  };
  const handleAddLeftOption = async (rightOptresponse, leftId) => {
    try {
      // Check if block ID is not present, then call handleNextStep
      let currentBlock = blockidRef.current ? block : await handleNextClick();

      // If block ID is still not present after handleNext, do not proceed
      if (!currentBlock) {
        return;
      }
      let body = {
        user_id: UserData?.data?.user?.id,
        course: courseDetails?._id,
        image: leftUrlPath,
        text: optText.leftText,
        currectOptions: rightOptresponse ? [rightOptresponse] : [],
        optionId: leftId || "",
      };
      //add match left option
      const response = await POST(
        `${Constants.updateBlock}/${blockidRef.current}/add_option`,
        body,
      );
      let leftoptiontext = [];
      response?.data?.options?.forEach((item) => {
        if (item?.is_optionb === false) {
          leftoptiontext.push(item);
        }
      });
      if (type !== "edit") {
        setBlockToBeAdded((prev) => ({ ...prev, ...response?.data }));
      }
      setleftOption(leftoptiontext);

      setoptText({ ...optText, leftText: "", leftId: "", rightText: "", rightId: "" });
      setSelectedLeftImage(null);
      setLefturlPath({});
      setCourseOne((prev) => ({ ...prev, published: false }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddRightOption = async (rightId) => {
    try {
      let currentBlock = blockidRef.current ? block : await handleNextClick();

      // If block ID is still not present after handleNext, do not proceed
      if (!currentBlock) {
        return;
      }

      let body = {
        user_id: UserData?.data?.user?.id,
        course: courseDetails?._id,
        image: rightUrlPath,
        text: optText.rightText,
        is_optionb: true,
        optionId: rightId || "",
      };
      setrightButtonDisable(true);
      //add match right option
      const response = await POST(
        `${Constants.updateBlock}/${blockidRef.current}/add_option`,
        body,
      );
      let rightoptiontext = [];
      response?.data?.options?.forEach((item) => {
        if (item?.is_optionb === true) {
          rightoptiontext.push(item);
        }
      });
      setrightOption(rightoptiontext);
      if (type !== "edit") {
        setBlockToBeAdded((prev) => ({ ...prev, ...response?.data }));
      }
      setrightButtonDisable(false);
      setoptText({ ...optText, rightText: "", rightId: "" });
      setSelectedRightImage(null);
      setRighturlPath({});
      return response?.data?.options;
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditOption = (editOption) => {
    const leftOptionToEdit = leftOption.find((option) => option._id === editOption?._id);
    const rightOptionToEdit = rightOption.find(
      (option) => option._id === editOption?.correct_options[0],
    );
    if (leftOptionToEdit && rightOptionToEdit) {
      setoptText({
        ...optText,
        leftText: leftOptionToEdit?.text,
        leftId: leftOptionToEdit?._id,
        rightId: rightOptionToEdit?._id,
        rightText: rightOptionToEdit?.text,
      });
      setLefturlPath(leftOptionToEdit?.image ? leftOptionToEdit?.image : {});
      setRighturlPath(rightOptionToEdit?.image ? rightOptionToEdit?.image : {});
    }
  };

  return (
    <div>
      <SourceTextSwitcher
        checked={checked}
        handleChange={(event) => setChecked(event.target.checked)}
        srcText={blockDetails?.srcText}
        srcTitleText={blockDetails?.srcTitleText}
      />
      <InputBox
        placeholder="Title"
        value={match.title || ""}
        onChange={(e) => setMatch({ ...match, title: e.target.value })}
        disabled={disable}
      />
      {checked && (
        <Box my={2}>
          <strong>Source Text:</strong> {blockDetails?.srcTitleText}
        </Box>
      )}
      <br />
      <CustomTextArea
        placeholder="Description"
        value={match.description || ""}
        onChange={(e) => setMatch({ ...match, description: e.target.value })}
        disabled={disable}
      />{" "}
      {checked && (
        <Box my={2}>
          <strong>Source Text:</strong> {blockDetails?.srcText}
        </Box>
      )}
      {/* {next && (
        <ButtonMain className="buttonGreen" onClick={handleNextClick}>
          Next Step
        </ButtonMain>
      )}
      {!next && ( */}
      <>
        <FormControl fullWidth sx={{ mt: 2, mb: 2, minWidth: 120 }} className="mcqStyle">
          <Typography sx={{ mb: 1 }}>Options Styles</Typography>
          <Dropdown
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            label="Options Style"
            value={match?.mtf_style}
            onChange={(event) => setMatch({ ...match, mtf_style: event.target.value })}
          >
            <MenuItem value="Horizontal">Horizontal Style</MenuItem>
            <MenuItem value="Vertical">Vertical Style</MenuItem>
          </Dropdown>
        </FormControl>
        <b>Create new option:</b>
        <Box sx={{ flexGrow: 1, mt: 1 }}>
          <Grid container spacing={2} columns={16}>
            <Grid item xs={14} sm={8} md={8}>
              <b>Left Option</b>
              <Item>
                {!Object.keys(leftUrlPath).length ? (
                  <CustomInputFile
                    title={
                      selectedLeftImage
                        ? selectedLeftImage.name?.length > 20 &&
                          selectedLeftImage.name.substring(0, 10)
                        : "Option Image"
                    }
                    icon={false}
                    onChange={handleLeftFileChange}
                  />
                ) : (
                  <img
                    src={cloudfrontUrl + leftUrlPath?.m}
                    alt="leftimg"
                    height="150px"
                    width="150px"
                  />
                )}
                <InputBox
                  placeholder="Option Text"
                  value={optText.leftText}
                  onChange={(e) => setoptText({ ...optText, leftText: e.target.value })}
                />
                <br />

                {/* <Button
                  variant="contained"
                  style={{ float: "inline-end", marginTop: "1rem" }}
                  onClick={handleAddLeftOption}
                  disabled={leftButtonDisable}
                >
                  Add Option
                </Button> */}
              </Item>
            </Grid>
            <Grid item xs={14} sm={8} md={8}>
              <b>Right Option</b>
              <Item>
                {!Object.keys(rightUrlPath).length ? (
                  <CustomInputFile
                    title={
                      selectedRightImage
                        ? selectedRightImage?.name?.length > 20 &&
                          selectedRightImage?.name.substring(0, 10)
                        : "Option Image"
                    }
                    icon={false}
                    onChange={handleRightFileChange}
                  />
                ) : (
                  <img
                    src={cloudfrontUrl + rightUrlPath?.m}
                    alt="rightimg"
                    height="150px"
                    width="150px"
                  />
                )}
                <InputBox
                  placeholder="Option Text"
                  value={optText.rightText}
                  onChange={(e) => setoptText({ ...optText, rightText: e.target.value })}
                />{" "}
                <br />
                <Button
                  variant="contained"
                  style={{ float: "inline-end", marginTop: "1rem" }}
                  onClick={handleAddOption}
                  disabled={rightButtonDisable}
                >
                  Add Option
                </Button>
              </Item>
            </Grid>
          </Grid>
        </Box>{" "}
        <br />
        {/* options */}
        {/* Display Options */}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Left Options</StyledTableCell>
                <StyledTableCell>Right Options</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from({ length: Math.max(leftOption.length, rightOption.length) }).map(
                (_, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "left",
                        }}
                      >
                        {leftOption[index]?.image?.m && (
                          <img
                            src={
                              leftOption[index]?.image?.m?.includes(cloudfrontUrl)
                                ? leftOption[index]?.image?.m
                                : cloudfrontUrl + leftOption[index]?.image?.m
                            }
                            alt={""}
                            style={{
                              width: "60px",
                              height: "50px",
                              objectFit: "contain", // Ensure the image fits within the container
                            }}
                          />
                        )}{" "}
                        {leftOption[index]?.text || ""}{" "}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "left",
                        }}
                      >
                        {rightOption[index]?.image?.m && (
                          <img
                            src={
                              rightOption[index]?.image?.m?.includes(cloudfrontUrl)
                                ? rightOption[index]?.image?.m
                                : cloudfrontUrl + rightOption[index]?.image?.m
                            }
                            alt={""}
                            style={{
                              width: "60px",
                              height: "50px",
                              objectFit: "contain", // Ensure the image fits within the container
                            }}
                          />
                        )}{" "}
                        {rightOption[index]?.text || ""}{" "}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      {" "}
                      <IconButton
                        color="primary"
                        aria-label="Remove"
                        size="small"
                        onClick={() => handleEditOption(leftOption[index], true)}
                        style={{ fontSize: "1rem" }}
                      >
                        <EditIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ),
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <Grid
          container
          columns={16}
          sx={{ border: "2px solid #a69b9b", background: "#fff", pr: 2 }}
        >
        
          <Grid
            item
            xs={16}
            sm={7.8}
             >
            {leftOption?.length && (
              <Box sx={{ p: 1 }}>
                <b>Left Options:</b>
                {leftOption.map((item, index) => (
                  <Box
                    key={item._id}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={1}
                  >
                    <Typography variant="body1"> {`${index + 1}. ${item.text}`}</Typography>
                   
                  </Box>
                ))}
              </Box>
            )}
          </Grid>
          <Grid item xs={16} sm={7.8} ml={1}>
            <b>Right Options:</b>
            {rightOption?.length && (
              <Box sx={{ p: 1 }}>
                {rightOption.map((item) => (
                  <Box
                    key={item._id}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={1}
                  >
                    <Typography variant="body1">{item.text}</Typography>
                    <IconButton
                      color="primary"
                      aria-label="Remove"
                      size="small"
                      onClick={() => handleEditOption(item?._id)}
                      style={{ fontSize: "1rem" }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            )}
          </Grid>
        </Grid> */}
        <br />
        <Divider sx={{ borderColor: "black", margin: "10px 0px" }} />
        <CustomAccordion
          first={
            <Dropdown
              onChange={(event) => setMatch({ ...match, score: event.target.value })}
              value={match.score}
            >
              {["attempt", "score", "none"].map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item === "attempt"
                    ? "Attempt and get credits"
                    : item === "score"
                    ? "Attempt correctly and get credits"
                    : "No credits for this question"}
                </MenuItem>
              ))}
            </Dropdown>
          }
          second={
            <Dropdown
              onChange={(event) => setMatch({ ...match, credit: event.target.value })}
              value={match.credit}
            >
              {creditPoints?.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              ))}
            </Dropdown>
          }
          third={
            <CheckboxInput
              label="Highlight if responded incorrectlyinfo"
              onChange={(event) => setMatch({ ...match, checked: event.target.checked })}
              value={!match.checked}
            />
          }
          fourth={
            <CheckboxInput
              label="Mark As Required"
              onChange={(event) =>
                setMatch({
                  ...match,
                  is_required: event.target.checked,
                })
              }
              value={!match.is_required}
            />
          }
        />
        <Divider sx={{ borderColor: "black", margin: "10px 0px" }} />
        <br />
        <ButtonMain
          className="buttonGreen"
          onClick={async () => {
            const blockId = await handleNextClick(); // Wait for handleNextStep to complete
            if (!blockId) return;

            if (type === "edit") {
              setCourseBlocks &&
                setCourseBlocks((prevBlocks) => {
                  const updatedBlocks = prevBlocks.map((prevBlock) => {
                    if (prevBlock._id === blockToBeAdded._id) {
                      return blockToBeAdded; // Update existing block
                    }
                    return prevBlock;
                  });
                  return updatedBlocks;
                });
            } else {
              if (blockModalOpen?.addBelow) {
                setCourseBlocks &&
                  setCourseBlocks((prev) => {
                    const updatedBlocks = prev.map((el) => {
                      if (el.order >= blockId?.order) {
                        el.order += 1;
                      }
                      return el;
                    });
                    const elIndex = updatedBlocks.findIndex(
                      (el) => el._id === blockModalOpen?.currentBlockId,
                    );
                    updatedBlocks.splice(elIndex + 1, 0, blockId);
                    return updatedBlocks;
                  });
              } else {
                setCourseBlocks &&
                  setCourseBlocks((prev) => {
                    prev.forEach((el) => {
                      el.order = el.order + 1;
                    });
                    return [blockId, ...prev];
                  });
              }
            }
            setblockModOpen && setblockModOpen(false);
            //  setmodalOpen && setmodalOpen((prev) => ({ ...prev, modalOpen: false }));
          }}
          disabled={disableButn}
        >
          Save Block
        </ButtonMain>
      </>
      <CustomizedSnackbars
        AlertText={errText}
        AlertType={"error"}
        open={open}
        onClose={() => setOpen(false)}
      />
    </div>
  );
};

export default AddAdvancedMatch;
