import React, { useEffect, useState, useRef, useCallback } from "react";
import BlockViews from "./BlockViews";
import { GET } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import { useNavigate, useParams } from "react-router-dom";
import { Typography, Container, Divider, Box, Button, Backdrop } from "@mui/material";
import KeywordsView from "../../Keywords/KeywordsView";
import { CourseDetailsSkeleton, FullScreenLoader } from "../../Loader/Loader";
import { useSelector } from "react-redux";
import "./download.scss";
import mgiepLogo from "../../../assets/mgiep-logo.png";
import { cloudfrontUrl } from "../../../config/BaseUrl";

const DownloadCourseView = () => {
  const [blockDetails, setBlockDetails] = useState([]);
  const [courseOne, setCourseOne] = useState({});
  const [cohortDetails, setCohortDetails] = useState({});
  const [blocksFetched, setBlocksFetched] = useState(false);
  const [loadingCourseBlocks, setLoadingCourseBlocks] = useState(false);
  const [close, setClose] = useState(false);

  const { courseID } = useParams();
  const navigate = useNavigate();
  const contentRef = useRef();

  const { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
      return;
    }

    if (courseID) {
      fetchCourseDetails();
    }
  }, [courseID, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && user?.id && courseID) {
      getUserActivity(user?.id);
    }
  }, [isLoggedIn, user?.id, courseID]);

  useEffect(() => {
    if (cohortDetails?.isCourseDownloadEnabled) {
      getCourseBlocks();
    }
  }, [cohortDetails]);

  useEffect(() => {
    if (blocksFetched && cohortDetails?.isCourseDownloadEnabled) {
      window.addEventListener("afterprint", (event) => {
        window.close();
      });
      setTimeout(() => window.print(), 0);
    }
  }, [cohortDetails, blocksFetched]);

  const fetchCourseDetails = async () => {
    try {
      const response = await GET(`${Constants.getPublicCourseDetail}/${courseID}`);
      setCourseOne(response?.data?.data || {});
    } catch (err) {
      console.error("Failed to fetch course details:", err);
      navigate("/404");
    }
  };

  const getUserActivity = async (user_id) => {
    try {
      const response = await GET(`${Constants.course}/activity/${courseID}/${user_id}`);
      setCohortDetails(response?.data?.userCourseActivity?.cohortDetails || {});
      !response?.data?.userCourseActivity?.cohortDetails?.isCourseDownloadEnabled && setClose(true);
    } catch (error) {
      console.error("Failed to fetch user activity:", error);
    }
  };

  // Fetch course blocks
  const getCourseBlocks = useCallback(async () => {
    setLoadingCourseBlocks(true); // Start loading
    try {
      const response = await GET(`${Constants.course}/nestedbblocks/${courseID}`);
      setBlockDetails(response?.data?.blocks || []);
      setLoadingCourseBlocks(false);
      setBlocksFetched(true);
      setClose(false);
    } catch (err) {
      setBlocksFetched(false);
      setLoadingCourseBlocks(false);
      setClose(true);
    }
  }, [courseID]);

  return (
    <div className="download-courseview" ref={contentRef}>
      {/* Watermark for print preview */}
      <div className="watermark">FRAMERSPACE</div>
      <Container maxWidth="xl" sx={{ width: "97%", py: 3 }}>
        <Box display="flex" alignItems="center" sx={{ mb: 3 }} className="fsTitle">
          <Box display="flex" mr={2} gap="10px">
            <img src={mgiepLogo} alt="mgiep-logo" style={{ width: "auto", height: "40px" }} />
            <img
              src={`${Constants.imgPath}/logo-dark.png`}
              alt="fs-logo"
              style={{ width: "auto", height: "40px" }}
            />
          </Box>
          <Typography variant="h3">FramerSpace</Typography>
        </Box>
        <Divider className="fsDivider" />
        <div className="title-container">
          <h2>{courseOne?.title}</h2>
          <Box display="flex" alignItems="center" sx={{ my: 2 }} className="course-desc-print">
            {/* Left-side Image */}
            {courseOne?.image?.m && (
              <img
                src={
                  courseOne?.image?.m?.startsWith(cloudfrontUrl)
                    ? courseOne?.image?.m
                    : `${cloudfrontUrl}/${courseOne?.image?.m}`
                }
                alt={courseOne?.title}
                className="download-course-image"
              />
            )}
            {/* Right-side Tagline */}
            <Typography
              dangerouslySetInnerHTML={{ __html: courseOne?.tagline }}
              variant="body1"
              sx={{ fontSize: "1.14286rem", lineHeight: 1.5, color: "text.secondary" }}
            />
          </Box>
          {/* For Screen View */}
          <Typography
            dangerouslySetInnerHTML={{ __html: courseOne?.tagline }}
            variant="body1"
            className="course-desc-screen"
            sx={{ margin: "0px", fontSize: "1.14286rem", lineHeight: 1.5 }}
          />
          <KeywordsView keywords={courseOne} />
        </div>

        {!blocksFetched && cohortDetails?.isCourseDownloadEnabled ? (
          <CourseDetailsSkeleton />
        ) : (
          <>
            {blockDetails?.length > 0 && (
              <BlockViews blockDetails={blockDetails} courseOne={courseOne} />
            )}
          </>
        )}
      </Container>
      <FullScreenLoader
        open={loadingCourseBlocks}
        className="loader-modal"
        text="Sit tight! We’re getting everything ready for you."
      />
      <Backdrop className="loader-modal backdrop-modal" open={close}>
        <Box className="white-box">
          {/* Conditionally render content */}
          {!cohortDetails?.isCourseDownloadEnabled ? (
            <>
              <Box className="not-enabled">Downloading is not enabled for this cohort.</Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => window.close()}
                sx={{ textTransform: "none" }}
              >
                Close
              </Button>
            </>
          ) : (
            <Box sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => window.close()}
                sx={{ textTransform: "none" }}
              >
                Close
              </Button>
            </Box>
          )}
        </Box>
      </Backdrop>
    </div>
  );
};

export default DownloadCourseView;
