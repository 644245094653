import React from "react";
import { Typography, Chip, Stack, Box } from "@mui/material";
import starIcon from "../../../assets/star.svg";
import CourseLearningIcon from "../../../assets/course-learnings.svg";
import CardHeading from "./CardHeading";

export const CourseLearnings = ({ courseOne }) => {
  return (
    <Box
      sx={{
        marginTop: 4,
        borderRadius: 2,
        boxShadow: "0px 0px 8px 2px rgba(0, 0, 0, 0.1)",
        padding: "24px 24px 24px 0",
      }}
    >
      <CardHeading image={CourseLearningIcon} title={"Course Learnings"} />
      <Box
        sx={{
          padding: 3,
        }}
      >
        <Typography variant="body2" sx={{ marginBottom: 2, fontSize: "1.125rem" }}>
          Skills you will learn
        </Typography>
        <Stack direction="row" flexWrap="wrap" gap={1}>
          {courseOne?.skills?.map((skill, index) => (
            <Chip
              variant="outlined"
              key={index}
              icon={
                <img
                  src={starIcon}
                  alt="star"
                  style={{
                    width: "16px",
                    height: "16px",
                  }}
                />
              }
              label={skill}
              sx={{
                padding: "4px",
                borderRadius: "8px",
                backgroundColor: "#f6f8fe",
                color: "#323232",
                fontSize: "1.125rem",
                marginRight: "10px",
              }}
            />
          ))}
        </Stack>
      </Box>
    </Box>
  );
};
