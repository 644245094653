import React from "react";
import { useOutletContext } from "react-router";
import { Box } from "@mui/system";

import { POST } from "../../config/Api";
import { baseurl } from "../../config/BaseUrl";
import SimpleTable from "./SimpleTable";
import AdvanceTable from "./AdvanceTable";
import { Alert, Skeleton, Typography } from "@mui/material";

const ScoreCard = () => {
  const [allScores, setAllScores] = React.useState("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const { courseOne, completedModulesData, modDetails, userDetails } = useOutletContext();

  React.useEffect(() => {
    setLoading(true);
    courseOne?._id && fetchScoreCard();
  }, [courseOne]);

  // -- fetch score card --
  const fetchScoreCard = () => {
    let data = { user_id: userDetails.user_id };
    POST(`${baseurl}/assessment/checkScore/${courseOne?._id}`, data)
      .then((res) => {
        setLoading(false);
        setAllScores(res.data);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data);
        console.log(err.response.data);
      });
  };

  let dateObject = new Date(completedModulesData.created_at);

  // Create a new Date object for today's date
  let today = new Date();

  // Calculate the time difference in milliseconds
  let timeDifference = today - dateObject;

  // Convert the time difference from milliseconds to days
  let daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  // Extract the date components
  let year = dateObject.getFullYear();
  let month = dateObject.getMonth() + 1; // Months are zero-based, so add 1
  let day = dateObject.getDate();

  // Create a formatted date string
  let formattedDate = `${day < 10 ? "0" : ""}${day}/${month < 10 ? "0" : ""}${month}/${year}`;

  return loading ? (
    <Box className="skeletonWrapper">
      <Skeleton
        variant="rectangular"
        animation="wave"
        // width={"50%"}
        // height={200}
        sx={{ borderRadius: "12px", flexShrink: 0 }}
        className="ScoreSkeleton"
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        // width={"50%"}
        // height={200}
        sx={{ borderRadius: "12px", flexShrink: 0 }}
        className="ScoreSkeleton"
      />
    </Box>
  ) : error ? (
    <Alert variant="standard" severity="error" sx={{ alignItems: "center", my: 3 }}>
      <Typography>An error has occured while retrieving assessment.</Typography>
    </Alert>
  ) : allScores?.isAdvance ? (
    <AdvanceTable
      allScores={allScores}
      allModules={modDetails}
      userActivity={completedModulesData}
      assessmentDashboard={courseOne.assessmentDashboard}
      dateOfEnrollment={formattedDate}
      numberOfDaysSinceEnrollment={daysDifference}
    />
  ) : (
    <Box width={"100%"} sx={{ display: "flex", justifyContent: "center" }}>
      <SimpleTable
        allScores={allScores}
        allModules={modDetails}
        userActivity={completedModulesData}
        assessment={"assessment"}
        dateOfEnrollment={formattedDate}
        numberOfDaysSinceEnrollment={daysDifference}
      />
    </Box>
  );
};

export default ScoreCard;
