import React, { useState, useEffect } from "react";
import { Grid, Typography, Box } from "@mui/material";

// Import SVG images
import Icon1 from "../../../assets/duration.svg";
import Icon2 from "../../../assets/direction.svg";
import Icon3 from "../../../assets/language.svg";
import Icon4 from "../../../assets/audience.svg";
import Icon5 from "../../../assets/enroll.svg";
import Icon6 from "../../../assets/certificate.svg";
import CourseDetailsIcon from "../../../assets/course-details.svg";

import { Constants } from "../../../config/Constants";
import { POST } from "../../../config/Api";
import CardHeading from "./CardHeading";
import { formatDate, formatTime } from "../../../utils";
import { useOutletContext } from "react-router-dom";

const CourseDetails = ({ courseOne }) => {
  const [languageName, setLanguageName] = useState("");
  const [termsArr, setTermsArr] = useState([]);

  const { completedModulesData } = useOutletContext() || {};

  useEffect(() => {
    courseOne?.courseLanguage && getCourseLanguageById(courseOne?.courseLanguage);
    courseOne?.termsArr?.length && getCourseTermsNameByIds(courseOne?.termsArr);
  }, [courseOne]);

  const getCourseLanguageById = async (id) => {
    try {
      const response = await POST(`${Constants.course}/language/${id}`);
      setLanguageName(response?.data?.data?.name);
    } catch (error) {
      console.log(error);
    }
  };
  const getCourseTermsNameByIds = async (ids) => {
    try {
      const response = await POST(`${Constants.course}/terms`, {
        termArr: ids,
      });
      setTermsArr(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const courseDetails = [
    {
      icon: <img src={Icon1} alt="Duration Icon" style={{ width: 50, height: 50 }} />,
      title: formatTime(courseOne?.duration?.hour),
      subtitle: "Course Duration",
    },
    courseOne?.direction && {
      icon: <img src={Icon2} alt="Direction Icon" style={{ width: 50, height: 50 }} />,
      title: courseOne.direction,
      subtitle: "Course Direction",
    },
    languageName && {
      icon: <img src={Icon3} alt="Language Icon" style={{ width: 50, height: 50 }} />,
      title: languageName,
      subtitle: "Language Available",
    },
    termsArr?.length > 0 && {
      icon: <img src={Icon4} alt="Audience Icon" style={{ width: 50, height: 50 }} />,
      title: termsArr.map((term) => term.title).join(", "), // Dynamically join names,
      subtitle: "Target Audience",
    },
    completedModulesData?.created_at && {
      icon: <img src={Icon5} alt="Platform Icon" style={{ width: 50, height: 50 }} />,
      title: formatDate(completedModulesData?.created_at),
      subtitle: "Enrolled on",
    },
    courseOne?.certification && {
      icon: <img src={Icon6} alt="Certificate Icon" style={{ width: 50, height: 50 }} />,
      title: courseOne?.isPaid ? "Paid Certificate" : "Free Certificate",
      subtitle: "Upon Course Completion",
    },
  ].filter(Boolean); // Filters out undefined items

  return (
    <Box
      sx={{
        marginTop: 4,
        borderRadius: 2,
        boxShadow: "0px 0px 8px 2px rgba(0, 0, 0, 0.15)",
        padding: "24px 24px 24px 0",
      }}
    >
      {/* Heading Section with Image */}
      <CardHeading image={CourseDetailsIcon} title="Course Details" />

      <Box
        sx={{
          padding: 3,
        }}
      >
        {/* Course Details Grid */}
        <Grid container spacing={3} justifyContent="center">
          {courseDetails.map((item, index) => (
            <Grid item lg={4} xs={12} key={index}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  backgroundColor: "#f6f8fe",
                  paddingTop: 4,
                  paddingBottom: 4,
                  paddingLeft: 3,
                  paddingRight: 3,
                  borderRadius: 2,
                  border: "1px solid #D4DCF3",
                }}
              >
                <Typography
                  variant="body2"
                  gutterBottom
                  fontSize={"1.125rem"}
                  marginBottom={"15px"}
                  fontWeight={600}
                >
                  {item.subtitle}
                </Typography>

                {item.icon}
                <Typography
                  variant="body2"
                  gutterBottom
                  marginTop={"10px"}
                  fontWeight={400}
                  fontSize={"1.125rem"}
                >
                  {item.title}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default CourseDetails;
