import React, { useState, useEffect } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Box, Button, Collapse, Skeleton, Alert, Snackbar } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/NavigateNext";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setEntrySourceAction } from "../../../redux/action";
import { ReactComponent as QueryIcon } from "../../../assets/QuerryIcon.svg";
import { ReactComponent as BlackQueryIcon } from "../../../assets/blackQuery.svg";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as IncorrectAnswerIcon } from "../../../assets/IncorrectAnswer.svg";

const MobileIncorrectBlocks = ({
  incorrectBlocks,
  unansweredblocks,
  userDetails,
  completedModulesData,
  loading,
  setCourseNav,
}) => {
  const [selectedBlockId, setSelectedBlockId] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const theme = useTheme();
  const [openContainers, setOpenContainers] = useState({});
  const { slug } = useParams();
  //const { cid } = useQueryParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function stripHTML(html) {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  }

  // Combine and group data
  const groupedData = {};
  const allBlocks = [...incorrectBlocks, ...unansweredblocks];

  allBlocks.forEach((block) => {
    const containerId = block.container;
    if (!groupedData[containerId]) {
      groupedData[containerId] = {
        containerDetails: block.containerDetails,
        blocks: [],
        incorrectCount: 0,
      };
    }
    groupedData[containerId].blocks.push(block);
    if (incorrectBlocks.some((incorrect) => incorrect._id === block._id)) {
      groupedData[containerId].incorrectCount += 1;
    }
  });

  useEffect(() => {
    allBlocks.reduce((acc, item) => {
      acc[item.container] = false;
      return acc;
    }, {});
  }, [allBlocks]);

  const handleToggle = (containerId) => {
    // Collapse the previously opened container and expand the new one
    setOpenContainers((prev) => ({
      [containerId]: !prev[containerId],
      ...Object.keys(prev).reduce((acc, key) => {
        if (key !== containerId) acc[key] = false;
        return acc;
      }, {}),
    }));
  };

  const sortedContainerIds = Object.keys(groupedData).sort((a, b) => {
    return (
      (groupedData[a].containerDetails?.order || 0) - (groupedData[b].containerDetails?.order || 0)
    );
  });

  const handleModuleOpen = async (item) => {
    try {
      // Async check for parentContainers' locked status
      const isParentLocked = item.containerDetails?.parentContainers
        ? await Promise.all(
            item.containerDetails?.parentContainers.map(async (parentContainerId) => {
              const parentModule = completedModulesData?.modules.find(
                (module) => module?.module_id === parentContainerId && module?.status === "locked",
              );
              return !!parentModule; // Return true if the parent is locked
            }),
          ).then((results) => results.every(Boolean)) // Check if all results are true (all parents are locked)
        : true;

      if (isParentLocked) {
        dispatch(setEntrySourceAction("menu_clicked"));
        setSelectedBlockId(item._id);
        navigate(`/course/${slug}/${item?.slug}?cid=${item?.course}&mid=${item?.container}`, {
          state: {
            userData: userDetails,
            blockId: item?._id,
          },
        });
        setCourseNav(false);
      } else {
        setPopupOpen(true);
      }
    } catch (err) {
      console.error("Error in handleModuleOpen:", err);
    }
  };

  return (
    <Box>
      {loading ? (
        <Skeleton variant="rounded" width={250} height={60} />
      ) : sortedContainerIds.length > 0 ? (
        sortedContainerIds.map((containerId, index) => (
          <>
            <ListItemButton
              onClick={() => handleToggle(containerId)}
              sx={{
                // bgcolor: openContainers[containerId] ? "#FFFFFF" : "", openContainers[containerId] ? "#0051A2" :
                borderRadius: "6px",
                pl: "8px",
                opacity: 0.95,
                borderBottom: "1px solid #8c8585",
                "& >div>.MuiListItemText-secondary": {
                  color: "#ffffffa1",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },

                "&:hover": {
                  bgcolor: "#4f89c3",
                  color: "white",
                  cursor: "pointer",
                  "& >div>.MuiListItemText-secondary": { color: "#ffffffa1" },
                },
                "&.Mui-selected": {
                  color: "black",
                  "& >div>.MuiListItemText-secondary": {
                    color: "#333333",
                  },
                  bgcolor: "#ffffff",
                  borderBottom: "1px solid #8c8585",
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: "auto" }}>
                {openContainers[containerId] ? <ExpandLess /> : <ExpandMore />}
              </ListItemIcon>
              <ListItemText primary={groupedData[containerId].containerDetails.title} />

              <Button
                size="small"
                variant={"contained"}
                sx={{
                  border: "2px solid #fff",
                  bgcolor: "#B3003D",
                  borderRadius: "50%",
                  minWidth: "19px",
                  minHeight: "19px",
                  lineHeight: "0.50",
                  padding: 0,
                  mr: 0,
                }}
              >
                {groupedData[containerId].blocks.length}
              </Button>
            </ListItemButton>

            <Collapse in={openContainers[containerId]} timeout="auto" unmountOnExit>
              {groupedData[containerId].blocks.map((block, index) => (
                <ListItemButton
                  key={index}
                  onClick={() => handleModuleOpen(block)}
                  sx={{
                    ml: 1,
                    padding: 0,
                    mt: 1,
                    borderRadius: "6px",
                    opacity: 0.95,
                    borderBottom: "1px solid #8c8585",
                    "& >div>.MuiListItemText-secondary": {
                      color: "#ffffffa1",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    },

                    "&:hover": {
                      bgcolor: "#4f89c3",
                      color: "white",
                      cursor: "pointer",
                      "& >div>.MuiListItemText-secondary": { color: "#ffffffa1" },
                    },
                    "&.Mui-selected": {
                      color: "black",
                      "& >div>.MuiListItemText-secondary": {
                        color: "#333333",
                      },
                      borderBottom: "2px solid #8c8585",
                    },
                  }}
                >
                  <ListItemIcon sx={{ minWidth: "auto", m: 2 }}>
                    {selectedBlockId === block?._id ? (
                      <QueryIcon fontSize={"small"} />
                    ) : (
                      <BlackQueryIcon fontSize={"small"} />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={stripHTML(block.title)} sx={{ fontSize: "14px" }} />
                  {incorrectBlocks.some((incorrect) => incorrect._id === block._id) && (
                    <ListItemIcon>
                      <IncorrectAnswerIcon
                        style={{
                          fill: "#DE4209",
                          width: "20",
                          height: "19",
                          //color: "#DE4209",
                          position: "absolute",
                          right: "5px",
                          top: 10,
                        }}
                      />
                    </ListItemIcon>
                  )}
                </ListItemButton>
              ))}
            </Collapse>
          </>
        ))
      ) : (
        <ListItemButton
          sx={{
            borderRadius: "6px",

            opacity: 0.95,
            borderBottom: "1px solid #8c8585",
            "& >div>.MuiListItemText-secondary": {
              color: "#ffffffa1",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            },
          }}
        >
          <ListItemText
            primary="There are no Wrong or Pending responses"
            sx={{
              ml: 2,
            }}
          />
        </ListItemButton>
      )}

      {/* Popup for locked modules */}
      <Snackbar
        open={popupOpen}
        autoHideDuration={4000}
        onClose={() => setPopupOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          [theme.breakpoints.up("sm")]: {
            top: "60%",
            left: "19%",
            right: "60%",
          },
        }}
      >
        <Alert severity="warning" onClose={() => setPopupOpen(false)}>
          Please complete the prerequisite modules before accessing this.
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MobileIncorrectBlocks;
