import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { GET, POST } from "../../config/Api";
import { Constants, userRoles } from "../../config/Constants";
import CourseBlockView from "./CourseBlockView";

import "./CourseCommonStyle.scss";
import KeywordsView from "../Keywords/KeywordsView";
import ButtonMain from "../ButtonMain";
import JoinCohort from "./JoinCohort/JoinCohort";
import { getS3ApiJsonKey, useQueryParams } from "../../utils";

import StepperModal from "./CourseModals/StepperModal";
import { ToastNotify } from "../SnackBar/ToastNotify";
import { POP_ONE_BREADCRUMB, PUSH_BREADCRUMB, RESET_BREADCRUMB } from "../../redux/constants";
import { Box, Button, Grid } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCertificateNameModal from "./CourseModals/CheckCertificateNameModal";
import { isValidUrl } from "../Validators";
import { cloudfrontUrl } from "../../config/BaseUrl";
import clickEvent from "../../utils/logging-event-utils";
import sendDataToFirehose from "../../utils/aws-utils";
import { getCohortDetailsByCohortId } from "../../utils/index";
import CourseIntroduction from "./CourseIntroduction/CourseIntroduction";

const CourseViewV2 = () => {
  const [open, setOpen] = useState(false);
  const [show, setshow] = useState(false);
  const [checkForCertificateModal, setCheckForCertificateModal] = useState(false);
  const [courseBlocks, setCourseBlocks] = useState([]);
  const [blockLoading, setBlockLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMsg, setshowMsg] = useState(false);
  const [showEval, setshowEval] = useState(false);
  const [showCohortDetails, setshowCohortDetails] = useState(false);
  const [generateCertModal, setGenerateCertModal] = useState(false);
  const [blockUserActivities, setBlockUserActivities] = useState([]);

  const {
    courseOne,
    completedModulesData,
    setCompletedModulesData,
    userDetails,
    moderator,
    showIncorrectTab,
  } = useOutletContext();
  const breadcrumbData = useSelector((state) => state.breadcrumb);

  let UserData = useSelector((state) => state.userLogin);
  const courseEntrySource = useSelector((state) => state.courseEntrySource.source);
  let isLoggedIn = UserData?.data ? UserData?.data?.isLoggedIn : false;

  const { cid, tabValue } = useQueryParams();

  const dispatch = useDispatch();
  const toastRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      (async () => {
        try {
          //save user last activity
          await POST(`${Constants.userActivity}?activity=courseLoad`, {
            user: UserData?.data?.user,
          });
        } catch (error) {
          console.error("Error updating learner activity:", error.message);
        }

        try {
          //get block user activity
          const response = await GET(
            `${Constants.getPublicCourseBlock}/blockUserActivity/${cid}/${userDetails.user_id}`,
          );
          setBlockUserActivities(response?.data);
        } catch (error) {
          // navigate("/404");
          console.log(error);
        }
      })();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_BREADCRUMB });
    };
  }, [showMsg]);

  useEffect(() => {
    if (courseOne.title && !breadcrumbData.some((item) => item.title === courseOne.title)) {
      const newBreadCrumb = {
        title: courseOne.title,
        navigate: `/course/${courseOne?.slug}?cid=${cid}`,
      };
      dispatch({ type: PUSH_BREADCRUMB, payload: newBreadCrumb });
    }
  }, [courseOne]);

  useEffect(() => {
    if (completedModulesData) {
      if (completedModulesData?.status === "enrolled") {
        setshowEval(true);
        if (completedModulesData?.cohortDetails?.type === "facilitated") {
          setshowCohortDetails(true);
        } else {
          setshowCohortDetails(false);
        }
        getCourseBlocks();
      } else if (completedModulesData?.status === "inactive") {
        setshow(true);
      }
    } else {
      setshow(true);
    }
  }, [completedModulesData]);

  useEffect(() => {
    courseOne?._id && getCourseBlocks();
    window.onpopstate = () => {
      dispatch({ type: POP_ONE_BREADCRUMB });
    };
  }, [courseOne]);

  useEffect(() => {
    if (isLoggedIn) {
      triggerUserEvent("course_opened");
      if (tabValue === "course-introduction") {
        if (courseOne?.courseIntroduction) {
          navigate(`/course/${courseOne?.slug}?cid=${cid}&tabValue=course-introduction`);
        } else {
          navigate(`/course/${courseOne?.slug}?cid=${cid}`);
        }
      }
    }
  }, [cid, isLoggedIn, courseOne, tabValue]);

  const getCourseBlocks = async () => {
    setBlockLoading(true);

    const url = `${getS3ApiJsonKey}/course/${courseOne._id}/landing.json`;

    const response = await fetch(url);

    if (response.status === 200) {
      const data = await response.json();
      setCourseBlocks(data);
    } else {
      //get public course blocks
      const response = await GET(`${Constants.getPublicCourseBlock}/${courseOne._id}`);
      setCourseBlocks(JSON.parse(response?.data?.data));
    }

    setBlockLoading(false);
  };

  const checkUserHasCohort = async () => {
    try {
      //check course has cohort or not
      const response = await GET(
        `${Constants.course}/CheckCoursehasCohort?user_id=${UserData?.data?.user?.id}&course_id=${cid}`,
      );
      let enrolledData = response?.data;
      if (enrolledData.status === "inactive") {
        setshowMsg(true);
        setshow(true);
      }
      return enrolledData;
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadCertificate = async () => {
    //save user last activity
    await POST(`${Constants.userActivity}?activity=downloadCertificate`, {
      user: UserData?.data?.user,
    });
    if (isValidUrl(completedModulesData?.certificateURL)) {
      window.open(completedModulesData?.certificateURL);
    } else {
      window.open(`${cloudfrontUrl}/${completedModulesData?.certificateURL}`);
    }
  };

  const handleUnlockModule = async () => {
    //unlock modules
    await POST(`${Constants.assessment}/unlockModules/${courseOne?._id}`)
      .then((res) => {
        if (res.data.success) {
          setCheckForCertificateModal(false);
          // getUserCourseActivity();
          window.location.reload();
          toastRef.current.showToastMessage(res?.data?.message, "success");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSDEnroll = async () => {
    try {
      let body = {
        user_id: UserData?.data?.user?.id,
        type: "self-directed",
        privacy: courseOne?.privacy,
        course_id: cid,
      };
      //enroll in cohort
      await POST(Constants.enrollIntoCohort, body)
        .then(async (res) => {
          toastRef.current.showToastMessage(res?.data?.message, "success");
          // getUserCourseActivity();
          setCompletedModulesData(res?.data?.data);
          if (res.data.success) {
            setshow(false);
            getCourseBlocks && getCourseBlocks();
            const cohortDetails =
              res?.data?.data?.cohort_id &&
              (await getCohortDetailsByCohortId(res?.data?.data?.cohort_id));
            triggerUserEvent(
              "enroll_course",
              cohortDetails?._id,
              cohortDetails?.title,
              cohortDetails?.type,
              cohortDetails?.eventTracking,
            );
            //save user last activity
            await POST(`${Constants.userActivity}?activity=enrollment`, {
              user: UserData?.data?.user,
            });
          }
        })
        .catch((err) => {
          toastRef.current.showToastMessage(err?.response?.data?.message, "error");
        });
      // await onEnrolled();
    } catch (error) {
      console.log(error);
    }
  };

  const handleEvaluateCertificate = () => {
    setCheckForCertificateModal(true);
  };

  // Loging user event
  const triggerUserEvent = (event, cohort_id, cohort_name, cohort_type, eventTracking, status) => {
    const eventData = new clickEvent();
    eventData.event = event;
    eventData.user_unique_id = UserData?.data?.user?.email;
    eventData.course_id = courseOne._id;
    eventData.cohort_id = cohort_id || completedModulesData?.cohortDetails?._id || "";
    eventData.course_name = courseOne?.title;
    eventData.course_type = courseOne?.privacy;
    eventData.cohort_name = cohort_name || completedModulesData?.cohortDetails?.title || "";
    eventData.cohort_type = cohort_type || completedModulesData?.cohortDetails?.type || "";
    eventData.course_open_ref = event === "course_opened" ? courseEntrySource : "";
    eventData.certificate_generation_status = status === true ? 1 : 0;

    if (eventTracking || completedModulesData?.cohortDetails?.eventTracking) {
      eventData.validateAndSendEvent(sendDataToFirehose);
    }
  };

  return (
    <>
      {isLoggedIn &&
      tabValue === "course-introduction" &&
      completedModulesData?.status === "enrolled" ? (
        <CourseIntroduction />
      ) : (
        <>
          <Box mt={2} className="course-container">
            <Typography
              dangerouslySetInnerHTML={{ __html: courseOne?.tagline }}
              variant="body1"
              sx={{
                margin: "0px",
                fontSize: "1.14286rem",
                lineHeight: 1.5,
              }}
            ></Typography>
            <KeywordsView keywords={courseOne} />
            {moderator && (
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  sx={{ mx: 2, marginRight: 0 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(`/course/createblock?cid=${cid}`, "_blank");
                  }}
                >
                  Switch to Editor View
                </Button>
              </Box>
            )}
            {showCohortDetails && (
              <>
                <p>
                  <strong>Cohort title</strong>: {completedModulesData?.cohortDetails?.title}
                </p>

                {completedModulesData?.cohortDetails?.customText && (
                  <Grid
                    container
                    className="courseCardMain preview-card"
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item md={6}>
                      <Grid item>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: completedModulesData?.cohortDetails?.customText,
                          }}
                        ></div>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Box>
          {isLoggedIn ? (
            <div>
              {/* {activeTab === "course-content" && ( */}
              <Box sx={{ display: { sm: "flex" }, width: "100%" }}>
                <Box sx={{ width: "100%", mt: 2 }}>
                  {completedModulesData?.status === "enrolled" && (
                    <CourseBlockView
                      courseBlocks={courseBlocks}
                      loading={blockLoading}
                      courseOne={courseOne}
                      completedModulesData={completedModulesData}
                      userDetails={userDetails}
                      blockUserActivities={blockUserActivities}
                    />
                  )}
                  {show && (
                    <>
                      {completedModulesData?.status === "inactive" ? (
                        <Box
                          p={3}
                          sx={{ width: "100%", bgcolor: "rgb(229, 246, 253)" }}
                          display="flex"
                          alignItems={"center"}
                          justifyContent="center"
                        >
                          <InfoOutlinedIcon color="primary" fontSize="large" />
                          <Typography
                            textAlign={"center"}
                            fontWeight={600}
                            ml={2}
                            color="rgb(1, 67, 97)"
                          >
                            Your enrollment request has been submitted and waiting for the
                            moderator's / facilitator's approval.
                          </Typography>
                        </Box>
                      ) : (
                        <ButtonMain
                          className="enrollnow-button"
                          // onClick={() => setOpen(true)}
                          onClick={() =>
                            courseOne?.is_container_course ? handleSDEnroll() : setOpen(true)
                          }
                        >
                          Enroll Now
                        </ButtonMain>
                      )}
                    </>
                  )}
                  {showEval && userDetails.role === userRoles.LEARNER && (
                    <>
                      {/* <Navigate /> */}
                      {courseOne?.certification &&
                        (!completedModulesData?.isCertificateGenerated ? (
                          <ButtonMain className={"markAsDone"} onClick={handleEvaluateCertificate}>
                            Evaluate for Certificate
                          </ButtonMain>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: "1rem",
                            }}
                          >
                            {completedModulesData?.certificateURL ? (
                              <ButtonMain
                                className={"markAsDone"}
                                onClick={handleDownloadCertificate}
                              >
                                Download Certificate
                              </ButtonMain>
                            ) : (
                              <ButtonMain
                                className={"markAsDone"}
                                onClick={() => setGenerateCertModal(true)}
                              >
                                Re-generate Certificate
                              </ButtonMain>
                            )}
                          </Box>
                        ))}
                    </>
                  )}
                  <JoinCohort
                    modalOpen={open}
                    modalClose={() => setOpen(false)}
                    setshow={setshow}
                    getCourseBlocks={getCourseBlocks}
                    modalTitle={"Join Cohort"}
                    courseOne={courseOne}
                    setshowMsg={setshowMsg}
                    onEnrolled={checkUserHasCohort}
                    setCompletedModulesData={setCompletedModulesData}
                  />
                </Box>
                {checkForCertificateModal && (
                  <StepperModal
                    modalOpen={checkForCertificateModal}
                    closeModal={() => setCheckForCertificateModal(false)}
                    loading={loading}
                    loadingClose={() => setLoading(false)}
                    setCompletedModulesData={setCompletedModulesData}
                    handleUnlockModule={handleUnlockModule}
                    savedCourseDetail={courseOne}
                    // getUserCourseActivity={getUserCourseActivity}
                    completedModulesData={completedModulesData}
                    triggerUserEvent={triggerUserEvent}
                    showIncorrectTab={showIncorrectTab}
                  />
                )}

                <CheckCertificateNameModal
                  generateCertModal={generateCertModal}
                  setGenerateCertModal={setGenerateCertModal}
                />
              </Box>
              {/* )} */}
            </div>
          ) : (
            <>
              <CourseBlockView
                courseBlocks={courseBlocks}
                loading={blockLoading}
                courseOne={courseOne}
                completedModulesData={completedModulesData}
              />
            </>
          )}

          <ToastNotify ref={toastRef} duration={4000} />
        </>
      )}
    </>
  );
};

export default CourseViewV2;
