import { Box } from "@mui/material";

const LeftBorder = () => {
  return (
    <Box
      role="presentation"
      sx={{
        width: "3.5px",
        height: "45px",
        backgroundColor: "#365082",
        borderRadius: "0px 6px 6px 0px",
      }}
    />
  );
};

export default LeftBorder;
