import { Box, Grid } from "@mui/material";
import React from "react";
import TextViews from "../courseViews/TextView/TextView";
import ButtonView from "../courseViews/ButtonView/ButtonView";
import DividerView from "../courseViews/DividerView/DividerView";
import ToggleList from "../courseViews/ToggleListView/ToggleList";
import ImageView from "../courseViews/ImageView/ImageView";
import MCQView from "../courseViews/MCQView/MCQView";
import FillView from "../courseViews/FillView/FillView";
import MatchView from "../courseViews/MatchView/MatchView";
import ResponseView from "../courseViews/ResponseView/ResponseView";
import ListView from "../courseViews/ListView/ListView";
import ComicView from "../courseViews/ComicView/ComicView";
import GridView from "../courseViews/GridView/GridView";
import { cloudfrontUrl } from "../../../config/BaseUrl";
import GifBoxOutlinedIcon from "@mui/icons-material/GifBoxOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import MoodBadOutlinedIcon from "@mui/icons-material/MoodBadOutlined";
import FormatAlignCenterOutlinedIcon from "@mui/icons-material/FormatAlignCenterOutlined";
import TvOutlinedIcon from "@mui/icons-material/TvOutlined";
import SourceIcon from "@mui/icons-material/Source";
import CodeOutlinedIcon from "@mui/icons-material/CodeOutlined";
import AdvancedFillView from "../courseViews/FillView/AdvancedFillView";
import AdvancedMatchView from "../courseViews/MatchView/AdvancedMatchView";

const RenderBlock = ({ block, courseOne }) => {
  if (block?.type === "container") {
    return (
      <>
        {/* Render module title only for top-level containers */}
        <Grid item className="fullwidth" lg={block?.size?.width}>
          <h1 id={block?._id}>{block?.title}</h1>
          <h3>{block?.text}</h3>

          {block?.children?.map((childBlock, index) => (
            <RenderBlock key={index} block={childBlock} courseOne={courseOne} />
          ))}
        </Grid>
      </>
    );
  }
  if (block?.type === "carousel_container") {
    return (
      <>
        {/* Render module title only for top-level containers */}
        <Grid item className="fullwidth" lg={block?.size?.width}>
          <h1> {block?.title}1</h1>

          {block?.children?.map((childBlock, index) => (
            <RenderBlock key={index} block={childBlock} courseOne={courseOne} />
          ))}
        </Grid>
      </>
    );
  }

  switch (block?.type) {
    case "text":
      return (
        <Grid item className="fullwidth" lg={block?.size?.width}>
          <TextViews block={block} courseOne={courseOne} />
        </Grid>
      );
    case "button":
      return (
        <Grid item className="fullwidth" lg={block?.size?.width}>
          <ButtonView button={block} courseOne={courseOne} />
        </Grid>
      );
    case "divider":
      return (
        <Grid item className="fullwidth" lg={block?.size?.width}>
          <DividerView divider={block} courseOne={courseOne} />
        </Grid>
      );
    case "toggle_list":
      return (
        <Grid item className="fullwidth" lg={block?.size?.width}>
          <ToggleList toggle_list={block?.items} blockId={block?._id} courseOne={courseOne} />
        </Grid>
      );
    case "image":
      return (
        <Grid item className="fullwidth" lg={block?.size?.width}>
          <ImageView image={block?.image} block={block} courseOne={courseOne} />
        </Grid>
      );
    case "file":
      return (
        <Grid item className="fullwidth" lg={block?.size?.width}>
          <div className={"link-blocks"}>
            <h3>{block?.title}</h3>
            <Box className="link-blocks-content">
              <InsertDriveFileOutlinedIcon />
              <a
                href={
                  block?.provider?.url?.startsWith(cloudfrontUrl)
                    ? block?.provider?.url
                    : cloudfrontUrl + "/" + block?.provider?.url
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {block?.provider?.url?.startsWith(cloudfrontUrl)
                  ? block?.provider?.url
                  : cloudfrontUrl + "/" + block?.provider?.url}
              </a>
            </Box>
          </div>
        </Grid>
      );
    case "link":
      return (
        <Grid item className="fullwidth" lg={block?.size?.width}>
          <h3>{block?.title}</h3>
          <img src={block?.image?.m} alt={block?.title} />
        </Grid>
      );
    case "gif":
      return (
        <Grid item className="fullwidth link-blocks" lg={block?.size?.width}>
          <div className="link-blocks-content">
            <GifBoxOutlinedIcon />
            <a href={block?.gif?.url} target="_blank" rel="noopener noreferrer">
              {block?.gif?.url}
            </a>
          </div>
        </Grid>
      );
    case "mcq":
      return (
        <Grid item className="fullwidth" lg={block?.size?.width}>
          <MCQView mcq={block} courseOne={courseOne} />
        </Grid>
      );
    case "survey-monkey":
      return (
        <Grid item className="fullwidth link-blocks" lg={block?.size?.width}>
          <div className="link-blocks-content">
            <MoodBadOutlinedIcon />
            <a href={block?.url} target="_blank" rel="noopener noreferrer">
              {block?.url}
            </a>
          </div>
        </Grid>
      );
    case "fs-form":
      return (
        <Grid item className="fullwidth link-blocks" lg={block?.size?.width}>
          <div className="link-blocks-content">
            <FormatAlignCenterOutlinedIcon />
            <a href={block?.url} target="_blank" rel="noopener noreferrer">
              {block?.url}
            </a>
          </div>
        </Grid>
      );
    case "fill":
      return (
        <Grid item className="fullwidth" lg={block?.size?.width}>
          <FillView fill={block} courseOne={courseOne} />
        </Grid>
      );
    case "match":
      return (
        <Grid item className="fullwidth" lg={block?.size?.width}>
          <MatchView match={block} courseOne={courseOne} />
        </Grid>
      );
    case "advancedFill":
      return (
        <Grid item className="fullwidth" lg={block?.size?.width}>
          <AdvancedFillView fill={block} courseOne={courseOne} />
        </Grid>
      );
    case "advancedMatch":
      return (
        <Grid item className="fullwidth" lg={block?.size?.width}>
          <AdvancedMatchView match={block} courseOne={courseOne} />
        </Grid>
      );
    case "response":
      return (
        <Grid item className="fullwidth" lg={block?.size?.width}>
          <ResponseView response={block} courseOne={courseOne} />
        </Grid>
      );
    case "list":
      return (
        <Grid item className="fullwidth" lg={block?.size?.width}>
          <ListView list={block} courseOne={courseOne} />
        </Grid>
      );
    case "comic":
      return (
        <Grid item className="fullwidth" lg={block?.size?.width}>
          <ComicView comic={block} courseOne={courseOne} />
        </Grid>
      );
    case "embed":
      return (
        <Grid item className="fullwidth link-blocks" lg={block?.size?.width}>
          <h3>{block?.title}</h3>
          <Box className="link-blocks-content">
            <CodeOutlinedIcon />
            <a
              href={block?.embed?.code?.match(/src="([^"]*)"/)[1]}
              target="_blank"
              rel="noopener noreferrer"
            >
              {block?.embed?.code?.match(/src="([^"]*)"/)[1]}
            </a>
          </Box>
        </Grid>
      );
    case "scorm":
      return (
        <Grid item className="fullwidth link-blocks" lg={block?.size?.width}>
          {block?.title && <h3>{block?.title}</h3>}
          <Box className="link-blocks-content">
            <SourceIcon />
            <a href={block?.scorm?.url} target="_blank" rel="noopener noreferrer">
              {block?.scorm?.url}
            </a>
          </Box>
        </Grid>
      );
    case "native-embed":
      return (
        <Grid item className="fullwidth " lg={block?.size?.width}>
          <div className="link-blocks">
            {block?.title && <h3>{block?.title}</h3>}
            <Box className="link-blocks-content">
              <TvOutlinedIcon />
              <a href={block?.nativeEmbed?.url} target="_blank" rel="noopener noreferrer">
                {block?.nativeEmbed?.url}
              </a>
            </Box>
          </div>
        </Grid>
      );
    case "grid":
      return (
        <Grid item className="fullwidth" lg={block?.size?.width}>
          <GridView grid={block} courseOne={courseOne} />
        </Grid>
      );
    default:
      return null;
  }
};

const BlockViews = ({ blockDetails, courseOne }) => {
  return (
    <Grid container spacing={3} columns={100}>
      {blockDetails?.map((block, index) => (
        <RenderBlock key={index} block={block} courseOne={courseOne} />
      ))}
    </Grid>
  );
};

export default BlockViews;
