import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CourseModuleIcon from "../../../assets/course-modules.svg";
import CardHeading from "./CardHeading";

export const CourseModules = ({ modDetails }) => {
  const [expanded, setExpanded] = useState(null);

  // Ensure the first module is expanded on initial render
  useEffect(() => {
    if (modDetails && modDetails.length > 0) {
      setExpanded(modDetails[0]._id);
    }
  }, [modDetails]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const renderFirstLevel = (nodes) => {
    return (
      <List sx={{ paddingLeft: 0 }}>
        {nodes.map((node) => (
          <Box key={node._id} sx={{ marginBottom: 3, borderRadius: "10px" }}>
            <Accordion
              sx={{
                backgroundColor: expanded === node._id ? "#F6F8FE" : "#ffffff",
                border: "0.5px solid #d4dcf3",
                boxShadow: "none",
              }}
              expanded={expanded === node._id && node.children?.length > 0}
              onChange={handleChange(node._id)}
            >
              <AccordionSummary
                expandIcon={
                  node.children?.length > 0 ? <ExpandMoreIcon sx={{ color: "#0051A2" }} /> : null
                }
                aria-controls={`panel-${node._id}-content`}
                id={`panel-${node._id}-header`}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: expanded === node._id && "#0051A2",
                    fontSize: "1.125rem",
                  }}
                >
                  {node.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {node.children && node.children.length > 0 ? (
                  <List>
                    {node.children.map((child) => (
                      <Typography
                        key={child._id}
                        sx={{ marginBottom: 1, color: "#353535", fontSize: "1.125rem" }}
                      >
                        {child.title}
                      </Typography>
                    ))}
                  </List>
                ) : (
                  <Typography>No sub-modules available</Typography>
                )}
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
      </List>
    );
  };

  return (
    <Box
      sx={{
        marginTop: 4,
        borderRadius: 2,
        boxShadow: "0px 0px 8px 2px rgba(0, 0, 0, 0.15)",
        padding: "24px 24px 24px 0",
      }}
    >
      <CardHeading image={CourseModuleIcon} title={"Course Modules"} />
      {/* Render only the first level of children */}
      <Box sx={{ padding: 3 }}>
        {modDetails && modDetails.length > 0 ? (
          renderFirstLevel(modDetails)
        ) : (
          <Typography>No modules available</Typography>
        )}
      </Box>
    </Box>
  );
};
