import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useOutletContext, useLocation } from "react-router-dom";
import { Box, Paper, IconButton, Typography, CircularProgress, Avatar } from "@mui/material";
import { POST } from "../../../../config/Api";
import { Constants } from "../../../../config/Constants";
import { styled } from "@mui/material/styles";
import { ToastNotify } from "../../../SnackBar/ToastNotify";
import BlockActions from "../BlockActions/BlockActions";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import clickEvent from "../../../../utils/logging-event-utils";
import sendDataToFirehose from "../../../../utils/aws-utils";
import { isLearnerView } from "../../../../utils";

const InputBox = styled("textarea")({
  flex: 1,
  marginRight: "1rem",
  width: "80%",
  padding: "8px",
  borderRadius: "4px",
  border: "1px solid #ccc",
  fontSize: "16px",
});

const GandhiBotView = ({ block, courseDetails, setmodalOpen, setblockModOpen, disableBlock }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(block?.is_hidden);
  const toastRef = useRef();
  let moduleDetails = useSelector((state) => state.savedModuleDetail);
  let location = useLocation();

  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};
  let isModerator = courseDetails?.members?.findIndex(
    (item) => item?.user?._id || item?.user === user?.id,
  );
  const { courseOne, completedModulesData } = useOutletContext() || {};

  const handleHover = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  const handleResponseSubmit = async () => {
    if (isLoggedIn) {
      const userResponseReg = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s,.'!?-]+$/;
      const validRes = userResponseReg.test(input);
      if (input === "" || input === null) {
        toastRef.current.showToastMessage("Please enter some text.", "error");
      } else if (!validRes) {
        toastRef.current.showToastMessage("Invalid response.", "error");
      } else {
        setLoading(true);
        try {
          // Add user message to the messages array
          const userMessage = { text: input, sender: "user" };
          setMessages((prev) => [...prev, userMessage]);

          // const previousUserMessages = messages
          //   .filter((msg) => msg.sender === "user") // Filter only user messages
          //   .map((msg) => msg.text) // Extract the text of user messages
          //   .join(" ");

          const bodyParams = {
            response: input,
          };

          const resp = await POST(
            `${Constants.saveAndUpdateUserActivities}/chatBot/${block?._id}`,
            bodyParams,
          );
          setInput("");
          triggerUserEvent("request");
          const botMessage = { text: resp.data.response, sender: "bot" }; // Assuming the API response contains the bot's message
          setMessages((prev) => [...prev, botMessage]);
          setLoading(false);
        } catch (error) {
          toastRef.current.showToastMessage("Something went wrong", "error");
        } finally {
          setLoading(false);
        }
      }
    }
  };

  // Handle Enter key press
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent newline in textarea
      handleResponseSubmit();
    }
  };

  const triggerUserEvent = (action) => {
    if (user?.email && moduleDetails?.data?.containerTitle && isLearnerView(location.pathname)) {
      const eventData = new clickEvent();
      eventData.event = "chatbot";
      eventData.user_unique_id = user.email;
      eventData.parent_container_id = moduleDetails.data.id;
      eventData.block_id = block._id;
      eventData.course_id = courseOne._id;
      eventData.cohort_id = completedModulesData?.cohortDetails?._id;
      eventData.course_name = courseOne?.title;
      eventData.course_type = courseOne?.privacy;
      eventData.cohort_name = completedModulesData?.cohortDetails?.title;
      eventData.cohort_type = completedModulesData?.cohortDetails?.type;
      eventData.block_title = moduleDetails?.data?.containerTitle;
      eventData.block_action = action;
      eventData.user_response = input;
      eventData.block_type = block?.type;

      // Ensure event tracking is enabled before sending
      if (completedModulesData?.cohortDetails?.eventTracking) {
        eventData.validateAndSendEvent(sendDataToFirehose);
      }
    }
  };

  return (
    <Box
      position={"relative"}
      className={`container-block ${disableBlock ? "disableBlock" : ""}`}
      onMouseOver={handleHover}
      onMouseLeave={handleMouseLeave}
      sx={{ height: "71vh", display: "flex", flexDirection: "column" }}
    >
      {isModerator !== -1 && isLoggedIn && isHovering && (
        <BlockActions
          blockId={block?._id}
          block={block}
          setmodalOpen={setmodalOpen}
          setblockModOpen={setblockModOpen}
          hidden={hidden}
          setHidden={setHidden}
        />
      )}

      <Paper
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          //marginBottom: 1,
          padding: 2,
          borderRadius: 2,
          border: "2px solid #ccc",
          borderBottom: "0px",
          backgroundColor: "#fff",
          //boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography variant="h6">{block?.title}</Typography>
        {/* Messages Container */}

        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          {/* Block Title */}
          <Box
            sx={{
              padding: 2,
              borderRadius: 1,
              mb: 1,
            }}
          >
            <Typography variant="h7">
              Welcome! Here, you can ask any question, and this bot will respond to you in the
              thoughtful and compassionate style of Mahatma Gandhi. Feel free to seek advice, share
              your thoughts, or explore Gandhi’s philosophy through this conversation.
            </Typography>
          </Box>
          {messages?.map((msg, idx) => (
            <Box
              key={idx}
              sx={{
                textAlign: msg.sender === "user" ? "right" : "left",
                mb: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: msg.sender === "user" ? "flex-end" : "flex-start",
                }}
              >
                {/* Bot Avatar */}
                {msg.sender === "bot" && (
                  <Avatar sx={{ bgcolor: "#f50057", marginRight: 1 }}>G</Avatar>
                )}
                {/* Message Bubble */}
                <Box
                  sx={{
                    backgroundColor: msg.sender === "user" ? "#e3f2fd" : "#f5f5f5",
                    padding: 2,
                    borderRadius: 1,
                    maxWidth: "70%",
                  }}
                >
                  <Typography>{msg.text}</Typography>
                </Box>
                {/* User Avatar */}
                {msg.sender === "user" && (
                  <Avatar sx={{ bgcolor: "#3f51b5", ml: 1 }}>
                    {user?.name?.charAt(0).toUpperCase()}
                  </Avatar>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </Paper>
      {/* Input Box */}
      <Box
        component="form"
        sx={{
          display: "flex",
          borderRadius: 2,
          width: "100%",
          position: "sticky",
          border: "2px solid #ccc",
          borderTop: "0px",
        }}
        onSubmit={(e) => {
          e.preventDefault();
          handleResponseSubmit();
        }}
      >
        <InputBox
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Chat with GandhiBot..."
          onKeyDown={handleKeyDown}
          disabled={disableBlock}
          sx={{ p: 1 }}
        />
        <IconButton
          color="primary"
          onClick={handleResponseSubmit}
          disabled={loading || disableBlock}
          sx={{ mr: 2 }}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <ArrowUpwardIcon sx={{ fontSize: "1.4rem" }} />
          )}
        </IconButton>
      </Box>

      <ToastNotify ref={toastRef} duration={3000} />
    </Box>
  );
};

export default GandhiBotView;
