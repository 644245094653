import React, { useRef, useEffect, useState } from "react";
import { Box, Grid, IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import DiscussionView from "../DiscussionView/DiscussionView";
import Slide from "@mui/material/Slide";
import ComicView from "../../courseViews/ComicView/ComicView";
import EmbedView from "../../courseViews/EmbedView/EmbedView";
import ListView from "../../courseViews/ListView/ListView";
import ResponseView from "../../courseViews/ResponseView/ResponseView";
import GridView from "../../courseViews/GridView/GridView";
import MatchView from "../../courseViews/MatchView/MatchView";
import FillView from "../../courseViews/FillView/FillView";
import MCQView from "../../courseViews/MCQView/MCQView";
import GifView from "../../courseViews/GifView/GifView";
import LinkView from "../../courseViews/LinkView/LinkView";
import { FileView } from "../../courseViews/FileView/FileView";
import { VideoView } from "../../courseViews/VideoView/VideoView";
import { AudioView } from "../../courseViews/AudioView/AudioView";
import ImageView from "../../courseViews/ImageView/ImageView";
import ToggleList from "../../courseViews/ToggleListView/ToggleList";
import DividerView from "../../courseViews/DividerView/DividerView";
import ButtonView from "../../courseViews/ButtonView/ButtonView";
import TextViews from "../../courseViews/TextView/TextView";
import NativeEmbedView from "../../courseViews/NativeEmbedView/NativeEmbedView";
import ScormView from "../../courseViews/ScormView/ScormView";
import { useLocation, useOutletContext } from "react-router-dom";
import { GET } from "../../../../config/Api";
import { Constants } from "../../../../config/Constants";
import { FullScreenLoader } from "../../../Loader/Loader";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { CircularProgress } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SurveyMonkeyView from "../SurveyMonkeyView/SurveyMonkeyViews";
import clickEvent from "../../../../utils/logging-event-utils";
import sendDataToFirehose from "../../../../utils/aws-utils";
import { useQueryParams, isLearnerView } from "../../../../utils";
import FSFormView from "../FSFormView/FSFormView";
import GameView from "../GameView/GameView";
import AdvancedFillView from "../FillView/AdvancedFillView";
import AdvancedMatchView from "../MatchView/AdvancedMatchView";
import GandhiBotView from "../GandhiBotView/GandhiBotView";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BlockWrapper = styled.div({
  width: "100%",
  height: "80vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  overflow: "auto",
  padding: "3rem",
  "& > div > div > div> span": {
    display: "none",
  },
  "& > div > div > span > svg": {
    display: "none",
  },
  "& > div > div > div > svg": {
    display: "none",
  },
  "& > div > div > div > div> svg": {
    display: "none",
  },

  "& > div > div > div > div> div.blockActions": {
    display: "none",
  },

  "& > div > div > div.blockActions": {
    display: "none",
  },
  "& > div > div > div > div.blockActions": {
    display: "none",
  },
  "& > div ": {
    width: "100%",
  },
});

const CarouselContainer = styled(Box)({
  position: "relative",
});

const ArrowButton = styled(IconButton)(({ disabled }) => ({
  position: "absolute",
  top: "45%",
  transform: "translateY(-50%)",
  zIndex: 10,
  padding: "10px",
  color: disabled ? "grey" : "inherit",
}));

const LeftArrow = styled(ArrowButton)({
  left: "7px",
});

const RightArrow = styled(ArrowButton)({
  right: "7px",
});

const CarosalModal = ({
  open,
  onClose,
  block,
  isHighlight,
  dialogTitle,
  setisHighlight,
  courseDetails,
  isLoading,
  carouselContainerDetails,
}) => {
  const carouselRef = useRef(null);
  const [loading, setLoading] = useState(isLoading || false);
  const [currentBlockIndex, setCurrentBlockIndex] = useState(0);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 600);
  const [blockUserActivities, setBlockUserActivities] = useState([]);
  const [isFullscreen, setIsFullscreen] = useState(false); // Add state for fullscreen
  const { completedModulesData, courseOne } = useOutletContext();

  //let navigate = useNavigate();
  let location = useLocation();
  let { isLoggedIn, user } = useSelector((state) => state?.userLogin?.data || {});
  const { mid } = useQueryParams();

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      // await fetchContainerDetails();
      await fetchBlockUserDetails();
    };

    if (open) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.slideTo(currentBlockIndex);
    }
  }, [currentBlockIndex]);

  useEffect(() => {
    // Update fullscreen state based on document.fullscreenElement
    setIsFullscreen(!!document.fullscreenElement);
    const onFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", onFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", onFullscreenChange);
    };
  }, []);

  useEffect(() => {
    open && isLoggedIn && isLearnerView(location.pathname) && triggerUserEvent("carousel_opened");
  }, [open, isLoggedIn]);

  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  const fetchBlockUserDetails = async () => {
    try {
      setLoading(true);
      //get block user activity
      const response = await GET(
        `${Constants.getPublicCourseBlock}/blockUserActivity/${courseDetails?._id}/${user?.id}`,
      );
      setBlockUserActivities(response?.data);
      setLoading(false);
    } catch (error) {
      // navigate("/404");
      console.log(error);
      setLoading(false);
    }
  };

  const handleFullscreenToggle = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };

  const handleSlideChanged = (event) => {
    event.isNextSlideDisabled && triggerUserEvent("carousel_scrolled", "right");
    event.isPrevSlideDisabled && triggerUserEvent("carousel_scrolled", "left");
    setCurrentBlockIndex(event.item);
  };

  const triggerUserEvent = (event, scrollStatus) => {
    if (user?.email) {
      const eventData = new clickEvent();
      eventData.event = event;
      eventData.user_unique_id = user.email;
      eventData.course_id = courseOne._id;
      eventData.cohort_id = completedModulesData?.cohortDetails?._id;
      eventData.parent_container_id = mid || "";
      eventData.block_id = block._id;
      eventData.block_title = block?.title;
      eventData.course_name = courseOne?.title;
      eventData.course_type = courseOne?.privacy;
      eventData.cohort_name = completedModulesData?.cohortDetails?.title;
      eventData.cohort_type = completedModulesData?.cohortDetails?.type;
      eventData.block_type = block.type;
      eventData.carousel_scroll = scrollStatus || "";

      // Ensure event tracking is enabled before sending
      if (completedModulesData?.cohortDetails?.eventTracking) {
        eventData.validateAndSendEvent(sendDataToFirehose);
      }
    }
  };

  const progress = ((currentBlockIndex + 1) / carouselContainerDetails?.length) * 100;

  const items =
    carouselContainerDetails?.length &&
    carouselContainerDetails?.map((item, index) => (
      <BlockWrapper key={index}>
        {item?.type === "text" && (
          <Grid item md={item?.size?.width}>
            <TextViews
              block={item}
              theme={item?.theme}
              art={item?.art}
              courseDetails={courseDetails}
            />
          </Grid>
        )}
        {item?.type === "button" && (
          <Grid item md={item?.size?.width}>
            <ButtonView key={item} button={item} block={item} courseDetails={courseDetails} />
          </Grid>
        )}
        {item?.type === "divider" && (
          <Grid item md={item?.size?.width}>
            <DividerView
              key={item}
              divider={item}
              theme={isLoggedIn ? "light" : "dark"}
              block={item}
              courseDetails={courseDetails}
            />
          </Grid>
        )}
        {item?.type === "toggle_list" && item?.items?.length > 0 ? (
          <Grid item md={item?.size?.width}>
            <ToggleList
              toggle_list={item?.items}
              theme={item.theme}
              block={item}
              courseDetails={courseDetails}
            />
          </Grid>
        ) : (
          ""
        )}
        {item?.type === "image" && (
          <Grid item md={item?.size?.width}>
            <ImageView
              image={item?.image}
              key={item?.image}
              theme={item.theme}
              block={item}
              courseDetails={courseDetails}
            />
          </Grid>
        )}
        {item?.type === "audio" && (
          <Grid item md={item?.size?.width}>
            <AudioView
              audio={item?.provider}
              key={item?.provider}
              block={item}
              courseDetails={courseDetails}
            />
          </Grid>
        )}
        {item?.type === "video" && (
          <Grid item md={item?.size?.width}>
            <VideoView video={item} block={item} courseDetails={courseDetails} />
          </Grid>
        )}
        {item?.type === "file" && (
          <Grid item md={item?.size?.width}>
            <FileView file={item} data={item} block={item} courseDetails={courseDetails} />
          </Grid>
        )}
        {item?.type === "link" && (
          <Grid item md={item?.size?.width}>
            <LinkView link={item} block={item} courseDetails={courseDetails} />
          </Grid>
        )}

        {item?.type === "gif" && (
          <Grid item md={item?.size?.width}>
            <GifView gif={item?.gif} block={item} courseDetails={courseDetails} />
          </Grid>
        )}
        {item?.type === "mcq" && (
          <Grid item md={item?.size?.width}>
            <MCQView
              mcq={item}
              block={item}
              mcqs={item.mcqs}
              isHighlight={isHighlight?.some((block) => block === item._id)}
              setisHighlight={setisHighlight}
              courseDetails={courseDetails}
              blockUserActivities={blockUserActivities}
            />
          </Grid>
        )}
        {item?.type === "survey-monkey" && (
          <Grid item md={item?.size?.width}>
            <SurveyMonkeyView
              block={item}
              courseDetails={courseDetails}
              blockUserActivities={blockUserActivities}
            />
          </Grid>
        )}
        {item?.type === "game" && (
          <Grid item md={item?.size?.width}>
            <GameView
              block={item}
              courseDetails={courseDetails}
              blockUserActivities={blockUserActivities}
            />
          </Grid>
        )}
        {item?.type === "fs-form" && (
          <Grid item md={item?.size?.width}>
            <FSFormView
              block={item}
              courseDetails={courseDetails}
              blockUserActivities={blockUserActivities}
            />
          </Grid>
        )}
        {item?.type === "game" && (
          <Grid item md={item?.size?.width}>
            <GameView
              block={item}
              courseDetails={courseDetails}
              blockUserActivities={blockUserActivities}
            />
          </Grid>
        )}
        {item?.type === "fill" && (
          <Grid item md={item?.size?.width}>
            <FillView
              fill={item}
              block={item}
              isHighlight={isHighlight?.some((block) => block === item._id)}
              courseDetails={courseDetails}
              blockUserActivities={blockUserActivities}
            />
          </Grid>
        )}
        {item?.type === "match" && (
          <Grid item md={item?.size?.width} height={"100%"}>
            <MatchView
              match={item}
              block={item}
              blockUserActivities={blockUserActivities}
              courseDetails={courseDetails}
            />
          </Grid>
        )}

        {item?.type === "advancedFill" && (
          <Grid item md={item?.size?.width}>
            <AdvancedFillView
              fill={item}
              block={item}
              isHighlight={isHighlight?.some((block) => block === item._id)}
              courseDetails={courseDetails}
              blockUserActivities={blockUserActivities}
            />
          </Grid>
        )}
        {item?.type === "advancedMatch" && (
          <Grid item md={item?.size?.width} height={"100%"}>
            <AdvancedMatchView
              match={item}
              block={item}
              blockUserActivities={blockUserActivities}
              courseDetails={courseDetails}
            />
          </Grid>
        )}

        {item?.type === "grid" && (
          <Grid item md={item?.size?.width}>
            <GridView grid={item} block={item} courseDetails={courseDetails} />
          </Grid>
        )}

        {item?.type === "gandhibot" && (
          <Grid item md={item?.size?.width}>
            <GandhiBotView
              response={item}
              block={item}
              art={item?.art}
              blockUserActivities={blockUserActivities}
              courseDetails={courseDetails}
            />
          </Grid>
        )}

        {item?.type === "response" && (
          <Grid item md={item?.size?.width}>
            <ResponseView
              response={item}
              block={item}
              art={item?.art}
              blockUserActivities={blockUserActivities}
              isHighlight={isHighlight?.some((block) => block === item._id)}
              setisHighlight={setisHighlight}
              courseDetails={courseDetails}
            />
          </Grid>
        )}
        {item?.type === "list" && (
          <Grid item md={item?.size?.width}>
            <ListView list={item} block={item} courseDetails={courseDetails} />
          </Grid>
        )}
        {item?.type === "embed" && (
          <Grid item md={item?.size?.width}>
            <EmbedView embed={item} block={item} courseDetails={courseDetails} />
          </Grid>
        )}
        {item?.type === "comic" && (
          <Grid item md={item?.size?.width}>
            <ComicView
              text={item.text}
              image={item.image}
              block={item}
              courseDetails={courseDetails}
            />
          </Grid>
        )}
        {item?.type === "discussion" && (
          <Grid item md={item?.size?.width}>
            <DiscussionView discussion={item} block={item} courseDetails={courseDetails} />
          </Grid>
        )}
        {item?.type === "scorm" && (
          <Grid item md={item?.size?.width}>
            <ScormView scorm={item} block={item} courseDetails={courseDetails} />
          </Grid>
        )}

        {item?.type === "native-embed" && (
          <Grid item md={item?.size?.width}>
            <NativeEmbedView block={item} courseDetails={courseDetails} />
          </Grid>
        )}
      </BlockWrapper>
    ));

  return (
    <Dialog
      fullScreen
      open={open}
      sx={{ "& .MuiDialog-paperFullScreen": { backgroundColor: "rgb(37 29 29 / 86%)" } }}
      onClose={onClose}
      TransitionComponent={Transition}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {carouselContainerDetails?.length > 0 ? (
        <>
          <Toolbar sx={{ mb: 2 }}>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                color: "white",
              }}
            >
              <Box sx={{ position: "relative", display: "inline-flex", marginRight: "1rem" }}>
                <CircularProgress
                  variant="determinate"
                  value={progress}
                  size={45}
                  thickness={4}
                  sx={{ color: "white" }}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="caption" component="div" color="white">{`${Math.round(
                    progress,
                  )}%`}</Typography>
                </Box>
              </Box>
              {isMouseOver && (
                <Typography
                  // sx={{ flex: 1, display: "flex", alignItems: "center" }}
                  variant="h7"
                  component="div"
                >
                  <span>
                    {currentBlockIndex + 1} / {carouselContainerDetails?.length}
                  </span>
                </Typography>
              )}
            </Box>
            {isMouseOver && (
              <Typography
                sx={{ flex: 1, display: "flex", alignItems: "center", color: "white" }}
                variant="h7"
                component="div"
              >
                {isMobileView
                  ? dialogTitle.length > 11
                    ? `${dialogTitle.substring(0, 11)}...`
                    : dialogTitle
                  : dialogTitle}
              </Typography>
            )}
            <Box sx={{ color: "white" }}>
              <IconButton
                onClick={handleFullscreenToggle}
                color="inherit"
                aria-label={isFullscreen ? "exit fullscreen" : "fullscreen"}
              >
                {isFullscreen ? (
                  <FullscreenExitIcon sx={{ fontSize: 30 }} />
                ) : (
                  <FullscreenIcon sx={{ fontSize: 30 }} />
                )}
              </IconButton>
              {!isFullscreen && (
                <IconButton color="inherit" onClick={onClose} aria-label="close">
                  <CloseIcon sx={{ fontSize: 30 }} />
                </IconButton>
              )}
            </Box>
          </Toolbar>
          {loading ? (
            <FullScreenLoader open={loading} />
          ) : (
            <CarouselContainer>
              <AliceCarousel
                ref={carouselRef}
                mouseTracking
                items={items}
                onSlideChanged={handleSlideChanged}
                activeIndex={currentBlockIndex}
                disableButtonsControls={true}
                dotsDisabled={true} // This hides the pagination dots
                keyboardNavigation={true} // Enable keyboard navigation
              />
              <LeftArrow
                onClick={() => carouselRef.current?.slidePrev()}
                color="inherit"
                aria-label="prev"
                disabled={currentBlockIndex === 0}
              >
                <ArrowBackIcon sx={{ fontSize: 30, color: "white" }} />
              </LeftArrow>
              <RightArrow
                onClick={() => carouselRef.current?.slideNext()}
                color="inherit"
                aria-label="next"
                disabled={currentBlockIndex === carouselContainerDetails.length - 1}
              >
                <ArrowForwardIcon sx={{ fontSize: 30, color: "white" }} />
              </RightArrow>
            </CarouselContainer>
          )}
        </>
      ) : (
        ""
      )}
    </Dialog>
  );
};

export default CarosalModal;
