import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Toolbar,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import "../CourseCommonStyle.scss";
import { GET, POST } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import { useDispatch, useSelector } from "react-redux";
import { cloudfrontUrl } from "../../../config/BaseUrl";
import { isValidUrl } from "../../Validators";
import { ToastNotify } from "../../SnackBar/ToastNotify";
import { rerenderBlocksAction } from "../../../redux/action";
import CertificationModal from "./CertificationModal";
import FullScreenModal from "./FullScreenModal";
import CertificateTemplate from "../../CertificateConfig/CertificatePreview/CertificateTemplate";
import defaultCertBg from "../../../assets/DefaultCertificate.png";
import ImageUploader from "../../Image/ImageUploader";
import QuillEditor from "../../QuillEditor/QuillEditor";
import AdvanceCourseInfo from "./AdvanceCourseInfo";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NewCourseModal = ({ modalOpen, modalClose, data }) => {
  let dispatch = useDispatch();
  let UserData = useSelector((state) => state.userLogin.data);

  const toastRef = useRef();
  const [courseData, setcourseData] = useState({
    title: "",
    description: "",
    core: "",
    sel: "",
    sdg: "",
    terms: "",
    termsArr: [],
    privacy: "private",
    image: "",
    certification: false,
    certificateConfig: "",
    signatoryConfig: "",
    isPaid: false,
    price: "",
    cohortVisibility: "both",
    discussionForum: false,
    learnerDashboard: false,
    courseIntroduction: false,
    durationHour: 0,
    direction: "",
    outcomes: [],
    skills: [],
  });
  const [courseDataError, setcourseDataError] = useState({
    title: "",
    terms: "",
    price: "",
    courseLang: "",
    direction: "",
    outcome: "",
    skill: "",
  });
  const [uploadImage, setUploadImage] = useState();
  const [preview, setPreview] = useState("");
  const [terms, setTerms] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [openCertification, setOpenCertification] = useState(false);
  const [certificates, setCertificates] = useState([]);
  const [signatories, setSignatories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [courseLang, setCourseLang] = useState({});
  const [languages, setLanguages] = useState([{}]);
  const [certmodalOpen, setcertModalOpen] = useState(false);
  const [certData, setCertData] = useState({});
  const [signData, setSignData] = useState({});

  let navigate = useNavigate();

  // useEffect(() => {
  //   const initializeData = async () => {
  //     if (modalOpen) {
  //       setLoading(true);

  //       if (data) {
  //         const selectedTerms = terms
  //           .filter((term) => data.termsArr.includes(term._id))
  //           .map((term) => term.title); // Map to titles for display

  //         setcourseData({
  //           title: data?.title || "",
  //           description: data?.tagline || "",
  //           core: data?.tags?.core || "",
  //           sel: data?.tags?.sel || "",
  //           sdg: data?.tags?.sdg || "",
  //           terms: data?.terms || "",
  //           termsArr: selectedTerms || [],
  //           privacy: data?.privacy || "private",
  //           certification: data?.certification || false,
  //           certificateConfig: data?.certificate_config || "",
  //           signatoryConfig: data?.signatory_config || "",
  //           isPaid: data?.isPaid || false,
  //           price: data?.price || "",
  //           cohortVisibility: data?.cohortVisibility || "both",
  //           discussionForum: data?.discussionForum || false,
  //           learnerDashboard: data?.learnerDashboard || false,
  //           courseIntroduction: data?.courseIntroduction || false,
  //           durationHour: data?.duration?.hour || 0,
  //           direction: data?.direction || "",
  //           outcomes: data?.outcomes || [],
  //           skills: data?.skills || [],
  //           image: "",
  //         });
  //       }

  //       try {
  //         // Load certificates, signatories, terms, and languages
  //         const certRes = await GET(`${Constants.getAllCertificate}`);
  //         const termsRes = await GET(Constants.course + "/terms");
  //         const langsRes = await GET(`${Constants.course}/languages/courseLanguage`);

  //         setCertificates(certRes?.data?.certificates);
  //         setSignatories(certRes?.data?.signatories);
  //         setTerms(termsRes?.data?.allTerms);
  //         setLanguages(langsRes?.data?.languages);

  //         // Load course image and language if in edit mode
  //         if (data?.image) {
  //           setIsEdit(true);
  //           const imageUrl = isValidUrl(data.image.l)
  //             ? data.image.l
  //             : `${cloudfrontUrl}/${data.image.l}`;
  //           setUploadImage(imageUrl);
  //           setPreview(imageUrl);
  //         }

  //         const lang = langsRes?.data?.languages?.find((el) => el._id === data?.courseLanguage);
  //         setCourseLang(lang);

  //         // Load course pricing
  //         if (data?._id) {
  //           const priceRes = await GET(`${Constants.course}/pricing/${data?._id}`);

  //           if (priceRes?.data?.priceInfo) {
  //             setcourseData((prev) => ({
  //               ...prev,
  //               price: priceRes?.data?.priceInfo?.price,
  //             }));
  //           } else {
  //             setcourseData((prev) => ({
  //               ...prev,
  //               price: "",
  //             }));
  //           }
  //         }
  //       } catch (error) {
  //         console.error(error);
  //       } finally {
  //         setLoading(false);
  //       }
  //     }
  //   };

  //   initializeData();
  // }, [modalOpen, data]);

  // Reset function

  useEffect(() => {
    const initializeData = async () => {
      if (!modalOpen) return; // Exit if modal is not open

      setLoading(true);

      try {
        // Step 1: Load certificates, signatories, terms, and languages
        const certRes = await GET(`${Constants.getAllCertificate}`);
        const termsRes = await GET(`${Constants.course}/terms`);
        const langsRes = await GET(`${Constants.course}/languages/courseLanguage`);

        const allTerms = termsRes?.data?.allTerms || [];
        setTerms(allTerms); // Update terms state

        setCertificates(certRes?.data?.certificates || []);
        setSignatories(certRes?.data?.signatories || []);
        setLanguages(langsRes?.data?.languages || []);

        // Step 2: Load course image and language if in edit mode
        if (data?.image) {
          setIsEdit(true);
          const imageUrl = isValidUrl(data.image.l)
            ? data.image.l
            : `${cloudfrontUrl}/${data.image.l}`;
          setUploadImage(imageUrl);
          setPreview(imageUrl);
        }

        const lang = langsRes?.data?.languages?.find((el) => el._id === data?.courseLanguage);
        setCourseLang(lang);

        // Step 3: Calculate `selectedTerms` after `terms` update
        if (data) {
          const selectedTerms = allTerms
            .filter((term) => data.termsArr.includes(term._id))
            .map((term) => term.title); // Map to titles for display

          // Step 4: Update course data
          setcourseData({
            title: data?.title || "",
            description: data?.tagline || "",
            core: data?.tags?.core || "",
            sel: data?.tags?.sel || "",
            sdg: data?.tags?.sdg || "",
            terms: data?.terms || "",
            termsArr: selectedTerms || [],
            privacy: data?.privacy || "private",
            certification: data?.certification || false,
            certificateConfig: data?.certificate_config || "",
            signatoryConfig: data?.signatory_config || "",
            isPaid: data?.isPaid || false,
            price: data?.price || "",
            cohortVisibility: data?.cohortVisibility || "both",
            discussionForum: data?.discussionForum || false,
            learnerDashboard: data?.learnerDashboard || false,
            courseIntroduction: data?.courseIntroduction || false,
            durationHour: data?.duration?.hour || 0,
            direction: data?.direction || "",
            outcomes: data?.outcomes || [],
            skills: data?.skills || [],
            image: "",
          });
        }

        // Step 5: Load course pricing
        if (data?._id) {
          const priceRes = await GET(`${Constants.course}/pricing/${data?._id}`);

          setcourseData((prev) => ({
            ...prev,
            price: priceRes?.data?.priceInfo?.price || "",
          }));
        }
      } catch (error) {
        console.error("Error during initialization:", error);
      } finally {
        setLoading(false);
      }
    };

    initializeData();
  }, [modalOpen, data]); // Dependency array

  const resetCourseData = () => {
    setcourseData({
      title: "",
      description: "",
      core: "",
      sel: "",
      sdg: "",
      terms: "",
      termsArr: [],
      privacy: "private",
      image: "",
      certification: false,
      certificateConfig: "",
      signatoryConfig: "",
      isPaid: false,
      price: "",
      cohortVisibility: "",
      discussionForum: false,
      learnerDashboard: false,
      courseIntroduction: false,
      direction: "",
      duration: "",
    });
    setcourseDataError({});
    setPreview("");
    setUploadImage(undefined);
  };

  // Reset state when modal closes
  useEffect(() => {
    if (!modalOpen) resetCourseData();
  }, [modalOpen]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setcourseDataError((prevState) => ({
      ...prevState,
      [name]: "",
    }));

    if (name === "terms") {
      setcourseData((prevState) => {
        prevState.termsArr = typeof value === "string" ? value.split(",") : value;
        return prevState;
      });
    } else {
      setcourseData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  }, []);

  useEffect(() => {
    if (!uploadImage) {
      setPreview(undefined);
      return;
    }
    let objectUrl;

    if (isEdit) {
      setPreview(uploadImage);
    } else {
      objectUrl = URL.createObjectURL(uploadImage);
      setPreview(objectUrl);
    }

    return () => {
      URL.revokeObjectURL(uploadImage);
    };
  }, [uploadImage, isEdit]);

  const onSelectFile = async (image) => {
    setcourseData({ ...courseData, image: image });

    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);
    setUploadImage(image);
    setIsEdit(false);
  };

  const handleNewCourse = (e) => {
    e.preventDefault();
    if (courseData.title === "") {
      setcourseDataError({ ...courseDataError, title: "Please enter a title" });
    } else if (courseData.isPaid && !courseData.price) {
      setcourseDataError({ ...courseDataError, price: "Course price cannot be empty or 0." });
    } else if (courseData.termsArr.length === 0) {
      setcourseDataError({ ...courseDataError, terms: "Please select terms" });
    } else if (!courseLang?._id) {
      setcourseDataError({ ...courseDataError, courseLang: "Please select course language" });
    } else if (courseData.courseIntroduction && courseData.direction.trim() === "") {
      setcourseDataError({ ...courseDataError, direction: "Please select a course direction" });
    } else if (courseData.courseIntroduction && courseData?.outcomes?.length === 0) {
      setcourseDataError({ ...courseDataError, outcome: "Please add atleast one outcomes." });
    } else if (courseData.courseIntroduction && courseData?.skills?.length === 0) {
      setcourseDataError({ ...courseDataError, skill: "Please add atleast one skill." });
    } else {
      // let selectedTerms = terms.find((item) => item.title === courseData.terms);
      let selectedTerms = terms
        .filter((el) => courseData.termsArr.includes(el.title))
        .map((el) => el?._id);
      let formData = new FormData();
      formData.append("image", courseData.image);
      formData.append("title", courseData.title);
      formData.append("tagline", courseData.description);
      formData.append("core", courseData.core);
      formData.append("sel", courseData.sel);
      formData.append("sdg", courseData.sdg);
      formData.append("terms", selectedTerms._id);
      formData.append("termsArr", selectedTerms);
      formData.append("privacy", courseData.privacy);
      formData.append("userId", UserData.user.id);
      formData.append("isPaid", courseData.isPaid);
      formData.append("price", courseData.price);
      formData.append("certification", courseData.certification || false);
      formData.append("certificateConfig", courseData.certificateConfig || "");
      formData.append("signatoryConfig", courseData.signatoryConfig || "");
      formData.append("cohortVisibility", courseData.cohortVisibility || "both");
      formData.append("discussionForum", courseData.discussionForum || false);
      formData.append("learnerDashboard", courseData.learnerDashboard || false);
      formData.append("courseIntroduction", courseData.courseIntroduction || false);
      formData.append("outcomes", courseData.outcomes || []);
      formData.append("skills", courseData.skills || []);
      formData.append("durationHour", courseData.durationHour || 0);
      formData.append("durationMinute", courseData.durationMinute || 0);
      formData.append("direction", courseData.direction || "");
      formData.append("courseLanguage", courseLang?._id || "");
      setLoading(true);
      //edit course
      POST(
        data ? `${Constants.course}/manage/editCourse/${data._id}` : Constants.createCourse,
        formData,
        "multipart/form-data",
      )
        .then((res) => {
          if (res?.data?.success) {
            toastRef.current.showToastMessage(res?.data?.message, "success");
            dispatch(rerenderBlocksAction(true));
          } else {
            toastRef.current.showToastMessage("Unable to modify course", "error");
          }
          setUploadImage(undefined);
          setLoading(false);
          // setTimeout(() => {
          modalClose();
          // }, 500);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const fetchData = async () => {
    // Fetch initial data for certificate and signatory if they are already selected
    if (courseData.certificateConfig) {
      const certData = await getConfiguration(courseData.certificateConfig);
      setCertData(certData);
    }
    if (courseData.signatoryConfig) {
      const signData = await getConfiguration(courseData.signatoryConfig);
      setSignData(signData);
    }
  };

  useEffect(() => {
    fetchData();
  }, [courseData.certificateConfig, courseData.signatoryConfig]);

  const getConfiguration = async (configId) => {
    try {
      //get getCertificateConfig by config id
      let response = await POST(`${Constants.getCertificateConfig}/${configId}`);
      return response?.data?.configuration;
    } catch (error) {
      navigate("/404");
    }
  };

  const handleCertificateChange = async (e) => {
    setcourseData({ ...courseData, certificateConfig: e.target.value });
    const data = await getConfiguration(e.target.value);
    setCertData(data);
  };
  const handleSignatoryChange = async (e) => {
    if (e.target.value === "none") {
      setcourseData({ ...courseData, signatoryConfig: "" });
      setSignData({});
    } else {
      setcourseData({ ...courseData, signatoryConfig: e.target.value });
      const data = await getConfiguration(e.target.value);
      setSignData(data);
    }
  };

  return (
    <>
      <Dialog fullScreen open={modalOpen} onClose={modalClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: "sticky", top: 0, bgcolor: "#111333" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {data ? (
                <>
                  <IconButton color="inherit">
                    <ModeEditOutlineOutlinedIcon />
                  </IconButton>
                  Edit course
                </>
              ) : (
                "Create new course"
              )}
            </Typography>

            <Box
              sx={{
                "&:hover": {
                  bgcolor: "warning.main",
                },
              }}
            >
              <Button
                autoFocus
                color="inherit"
                edge="start"
                variant="outlined"
                onClick={modalClose}
                aria-label="close"
              >
                <CloseIcon />
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress />
          </Box>
        ) : (
          <Box p={5}>
            <form onSubmit={handleNewCourse}>
              <Grid container spacing={3}>
                <Grid container item xs={12} lg={9} md={8} order={{ xs: 2, lg: 1 }}>
                  <Grid item lg={12} xs={12} mb={3}>
                    <TextField
                      variant="outlined"
                      name="title"
                      fullWidth
                      type="text"
                      label="Course Name*"
                      placeholder="Course Name*"
                      value={courseData.title}
                      onChange={handleChange}
                    />
                    {courseDataError.title && (
                      <Typography variant="body2" color={"error"} component={"span"}>
                        {courseDataError.title}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={12} xs={12} mb={3}>
                    <QuillEditor
                      placeholder="Course Description*"
                      value={courseData.description}
                      handleChange={handleChange}
                      height={245}
                    />{" "}
                    <br />
                    {courseDataError.description && (
                      <Typography variant="body2" color={"error"} component={"span"}>
                        {courseDataError.description}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid item lg={3} md={4} xs={12}>
                  <Box>
                    <ImageUploader
                      onCropped={onSelectFile}
                      initialImage={preview}
                      circularCrop
                      title={"Choose course cover image minimum size 800 X 800"}
                      className={"courseCover-imageUpload"}
                      accept="image/*"
                      targetWidth={800}
                      targetHeight={800}
                      icon={true}
                      modaltype="certificate"
                    />
                  </Box>
                </Grid>
              </Grid>

              <Divider className="Coursedivider">Privacy, Cohort Visibility & Audience</Divider>
              <Grid container my={2} spacing={2}>
                <Grid lg={4} sm={4} xs={12} item>
                  <FormControl fullWidth>
                    <InputLabel id="fs-privacy-label">Privacy</InputLabel>
                    <Select
                      labelId="fs-privacy-label"
                      id="fs-privacy"
                      name="privacy"
                      label="Privacy"
                      value={courseData.privacy}
                      onChange={handleChange}
                    >
                      <MenuItem value={"private"}>Private</MenuItem>
                      {UserData?.user?.type === "admin" && (
                        <MenuItem value={"public"}>Public</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  {courseDataError.privacy && (
                    <Typography variant="body2" color={"error"} component={"span"}>
                      {courseDataError.privacy}
                    </Typography>
                  )}
                </Grid>
                {UserData?.user?.type === "admin" && (
                  <Grid lg={4} sm={4} xs={12} item>
                    <>
                      <FormControl fullWidth>
                        <InputLabel id="cohort-visibilty-label">Cohort Visibility</InputLabel>
                        <Select
                          labelId="cohort-visibilty-label"
                          id="cohort-visibilty"
                          label="Cohort Visibility"
                          name="cohortVisibility"
                          value={courseData.cohortVisibility}
                          onChange={handleChange}
                        >
                          <MenuItem value="selfDirected">Self Directed</MenuItem>
                          <MenuItem value="facilitated">Facilitated</MenuItem>
                          <MenuItem value="both">Both</MenuItem>
                        </Select>
                      </FormControl>
                      {courseDataError.cohortVisibility && (
                        <Typography variant="body2" color={"error"} component={"span"}>
                          {courseDataError.cohortVisibility}
                        </Typography>
                      )}
                    </>
                  </Grid>
                )}
                <Grid item lg={4} sm={4} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="select-audience-label">Select Audience</InputLabel>
                    <Select
                      labelId="select-audience-label"
                      id="select-audience"
                      label="Select Audience"
                      onChange={handleChange}
                      multiple
                      name="terms"
                      value={courseData.termsArr}
                      renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {selected?.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                    >
                      {terms.length > 0 &&
                        terms.map((item, i) => {
                          return (
                            <MenuItem value={item.title} key={i}>
                              {item.title}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  {courseDataError.terms && (
                    <Typography variant="body2" color={"error"} component={"span"}>
                      {courseDataError.terms}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    fullWidth
                    options={languages}
                    getOptionLabel={(option) => option.name + " " + option.nativeName}
                    value={courseLang}
                    onChange={(e, newValue) => {
                      setCourseLang(newValue);
                      setcourseDataError((prevState) => ({
                        ...prevState,
                        courseLang: "",
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="course_language"
                        type="text"
                        label="Course Language"
                      />
                    )}
                  />
                  {courseDataError.courseLang && (
                    <Typography variant="body2" color={"error"} component={"span"}>
                      {courseDataError.courseLang}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Divider textAlign="center">Keywords</Divider>
              <Grid mb={3} mt={1} container spacing={2}>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    variant="outlined"
                    name="core"
                    fullWidth
                    type="text"
                    label="Core*"
                    placeholder="Core subjects: Physics, Maths, Civics, ...*"
                    value={courseData.core}
                    onChange={handleChange}
                    errorMsg={courseDataError.core}
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    variant="outlined"
                    name="sel"
                    fullWidth
                    type="text"
                    label="SEL components*"
                    placeholder="SEL components: Empathy, Compassion, ..."
                    value={courseData.sel}
                    onChange={handleChange}
                    errorMsg={courseDataError.sel}
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    variant="outlined"
                    name="sdg"
                    fullWidth
                    type="text"
                    label="Target SDGs*"
                    placeholder="Target SDGs: Gender Equality, Climate Change, ..."
                    value={courseData.sdg}
                    onChange={handleChange}
                    errorMsg={courseDataError.sdg}
                  />
                </Grid>
              </Grid>
              <Divider />
              <Grid container spacing={3} my={2}>
                <Grid item lg={2.4} xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        label="Show Discussion Forum"
                        value={courseData.discussionForum}
                        checked={courseData.discussionForum}
                        onChange={() =>
                          setcourseData({
                            ...courseData,
                            discussionForum: !courseData.discussionForum,
                          })
                        }
                      />
                    }
                    label="Show Discussion Forum"
                  />
                </Grid>
                <Grid item lg={2.4} xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        label="Enable Learner Dashboard"
                        value={courseData.learnerDashboard}
                        checked={courseData.learnerDashboard}
                        onChange={() =>
                          setcourseData({
                            ...courseData,
                            learnerDashboard: !courseData.learnerDashboard,
                          })
                        }
                      />
                    }
                    label="Enable Learner Dashboard"
                  />
                </Grid>
                <Grid item lg={2} xs={12}>
                  {UserData?.user?.type === "admin" && (
                    <>
                      <FormControlLabel
                        control={
                          <Switch
                            label="Enable Certification"
                            value={courseData.certification}
                            checked={courseData.certification}
                            onChange={() =>
                              setcourseData((prevState) => ({
                                ...prevState,
                                certification: !prevState.certification,
                                certificateConfig: !prevState.certification
                                  ? prevState.certificateConfig
                                  : "",
                                signatoryConfig: !prevState.certification
                                  ? prevState.signatoryConfig
                                  : "",
                              }))
                            }
                          />
                        }
                        label="Enable Certification"
                      />

                      {courseData.certification && (
                        <>
                          <Box my={2}>
                            <FormControl fullWidth>
                              <InputLabel id="certification-label">
                                Primary Configuration
                              </InputLabel>
                              <Select
                                labelId="certification-label"
                                id="certification"
                                name="certificate"
                                label="Primary Configuration"
                                value={courseData.certificateConfig}
                                onChange={handleCertificateChange}
                                renderValue={(selected) => {
                                  if (!selected) {
                                    return (
                                      <span className="dropdownLabel">
                                        Select Certificate Configuration
                                      </span>
                                    );
                                  } else {
                                    let selectedItem = certificates.find(
                                      (item) => item?._id === selected,
                                    );

                                    return <span>{selectedItem?.configuration_title}</span>;
                                  }
                                }}
                              >
                                {certificates?.length > 0 &&
                                  certificates?.map((item, i) => {
                                    return (
                                      <MenuItem value={item._id} key={item?._id}>
                                        {item?.configuration_title}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                          </Box>
                          <Box my={2}>
                            <FormControl fullWidth>
                              <InputLabel id="signatory-label">Secondary Configuration</InputLabel>
                              <Select
                                labelId="signatory-label"
                                id="signatory"
                                label="Secondary Configuration"
                                name="signatories"
                                value={courseData.signatoryConfig}
                                onChange={handleSignatoryChange}
                                disabled={!courseData.certificateConfig}
                                renderValue={(selected) => {
                                  if (!selected) {
                                    return (
                                      <span className="dropdownLabel">
                                        Select Additional Signatory Configuration
                                      </span>
                                    );
                                  } else {
                                    let selectedItem = signatories.find(
                                      (item) => item?._id === selected,
                                    );

                                    return <span>{selectedItem?.configuration_title}</span>;
                                  }
                                }}
                              >
                                <MenuItem value="none">None</MenuItem>
                                {signatories?.length > 0 &&
                                  signatories?.map((item, i) => {
                                    return (
                                      <MenuItem value={item._id} key={item?._id}>
                                        {item?.configuration_title}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                          </Box>
                        </>
                      )}
                    </>
                  )}
                  {/* {(UserData?.user?.type === "admin" || UserData?.user?.type === "moderator") && (
                  <> */}

                  {courseData.certificateConfig && (
                    <>
                      <Typography
                        onClick={() => {
                          setcertModalOpen(true);
                        }}
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          textDecoration: "underline",
                        }}
                      >
                        Preview Certificate
                      </Typography>

                      <FullScreenModal
                        open={certmodalOpen}
                        setOpen={() => {
                          setcertModalOpen(false);
                        }}
                        dialogTitle={"Certificate Preview "}
                      >
                        <Grid
                          item
                          className="fullwidth"
                          xs={12}
                          justifyContent={"center"}
                          display="flex"
                          mt={4}
                        >
                          {Object.keys(certData).length ? (
                            <CertificateTemplate certData={certData} signData={signData} />
                          ) : (
                            <img src={defaultCertBg} alt="certBg" width="100%" />
                          )}
                        </Grid>
                      </FullScreenModal>
                    </>
                  )}
                  {/* </>
                )} */}
                </Grid>
                {/* <Divider orientation="vertical" variant="middle" /> */}
                <Grid item lg={2.4} xs={12}>
                  {UserData?.user?.type === "admin" && (
                    <>
                      <FormControlLabel
                        control={
                          <Switch
                            label="Make this a paid course"
                            value={courseData.isPaid}
                            checked={courseData.isPaid}
                            onChange={() =>
                              setcourseData({
                                ...courseData,
                                isPaid: !courseData.isPaid,
                                price: "",
                              })
                            }
                          />
                        }
                        label="Make this a paid course"
                      />
                      {courseData.isPaid && (
                        <Box position={"relative"}>
                          <TextField
                            variant="outlined"
                            name="price"
                            fullWidth
                            type="text"
                            label="Price in USD*"
                            placeholder="Price in USD"
                            value={courseData.price}
                            sideLabel={"Price in USD"}
                            onChange={(e) => {
                              const isNumber = /^(?:0|\d+(\.\d+)?)?$/.test(e.target.value);
                              if (isNumber) {
                                handleChange(e);
                              }
                            }}
                            errorMsg={courseDataError.price}
                          />
                          {courseDataError.price && (
                            <Typography variant="body2" color={"error"} component={"span"}>
                              {courseDataError.price}
                            </Typography>
                          )}
                        </Box>
                      )}
                    </>
                  )}
                </Grid>
                <Grid item lg={2.8} xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        label="Enable Course Introduction"
                        value={courseData.courseIntroduction}
                        checked={courseData.courseIntroduction}
                        onChange={() =>
                          setcourseData({
                            ...courseData,
                            courseIntroduction: !courseData.courseIntroduction,
                          })
                        }
                      />
                    }
                    label="Enable Course Introduction"
                  />
                </Grid>
              </Grid>

              {courseData?.courseIntroduction && (
                <>
                  <Divider textAlign="center">Advance Course Info</Divider>
                  <AdvanceCourseInfo
                    courseData={courseData}
                    setcourseData={setcourseData}
                    courseDataError={courseDataError}
                    setcourseDataError={setcourseDataError}
                    handleChange={handleChange}
                  />
                </>
              )}
            </form>

            <Button
              variant="contained"
              color="primary"
              className="save_cohort_button"
              disabled={loading}
              onClick={handleNewCourse}
            >
              Save
            </Button>
          </Box>
        )}
      </Dialog>
      <ToastNotify ref={toastRef} duration={4000} />
      <CertificationModal
        modalOpen={openCertification}
        setOpenCertification={setOpenCertification}
      />
    </>
  );
};

export default NewCourseModal;
