import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { POST } from "../../../../config/Api";
import { Constants } from "../../../../config/Constants";
import ButtonMain from "../../../ButtonMain";
import ArtView from "../ArtView/ArtView";
import { VideoRecord } from "../VideoView/VideoView";
import { UploadFile } from "../FileView/FileView";
import "./AIResponseView.scss";
import { AudioRecording } from "../AudioView/AudioView";
import { useQueryParams } from "../../../../utils";
import BlockActions from "../BlockActions/BlockActions";
import { Alert, AlertTitle, Box, CircularProgress, Typography } from "@mui/material";
import { ToastNotify } from "../../../SnackBar/ToastNotify";
import { useLocation, useOutletContext } from "react-router-dom";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import clickEvent from "../../../../utils/logging-event-utils";
import sendDataToFirehose from "../../../../utils/aws-utils";

const AIResponseView = ({
  response,
  art,
  courseDetails,
  blockUserActivities,
  courseDetail,
  courseOne,
  setmodalOpen,
  setblockModOpen,
  block,
  disableBlock,
  isHighlight,
  setisHighlight,
}) => {
  let UserData = useSelector((state) => state.userLogin);
  const { cid } = useQueryParams();

  const [responseText, setResponseText] = useState("");
  const [disable, setDisable] = React.useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(block?.is_hidden);
  const [loading, setloading] = useState(false);
  const [checkUserInputs, setcheckUserInputs] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [stdTip, setStdTip] = useState("");

  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};
  let isModerator = (courseDetails || courseDetail)?.members?.findIndex(
    (item) => item?.user?._id || item?.user === user?.id,
  );
  let moduleDetails = useSelector((state) => state.savedModuleDetail);
  const { completedModulesData } = useOutletContext() || {};

  const toastRef = useRef();

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  let location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith("/course/createblock")) {
      setDisable(true);
    }
  }, [location]);

  useEffect(() => {
    const data = blockUserActivities?.data?.find((item) => item.block_id === response._id);
    setResponseText(data?.response_value || "");
    if (data) {
      setcheckUserInputs(true);
    } else {
      setcheckUserInputs(false);
    }
  }, [blockUserActivities]);

  const handleResponseSubmit = async () => {
    if (isLoggedIn) {
      const userResponseReg = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s,.'!?-]+$/;
      const validRes = userResponseReg.test(responseText);
      if (responseText === "" || responseText === null) {
        toastRef.current.showToastMessage("Please enter some text.", "error");
      } else if (!validRes) {
        toastRef.current.showToastMessage("Invalid response.", "error");
      } else if (responseText.toLowerCase().trim() === block.title.toLowerCase().trim()) {
        toastRef.current.showToastMessage(
          "You cannot just put the question in your response.",
          "error",
        );
      } else {
        setDisable(true);
        setloading(true);
        try {
          let bodyParams = {
            user_id: UserData.data.user.id,
            course_id: cid,
            activity: "AIresponse",
            response_type: "text",
            response_value: responseText,
          };
          const resp = await POST(
            `${Constants.saveAndUpdateUserActivities}/${response?._id}`,
            bodyParams,
          );

          triggerUserEvent(`${checkUserInputs ? "update" : "save"}`);
          setFeedbackText(resp?.data?.data?.tips);
          setStdTip(resp?.data?.data?.stdTip);
          setcheckUserInputs(true);
          setDisable(false);
          setloading(false);
          toastRef.current.showToastMessage(
            "Your response has been submitted successfully.",
            "success",
          );
        } catch (error) {
          toastRef.current.showToastMessage("Something went wrong", "error");
          setloading(false);
        }
      }
    }
  };

  const triggerUserEvent = (action) => {
    if (user?.email && moduleDetails?.data?.containerTitle) {
      const eventData = new clickEvent();
      eventData.event = "user_input";
      eventData.user_unique_id = user.email;
      eventData.parent_container_id = moduleDetails.data.id;
      eventData.block_id = block._id;
      eventData.course_id = courseOne._id;
      eventData.cohort_id = completedModulesData?.cohortDetails?._id;
      eventData.course_name = courseOne?.title;
      eventData.course_type = courseOne?.privacy;
      eventData.cohort_name = completedModulesData?.cohortDetails?.title;
      eventData.cohort_type = completedModulesData?.cohortDetails?.type;
      eventData.block_title = moduleDetails?.data?.containerTitle;
      eventData.block_action = action;
      eventData.user_response = responseText.trim();
      eventData.AIFeedback = feedbackText;
      eventData.block_type = block?.type;

      // Ensure event tracking is enabled before sending
      if (completedModulesData?.cohortDetails?.eventTracking) {
        eventData.validateAndSendEvent(sendDataToFirehose);
      }
    }
  };

  return (
    <Box
      position={"relative"}
      className={`container-block response-block ${disableBlock ? "disableBlock" : ""}
      ${isHighlight && response?.response_type === "text" && "highlightBlock"}
        ${
          response?.theme === "" || response?.theme === undefined
            ? `${isLoggedIn ? "postBgColor" : "defaultStyle"}`
            : response?.theme
        }`}
      onMouseOver={handleHover}
      onMouseLeave={handleMouseLeave}
    >
      {isModerator !== -1 && isLoggedIn && isHovering && (
        <BlockActions
          blockId={response?._id}
          block={response}
          setmodalOpen={setmodalOpen}
          setblockModOpen={setblockModOpen}
          hidden={hidden}
          setHidden={setHidden}
        />
      )}
      <p className="block-title">
        {response?.is_required && <span className="required">*</span>}
        {response?.title}
        {response.response_type === "audio" && (
          <AudioRecording
            block_id={response?._id}
            blockUserActivities={blockUserActivities}
            courseDetail={courseDetail}
            courseDetails={courseDetails}
            courseOne={courseOne}
            disableBlock={disableBlock}
          />
        )}
        {response.response_type === "video" && (
          <VideoRecord
            block_id={response?._id}
            blockUserActivities={blockUserActivities}
            courseDetail={courseDetail}
            courseDetails={courseDetails}
            courseOne={courseOne}
            disableBlock={disableBlock}
          />
        )}
        {response.response_type === "file" && (
          <>
            <Typography mt={1}>{response?.text}</Typography>
            <UploadFile
              block_id={response?._id}
              blockUserActivities={blockUserActivities}
              courseDetail={courseDetail}
              courseDetails={courseDetails}
              courseOne={courseOne}
              disableBlock={disableBlock}
            />
          </>
        )}
      </p>
      {response?.response_type === "text" && (
        <div dangerouslySetInnerHTML={{ __html: response.text }}></div>
      )}
      {response?.response_type === "text" && (
        <>
          <textarea
            placeholder="Write your thoughts/answer here..."
            disabled={disableBlock}
            className={`text-response entity-text ${
              isLoggedIn ? "post-entity-text" : "pre-entity-text"
            }`}
            value={responseText}
            onChange={(e) => {
              setResponseText(e.target.value);
              setisHighlight &&
                setisHighlight((prevState) => prevState.filter((item) => item !== response._id));
            }}
          ></textarea>{" "}
          <br />
          {(response.showFeedback || response.stdTip) && (feedbackText || stdTip) && (
            <Box my={2}>
              <Alert icon={<TipsAndUpdatesOutlinedIcon />} severity="info">
                {response?.stdTip && <AlertTitle>{stdTip}</AlertTitle>}
                {feedbackText}
              </Alert>
            </Box>
          )}
          <br />
          <ButtonMain
            onClick={handleResponseSubmit}
            disabled={disable || disableBlock}
            style={{ padding: `${loading ? "5px 25px" : ""}` }}
          >
            {loading ? <CircularProgress size={17} /> : checkUserInputs ? "Update" : "Save"}
          </ButtonMain>
        </>
      )}
      <ArtView art={art} />
      <ToastNotify ref={toastRef} duration={3000} />
    </Box>
  );
};

export default AIResponseView;
