// Import necessary components, hooks, and functions from MUI, custom components, and utility files.
import { Divider, FormControl, FormControlLabel, MenuItem, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { POST } from "../../../../../config/Api";
import { Constants, creditPoints, scoreLists } from "../../../../../config/Constants";
import CustomAccordion from "../../../../Accrodion/Accordion";
import ButtonMain from "../../../../ButtonMain";
import { CheckboxInput, Dropdown, InputBox } from "../../../../InputBox/InputBox";
import CustomizedSnackbars from "../../../../SnackBar/CustomizedSnackbars";
import CustomTextArea from "../../../../TextArea/CustomTextArea";
import { useQueryParams } from "../../../../../utils";
import { useOutletContext } from "react-router-dom";

// Define functional component AddAITextResponse with multiple input props.
const AddAITextResponse = ({
  assessmentSettings,
  block,
  type,
  updateOrder,
  setblockModOpen,
  setmodalOpen,
  setCourseBlocks,
  blockModalOpen,
}) => {
  // Define states with useState hook for managing form inputs and related variables.
  const [inputValue, setInputValue] = useState({
    title: block?.title || "",
    description: block?.text || "",
    correctAnswers: block?.keywords || "",
    score: block?.typeOfBlock || "attempt",
    credit: block?.score || 5,
    checked: false,
    is_required: block?.is_required || false,
    showFeedback: block?.showFeedback || false,
    stdTip: block?.stdTip,
  });
  // Additional states to manage assessment, form disable, alert text, Snackbar open, and alert type.
  const [assessment, setAssessment] = useState("");
  const [disable, setDisable] = useState(false);
  const [errText, seterrText] = useState("");
  const [open, setOpen] = useState(false);
  const [alertType, setalertType] = useState("error");
  const { setCourseOne } = useOutletContext();

  // Extract userLogin state from Redux store using useSelector hook and assign to UserData variable.
  let UserData = useSelector((state) => state.userLogin);

  const { cid, mid } = useQueryParams();

  useEffect(() => {
    // Set the assessment state based on the block prop when it changes.
    if (block) {
      setAssessment(block.assessmentType ? block.assessmentType : "");
    }
  }, [block]);

  const handleSubmit = async () => {
    // Function to handle form submission.
    if (inputValue.title === "") {
      setOpen(true);
      seterrText("Please Enter Title.");
    } else if (inputValue.description === "") {
      setOpen(true);
      seterrText("Please Enter Description");
    } else {
      let body = {
        user_id: UserData?.data?.user?.id,
        course: cid,
        container: mid,
        order: blockModalOpen?.addBelow ? blockModalOpen?.currentBlockOrder + 1 : updateOrder || 1,
        response_type: "text",
        highlightIfIncorrect: inputValue.checked,
        typeOfBlock: inputValue.score,
        score: inputValue.credit,
        title: inputValue.title,
        text: inputValue.description,
        keywords: inputValue.correctAnswers,
        assessmentType: assessment,
        type: type,
        is_required: inputValue.is_required,
        showFeedback: inputValue.showFeedback,
        stdTip: inputValue.stdTip,
      };
      // console.log({ body });
      // return;
      setDisable(true);
      await POST(`${Constants.addBlock}/AIresponse?_id=${block?._id}`, body)
        .then((response) => {
          setDisable(false);
          setOpen(true);
          setalertType("success");
          seterrText("Block Created Successfully");
          setCourseOne((prev) => ({ ...prev, published: false }));
          if (type === "edit") {
            setCourseBlocks &&
              setCourseBlocks((prev) => {
                const elIndex = prev.findIndex((el) => el._id === block?._id);
                const updatedBlocks = prev.map((el) => {
                  if (el.order >= response?.data?.order) {
                    el.order += 1;
                  }
                  return el;
                });
                updatedBlocks.splice(elIndex, 1, response?.data);
                updatedBlocks.sort((a, b) => a?.order - b?.order);
                return updatedBlocks;
              });
          } else {
            if (blockModalOpen?.addBelow) {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  const updatedBlocks = prev.map((el) => {
                    if (el.order >= response?.data?.order) {
                      el.order += 1;
                    }
                    return el;
                  });
                  const elIndex = updatedBlocks.findIndex(
                    (el) => el._id === blockModalOpen?.currentBlockId,
                  );
                  updatedBlocks.splice(elIndex + 1, 0, response?.data);
                  return updatedBlocks;
                });
            } else {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  prev.forEach((el) => {
                    el.order = el.order + 1;
                  });
                  return [response?.data, ...prev];
                });
            }
          }
          setmodalOpen && setmodalOpen((prev) => ({ ...prev, modalOpen: false }));
          setblockModOpen(false);
        })
        .catch((error) => console.log(error));
    }
  };
  return (
    <div>
      <FormControl
        sx={{ paddingBottom: "10px", width: "100%" }}
        variant="standard"
        // ... refer here for TextResponse blanking issue
        disabled={!assessmentSettings?.isAdvanceAssessment}
      >
        <Dropdown
          onChange={(event) => setAssessment(event.target.value)}
          value={assessment}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <span className="dropdownLabel">Select a Domain</span>;
            } else {
              let selectedDomain = assessmentSettings?.assessmentDomain?.find(
                (domain) => domain?._id === selected,
              );
              return <span>{selectedDomain?.title}</span>;
            }
          }}
        >
          {assessmentSettings?.assessmentDomain?.length > 0 &&
            assessmentSettings?.assessmentDomain?.map((item, i) => {
              return (
                <MenuItem value={item?._id} key={i}>
                  {item?.title}
                </MenuItem>
              );
            })}
        </Dropdown>
      </FormControl>{" "}
      <br />
      <InputBox
        placeholder="Question"
        onChange={(event) => setInputValue({ ...inputValue, title: event.target.value })}
        value={inputValue.title}
      />{" "}
      <br />
      <CustomTextArea
        placeholder="Description/Hints"
        onChange={(event) => setInputValue({ ...inputValue, description: event.target.value })}
        value={inputValue.description}
      />{" "}
      <br />
      <FormControlLabel
        control={
          <Switch
            checked={inputValue.showFeedback}
            onChange={(event) =>
              setInputValue({ ...inputValue, showFeedback: event.target.checked })
            }
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Show AI Feedback"
      />
      <FormControlLabel
        control={
          <Switch
            checked={inputValue.stdTip}
            onChange={(event) => setInputValue({ ...inputValue, stdTip: event.target.checked })}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Show Standard Feedback"
      />
      <br />
      <b>Correct answers:</b>
      <CustomTextArea
        placeholder="Write your answer here."
        onChange={(event) => setInputValue({ ...inputValue, correctAnswers: event.target.value })}
        value={inputValue.correctAnswers}
      />{" "}
      <>
        <Divider sx={{ borderColor: "black", margin: "10px 0px" }} />
        <CustomAccordion
          first={
            <Dropdown
              onChange={(event) => setInputValue({ ...inputValue, score: event.target.value })}
              value={inputValue.score}
            >
              {scoreLists?.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item === "attempt"
                    ? "Attempt and get credits"
                    : item === "score"
                      ? "Attempt correctly and get credits"
                      : "No credits for this question"}
                </MenuItem>
              ))}
            </Dropdown>
          }
          second={
            <Dropdown
              onChange={(event) => setInputValue({ ...inputValue, credit: event.target.value })}
              value={inputValue.credit}
            >
              {creditPoints?.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              ))}
            </Dropdown>
          }
          third={
            <CheckboxInput
              label="Highlight if responded incorrectlyinfo"
              onChange={(event) =>
                setInputValue({
                  ...inputValue,
                  checked: event.target.checked,
                })
              }
              value={!inputValue.checked}
            />
          }
          fourth={
            <CheckboxInput
              label="Mark As Required"
              onChange={(event) =>
                setInputValue({
                  ...inputValue,
                  is_required: event.target.checked,
                })
              }
              value={!inputValue.is_required}
            />
          }
        />
      </>
      <Divider sx={{ borderColor: "black", margin: "10px 0px" }} />
      <ButtonMain className="buttonGreen" onClick={handleSubmit} disabled={disable}>
        Save Block
      </ButtonMain>
      <CustomizedSnackbars
        AlertText={errText}
        AlertType={alertType}
        open={open}
        onClose={() => setOpen(false)}
      />
    </div>
  );
};

export default AddAITextResponse;
