import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { POST } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import { useQueryParams } from "../../../utils";
import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as GoBackIcon } from "../../../assets/goBack.svg";
import { ReactComponent as IncorrectAnswerIcon } from "../../../assets/WrongAnswer.svg";
import { useState, useEffect } from "react";
import MobileIncorrectBlocks from "./MobileIncorrectBlocks";
import MobileNavigateItem from "./MobileNavigateItem";

export default function MobileNavigate({
  modDetails,
  setCourseNav,
  open,
  setOpen,
  courseId,
  userDetails,
  completedModulesData,
  navTab,
  courseBlock,
}) {
  const { cid, mid } = useQueryParams();
  const [loading, setLoading] = useState(false);
  const [incorrectAnswers, setIncorrectAnswers] = useState([]);
  const [unAnsweredBlocks, setUnansweredBlocks] = useState([]);

  const handleIncorrectAnswers = async () => {
    const module = courseBlock ? null : mid;
    try {
      const res = await POST(`${Constants.checkIncorrectBlocks}/${cid}/${module}`);
      if (res?.data?.success) {
        setIncorrectAnswers([]);
        setUnansweredBlocks([]);
        setLoading(false);
      } else {
        if (res?.data?.incorrectBlocks?.length > 0 || res?.data?.unAnsweredBlocks?.length > 0) {
          setIncorrectAnswers(res.data.incorrectBlocks);
          setUnansweredBlocks(res.data.unAnsweredBlocks);
        }
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching incorrect blocks:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleIncorrectAnswers();
  }, []);

  return (
    <div style={{ width: 320 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ borderBottom: "2px solid #8c8585" }}
      >
        <Typography variant="h5" gutterBottom sx={{ m: 2 }}>
          FramerSpace
        </Typography>
        <IconButton onClick={() => setCourseNav(false)}>
          <GoBackIcon height={"24px"} width={"28px"} fill="#166bda" />
        </IconButton>
      </Box>
      <Box>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Box display="flex" alignItems="center">
              Course Navigation
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ p: "5px" }}>
            {modDetails?.map((item, index) => (
              <Box key={index}>
                <MobileNavigateItem
                  data={item}
                  setOpen={setOpen}
                  courseId={courseId}
                  userDetails={userDetails}
                  completedModulesData={completedModulesData}
                  setCourseNav={setCourseNav}
                />
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
        {navTab && (
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
              onClick={handleIncorrectAnswers}
            >
              <Box display="flex" alignItems="center">
                Wrong & Pending responses <IncorrectAnswerIcon style={{ marginLeft: 8 }} />
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ p: "5px" }}>
              <MobileIncorrectBlocks
                incorrectBlocks={incorrectAnswers}
                unansweredblocks={unAnsweredBlocks}
                loading={loading}
                userDetails={userDetails}
                setCourseNav={setCourseNav}
                completedModulesData={completedModulesData}
              />
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
    </div>
  );
}
