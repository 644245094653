import React, { useState } from "react";
import { Grid, IconButton, Tooltip, Typography, Zoom } from "@mui/material";
import { useNavigate } from "react-router";
import { Box } from "@mui/system";

import "./Card.scss";

import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import UnarchiveOutlinedIcon from "@mui/icons-material/UnarchiveOutlined";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";

import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import LinkIcon from "@mui/icons-material/Link";
import { useDispatch } from "react-redux";
import { saveCourseDetailAction } from "../../redux/action";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import PreviewIcon from "@mui/icons-material/Preview";
import LearnersModal from "../LearnersModal";
import { environmentURL } from "../../config/BaseUrl";

const ManagedCard = ({
  data,
  handleEdit,
  tabValue,
  handleArchiveUnarchive,
  handleMembers,
  handleAssessmentDashboard,
}) => {
  const [showLearnerModal, setShowLearnerModal] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [addLearnersData, setAddLearnersData] = useState({
    cohort_id: "",
    courseId: "",
  });

  let navigate = useNavigate();
  let dispatch = useDispatch();
  const handleCreateBlock = () => {
    dispatch(saveCourseDetailAction(data));
    window.open(`/course/createblock?cid=${data?._id}`, "_blank");
  };

  const handleLearners = (data) => {
    setAddLearnersData({
      courseId: data?._id,
    });
    setShowLearnerModal(true);
  };
  return (
    <>
      <Grid container className="courseCardMain" alignItems="center">
        <Grid item md={5} className="">
          <Typography variant="h5" fontSize={"20px"} fontWeight={600}>
            {data?.title}
          </Typography>
        </Grid>
        <Grid
          item
          className="courseContentRight"
          md={7}
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          {tabValue === "moderator" && (
            <Grid container gap={2} mr={3} alignItems="center" justifyContent={"flex-end"}>
              <Tooltip
                TransitionComponent={Zoom}
                title={isCopied ? "Link Copied" : "Copy Course Link"}
              >
                <IconButton
                  className="courseIcons"
                  color="default"
                  onClick={(e) => {
                    e.stopPropagation();
                    // Copy the text inside the text field
                    navigator.clipboard.writeText(
                      `${environmentURL}/course/${data?.slug}?cid=${data._id}&tabValue=course-introduction`,
                    );
                    setIsCopied(true);
                    setTimeout(() => {
                      setIsCopied(false);
                    }, 2000);
                  }}
                  sx={{
                    transform: "rotate(-45deg)",
                  }}
                >
                  <LinkIcon />
                </IconButton>
              </Tooltip>
              <Tooltip TransitionComponent={Zoom} title="View Course">
                <IconButton
                  className="courseIcons"
                  color="default"
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(`${`/course/${data?.slug}?cid=${data._id}`}`, "_blank");
                  }}
                >
                  <LocalLibraryIcon />
                </IconButton>
              </Tooltip>
              <Tooltip TransitionComponent={Zoom} title="View as an Editor">
                <IconButton
                  className="courseIcons"
                  color="default"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCreateBlock();
                  }}
                >
                  <PreviewIcon />
                </IconButton>
              </Tooltip>
              <Tooltip TransitionComponent={Zoom} title="Edit course profile">
                <IconButton
                  color="default"
                  onClick={(e) => {
                    handleEdit(data);
                    e.stopPropagation();
                  }}
                  className="courseIcons"
                >
                  <ModeEditOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip TransitionComponent={Zoom} title="Assessment Dashboard">
                <IconButton
                  className="courseIcons"
                  color="default"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAssessmentDashboard();
                  }}
                >
                  <FeedOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip TransitionComponent={Zoom} title="Add Discussion">
                <IconButton
                  className="courseIcons"
                  color="default"
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(`/create-discussion-forum?cid=${data?._id}`, "_blank");
                  }}
                >
                  <MessageOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip TransitionComponent={Zoom} title="Manage Cohorts">
                <IconButton
                  color="default"
                  className="courseIcons"
                  onClick={(e) => {
                    navigate(`${data?.slug}/cohortManagement?cid=${data?._id}`);
                    e.stopPropagation();
                  }}
                >
                  <Diversity3Icon />
                </IconButton>
              </Tooltip>

              <Tooltip TransitionComponent={Zoom} title="Add Moderators">
                <IconButton
                  className="courseIcons"
                  color="default"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMembers(data?._id);
                  }}
                >
                  <GroupAddOutlinedIcon />
                </IconButton>
              </Tooltip>

              <Tooltip TransitionComponent={Zoom} title="Approve learner request">
                <IconButton
                  color="default"
                  className="courseIcons"
                  onClick={() => {
                    handleLearners(data);
                  }}
                >
                  <HowToRegIcon />
                </IconButton>
              </Tooltip>

              <Tooltip TransitionComponent={Zoom} title="Copy, Backup & Translate Course Content">
                <IconButton
                  color="default"
                  className="courseIcons"
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(`${`/course/export-content/${data._id}`}`, "_blank");
                  }}
                >
                  <ContentCopyOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip TransitionComponent={Zoom} title="View Payment Dashboard">
                <IconButton
                  className="courseIcons"
                  color="default"
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(`${`/course/payment-dashboard/${data._id}`}`, "_blank");
                  }}
                >
                  <PaidOutlinedIcon />
                </IconButton>
              </Tooltip>

              <Tooltip TransitionComponent={Zoom} title="Archive course">
                <IconButton
                  className="courseIcons"
                  color="default"
                  onClick={(e) => {
                    handleArchiveUnarchive("archive", data?._id);
                    e.stopPropagation();
                  }}
                >
                  <ArchiveOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          {tabValue === "facilitator" && (
            <Box flex={"row"} alignItems="center" className="managedIconContainer">
              <Tooltip TransitionComponent={Zoom} title="Cohort Management">
                <IconButton
                  className="courseIcons"
                  color="default"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`${data?.slug}/cohortManagement?cid=${data?._id}`);
                  }}
                >
                  <Diversity3Icon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          {tabValue === "archived" && (
            <Box flex={"row"} alignItems="center" className="managedIconContainer">
              <Tooltip TransitionComponent={Zoom} title="Restore">
                <IconButton
                  className="courseIcons"
                  color="default"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleArchiveUnarchive("unarchive", data?._id);
                  }}
                >
                  <UnarchiveOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Grid>
      </Grid>
      <LearnersModal
        addLearnersData={addLearnersData}
        showLearnerModal={showLearnerModal}
        setShowLearnerModal={setShowLearnerModal}
        cohortType="self_directed"
      />
    </>
  );
};

export default ManagedCard;
