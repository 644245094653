import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, CircularProgress, Grid, IconButton, Paper, Divider } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useLocation, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { POST } from "../../../../config/Api";
import { Constants, userRoles } from "../../../../config/Constants";
import { useQueryParams } from "../../../../utils";
import ButtonMain from "../../../ButtonMain";
import BlockActions from "../BlockActions/BlockActions";
import "./MatchView.scss";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";

import { ToastNotify } from "../../../SnackBar/ToastNotify";
import { cloudfrontUrl } from "../../../../config/BaseUrl";
import ArtView from "../ArtView/ArtView";
import LearnerProperties from "../../LearnerProperties";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import clickEvent from "../../../../utils/logging-event-utils";
import sendDataToFirehose from "../../../../utils/aws-utils";
import { ReactComponent as MatchIcon } from "../../../../assets/MatchIcon.svg";
import { ReactComponent as DragDrop } from "../../../../assets/dragDrop.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/closeIcon.svg";
import ResetIcon from "@mui/icons-material/SettingsBackupRestore";

const MenuIconWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 10,
  right: 10,
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "right",
  justifyContent: "right",
  color: theme.palette.common.white,
}));

const lastItem = (arr) => {
  return arr[arr.length - 1];
};

const shuffleArray = (array) => {
  const shuffledArray = [...array]; // Create a copy to avoid mutating the original
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

function AdvancedMatchView({
  match,
  courseDetails,
  blockUserActivities,
  setmodalOpen,
  setblockModOpen,
  block,
  disableBlock,
  handleBlockAnswer,
  isHighlight,
}) {
  const [options, setOptions] = useState([]); // Pool of draggable options
  const [rightOptions, setRightOptions] = useState([]); // Right input fields for matches
  const [leftOptions, setLeftOptions] = useState([]);
  let UserData = useSelector((state) => state.userLogin);
  const { cid } = useQueryParams();
  const { userDetails, completedModulesData, courseOne } = useOutletContext() || {};
  const [finalResponse, setFinalResponse] = useState([]);
  const isMobile = window.innerWidth <= 600; // Check for mobile view
  const [disabled, setDisabled] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(block?.is_hidden);
  const [res, setRes] = useState([{}]);
  const [loading, setloading] = useState(false);
  const [checkUserInputs, setcheckUserInputs] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [blockActivity, setBlockActivity] = useState({});
  const [showActions, setShowActions] = useState(false);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const open = Boolean(anchorEl);
  const handleBlockProperties = (event) => {
    setAnchorEl(event.currentTarget);
  };

  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};
  let moduleDetails = useSelector((state) => state.savedModuleDetail);

  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);
  const toastRef = useRef();

  let location = useLocation();
  useEffect(() => {
    if (
      location.pathname.startsWith("/course/createblock") ||
      location.state?.userData?.role === userRoles.REVIEWER
    ) {
      setDisabled(true);
    }
  }, [location]);

  useEffect(() => {
    const data = blockUserActivities?.data?.find((item) => item.block_id === match._id);
    if (data) {
      setBlockActivity(data);
      let matchedPairIds = [];
      // Map the matched pairs to initialize rightOptions
      const initialRightOptions = leftOptions?.map((leftOption) => {
        const matchedPair = data?.matched_array.find((pair) => pair.left_option === leftOption._id);
        if (matchedPair) {
          matchedPairIds = matchedPairIds.concat(matchedPair.right_option); // Collect matched IDs
          return matchedPair.right_option.map((rightId) =>
            match?.options.find((option) => option._id === rightId),
          );
        }
        return [];
      });

      // Filter out matched options from the options list
      const filteredOptions = options.filter((option) => !matchedPairIds.includes(option._id));

      setOptions(filteredOptions);
      setRightOptions(initialRightOptions);
      setcheckUserInputs(true);
    } else {
      setRightOptions(leftOptions.map(() => [])); // Empty right options if no data
      setcheckUserInputs(false);
    }
  }, [blockUserActivities, match, leftOptions]);

  const handleMatchSubmit = async (finalresponse) => {
    setDisabled(true);
    setloading(true);

    try {
      let body = {
        user_id: UserData?.data?.user?.id,
        course_id: cid,
        activity: "match",
        matched_array: finalresponse,
      };
      //Submit match block answers
      await POST(`${Constants.saveAndUpdateUserActivities}/${match?._id}`, body);
      toastRef.current.showToastMessage(
        "Your response has been submitted successfully.",
        "success",
      );
      setcheckUserInputs(true);
      handleBlockAnswer(match?._id);
      setDisabled(false);
      setloading(false);
      //save user last activity
      await POST(
        `${Constants.userActivity}?activity=${
          checkUserInputs ? "blockActivityUpdate" : "blockActivitySave"
        }`,
        {
          user: UserData?.data?.user,
        },
      );
      triggerUserEvent(checkUserInputs ? "update" : "save");
    } catch (error) {
      toastRef.current.showToastMessage("Something went wrong.", "error");
    }
  };
  const onSaveData = () => {
    if (isLoggedIn) {
      // Convert the `res` state to the final response
      const finalresponse = finalRes(lastItem(res));

      // Filter out any pairs that don't have right options (i.e., no match yet)
      const dbData = finalresponse?.reduce((acc, curr) => {
        if (curr.rightOptions?.length > 0) {
          acc.push(curr); // Only add pairs with right options
        }
        return acc;
      }, []);
      setFinalResponse(dbData);
      // Now pass the matched data to the backend for saving
      handleMatchSubmit(dbData);
    } else {
      // Redirect to the login page if the user is not logged in
      window.location.replace(
        `${process.env.REACT_APP_AUTH_URL_FE}?referrer=${window.location.href}`,
      );
    }
  };

  const finalRes = (obj) => {
    const arr = Object.keys(obj);
    const resArr = [];
    for (let key of arr) {
      resArr.push({
        leftOption: key, // Left option
        rightOptions: obj[key], // Right options
      });
    }
    return resArr;
  };

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setShowActions(!showActions);
  };

  const triggerUserEvent = (action) => {
    if (user?.email && moduleDetails?.data?.containerTitle) {
      const eventData = new clickEvent();
      eventData.event = "user_input";
      eventData.user_unique_id = user.email;
      eventData.parent_container_id = moduleDetails.data.id;
      eventData.block_id = block._id;
      eventData.course_id = courseOne._id;
      eventData.cohort_id = completedModulesData?.cohortDetails?._id;
      eventData.course_name = courseOne?.title;
      eventData.course_type = courseOne?.privacy;
      eventData.cohort_name = completedModulesData?.cohortDetails?.title;
      eventData.cohort_type = completedModulesData?.cohortDetails?.type;
      eventData.block_title = moduleDetails?.data?.containerTitle;
      eventData.block_action = action;
      eventData.user_response = finalResponse;
      eventData.block_type = block?.type;

      // Ensure event tracking is enabled before sending
      if (completedModulesData?.cohortDetails?.eventTracking) {
        eventData.validateAndSendEvent(sendDataToFirehose);
      }
    }
  };

  useEffect(() => {
    // Initialize the draggable options and right input fields
    const filteredOptions = match?.options?.filter((item) => item.is_optionb) || [];
    const filteredLeftOptions = match?.options?.filter((item) => item.is_optionb === false) || [];
    setLeftOptions(filteredLeftOptions);
    const shuffled = shuffleArray(filteredOptions);
    const emptyInputs = new Array(filteredLeftOptions?.length || 0).fill(null); // Create empty input fields

    setRightOptions(emptyInputs);
    setOptions(shuffled);
  }, [match]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const updatedOptions = [...options];
    const updatedRightOptions = [...rightOptions];

    // Dragging from options pool to right input
    if (
      result.source.droppableId === "options" &&
      result.destination.droppableId.startsWith("input")
    ) {
      const draggedOption = updatedOptions[result.source.index];
      updatedOptions.splice(result.source.index, 1); // Remove from options pool

      const destinationIndex = parseInt(result.destination.droppableId.split("-")[1], 10);
      if (!updatedRightOptions[destinationIndex]) {
        updatedRightOptions[destinationIndex] = []; // Initialize array if it's not already
      }
      updatedRightOptions[destinationIndex].push(draggedOption); // Add the dragged option to the corresponding index
    }

    setOptions(updatedOptions);
    setRightOptions(updatedRightOptions);

    // Update the `res` state (left-right pairs)
    const newRes = updatedRightOptions?.reduce((acc, rightOption, index) => {
      if (rightOption?.length > 0) {
        const leftOption = leftOptions[index];
        acc[leftOption._id] = acc[leftOption._id] || [];
        acc[leftOption._id].push(...rightOption?.map((item) => item._id)); // Push all items in the right option array
      }
      return acc;
    }, {});

    setRes([newRes]);
  };

  const handleRemoveFromRightOptions = (index, item) => {
    const updatedRightOptions = [...rightOptions];
    const itemIndex = updatedRightOptions[index].indexOf(item);
    if (itemIndex !== -1) {
      updatedRightOptions[index].splice(itemIndex, 1); // Remove the item from the right option array
    }

    const updatedOptions = [...options, item]; // Add the removed item back to the options pool

    setRightOptions(updatedRightOptions);
    setOptions(updatedOptions);
  };

  const handleImageClick = (imageSrc) => {
    setCurrentImage(imageSrc);
    setIsLightboxOpen(true);
  };

  const getGridColumns = (width) => {
    if (width <= 70) return 6; // 2 columns (6 out of 12)
    return 4; // 3 columns (4 out of 12)
  };

  const columnSize = getGridColumns(match.size.width);

  const handleReset = () => {
    // Reset options to the initial shuffled list
    const filteredOptions = match?.options?.filter((item) => item.is_optionb) || [];
    const shuffled = shuffleArray(filteredOptions);
    setOptions(shuffled);

    // Clear the rightOptions list
    setRightOptions(new Array(leftOptions?.length || 0).fill(null));

    // Reset the `res` state (left-right pairs)
    setRes([{}]);
  };

  return (
    <>
      {(isModerator === -1 || isModerator === undefined) && block?.is_hidden ? (
        ""
      ) : (
        <Box className="match-view">
          <Box
            className={`${isHighlight && "highlightBlock"} ${disableBlock ? "disableBlock" : ""} ${
              match?.theme ? match?.theme : "postBgColor"
            }`}
            sx={{
              background: `${match?.theme_type === "color" ? match?.color : ""}`,
              p: 2,
            }}
            onMouseOver={handleHover}
            onMouseLeave={handleMouseLeave}
          >
            {isModerator !== -1 && isLoggedIn && (isHovering || showActions) && (
              <BlockActions
                blockId={match?._id}
                block={match}
                setmodalOpen={setmodalOpen}
                setblockModOpen={setblockModOpen}
                hidden={hidden}
                setHidden={setHidden}
              />
            )}

            {isModerator !== -1 && block?.carousel_container && (
              <Image>
                <Tooltip title="This Block Tagged to Carousel Block">
                  <LocalOfferRoundedIcon fontSize="large" color="primary" />
                </Tooltip>
              </Image>
            )}

            {isMobile && (
              <MenuIconWrapper onClick={handleMenuClick}>
                <MoreVertIcon fontSize="large" color="action" />
              </MenuIconWrapper>
            )}

            <Typography variant="h6">
              {match.is_required && <span className="required">*</span>}
              {match?.title}
            </Typography>
            <Typography
              sx={{
                textAlign: "left",
                font: "italic normal normal 15px/24px Segoe UI",
                letterSpacing: "0px",
                color: "#9E9E9E",
                mb: 1,
              }}
            >
              {" "}
              (Drag and drop into the appropriate area below)
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between", // Align text and icon to opposite ends
                alignItems: "center", // Vertically center the icon with the text
                width: "100%", // Ensure the Box takes full width
              }}
            >
              {match?.text && (
                <Typography
                  sx={{ flex: 1 }}
                  dangerouslySetInnerHTML={{ __html: match?.text }}
                ></Typography>
              )}
              <Tooltip title="Reset Options" placement="bottom">
                <Box
                  display="flex"
                  alignItems="center"
                  onClick={handleReset}
                  sx={{ cursor: "pointer", border: "2px solid #ccc", p: 0.4, mr: 3 }}
                >
                  Reset
                  <ResetIcon
                    sx={{
                      color: "#232323",
                      ml: "2px",
                    }}
                  />
                </Box>
              </Tooltip>
            </Box>

            <DragDropContext onDragEnd={handleDragEnd}>
              <Grid container spacing={2} alignItems="center" sx={{ p: { xs: 0, sm: 2 } }}>
                <Droppable droppableId="options" direction="horizontal">
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Grid container sx={{ justifyContent: "center", mt: 1 }}>
                        {options.map((option, index) => (
                          <Draggable key={option._id} draggableId={option._id} index={index}>
                            {(provided) => (
                              <Grid
                                item
                                sm={columnSize}
                                xs={12}
                                key={index}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column", // Stack option and input box vertically
                                  alignItems: "center",
                                  p: 1,
                                }}
                              >
                                <Paper
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  sx={{
                                    cursor: "pointer",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    borderRadius: "10px",
                                    width: "100%",
                                    height: 80,
                                    backgroundColor: "#E4EEFA",
                                    boxShadow: "0px 2px 3px #00000029",
                                    opacity: 1,
                                  }}
                                >
                                  <Box
                                    {...provided.dragHandleProps}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: "28px",
                                      height: "100%",
                                      backgroundColor: "#B1C6DF", // White background for the handle
                                      borderRadius: "4px 0 0 4px", // Rounded corners on the left
                                      mr: 1, // Margin to separate handle from text
                                    }}
                                  >
                                    <DragDrop />
                                  </Box>

                                  {/* Image */}
                                  {option?.image && (
                                    <img
                                      onClick={() =>
                                        handleImageClick(
                                          option?.image?.m?.includes(cloudfrontUrl)
                                            ? option?.image?.m
                                            : cloudfrontUrl + option?.image?.m,
                                        )
                                      }
                                      src={
                                        option?.image?.m?.includes(cloudfrontUrl)
                                          ? option?.image?.m
                                          : cloudfrontUrl + option?.image?.m
                                      }
                                      alt={option?.text}
                                      style={{
                                        width: option?.text ? "60px" : "110px", // Make the image responsive
                                        height: option?.text ? "40px" : "70px", // Maintain the aspect ratio
                                        objectFit: "contain", // Ensure the image fits within the container
                                      }}
                                    />
                                  )}
                                  {/* Text */}
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      flex: 1,
                                      textAlign: "left",
                                      display: "-webkit-box",
                                      overflow: "hidden",
                                      WebkitBoxOrient: "vertical",
                                      WebkitLineClamp: 4,
                                      lineClamp: 4,
                                      ml: 1,
                                    }}
                                  >
                                    {option?.text}
                                  </Typography>
                                </Paper>
                              </Grid>
                            )}
                          </Draggable>
                        ))}
                      </Grid>
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>

                <Paper
                  variant="outlined"
                  sx={{
                    p: 0.2,
                    width: "100%",
                    m: 1,
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    boxShadow: "inset 0px 0px 6px #0000004F",
                    borderRadius: "10px",
                    opacity: 1,
                  }}
                />

                {match?.mtf_style === "Vertical" ? (
                  <>
                    <Grid
                      container
                      spacing={1}
                      sx={{ pl: 1, pr: 1, display: "flex", alignItems: "center" }}
                    >
                      {/* Left Option */}

                      <Grid item sm={5.7} xs={5.4}>
                        <Paper
                          variant="outlined"
                          sx={{
                            backgroundColor: "#000",
                            width: "100%",
                            p: "6px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography sx={{ color: "#fff", textAlign: "right" }}>Items</Typography>
                        </Paper>
                      </Grid>
                      <Grid item sm={0.6} xs={1.1}></Grid>
                      <Grid item sm={5.7} xs={5.4} sx={{ justifyContent: "right" }}>
                        <Paper
                          variant="outlined"
                          sx={{
                            backgroundColor: "#000",
                            width: "100%",
                            p: "6px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography sx={{ color: "#fff", textAlign: "left" }}>Matches</Typography>
                        </Paper>
                      </Grid>
                    </Grid>

                    {leftOptions?.map((leftOption, index) => (
                      <Grid
                        container
                        key={index}
                        spacing={1}
                        alignItems="center"
                        sx={{ pl: 1, pr: 1, mb: { xs: "1rem", sm: 0 } }}
                      >
                        {/* Left Option */}
                        <Grid item sm={5.7} xs={12} mt={0.6}>
                          <Paper
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "left",
                              p: 1.5,
                              background: "#EAECED 0% 0% no-repeat padding-box",
                              width: "100%",
                              borderRadius: "10px",
                              boxShadow: "0px 2px 3px #00000029",
                              height: 80,
                              opacity: 1,
                            }}
                          >
                            {leftOption?.image?.m && (
                              <img
                                onClick={() =>
                                  handleImageClick(
                                    leftOption?.image?.m?.includes(cloudfrontUrl)
                                      ? leftOption?.image?.m
                                      : cloudfrontUrl + leftOption?.image?.m,
                                  )
                                }
                                src={
                                  leftOption?.image?.m?.includes(cloudfrontUrl)
                                    ? leftOption?.image?.m
                                    : cloudfrontUrl + leftOption?.image?.m
                                }
                                alt={""}
                                style={{
                                  width: leftOption?.text ? "60px" : "110px", // Make the image responsive
                                  height: leftOption?.text ? "40px" : "70px",
                                  objectFit: "contain", // Ensure the image fits within the container
                                  cursor: "pointer",
                                }}
                              />
                            )}
                            {leftOption?.text && (
                              <Typography
                                variant="body2"
                                sx={{
                                  flex: 1,
                                  textAlign: "left",
                                  display: "-webkit-box",
                                  overflow: "hidden",
                                  WebkitBoxOrient: "vertical",
                                  WebkitLineClamp: 4, // Limit to 3 lines
                                  lineClamp: 4, // For broader compatibility
                                }}
                              >
                                {leftOption.text}
                              </Typography>
                            )}
                          </Paper>
                        </Grid>

                        {/* Arrow Icon */}
                        <Grid
                          item
                          sm={0.6}
                          sx={{
                            display: { sm: "flex", xs: "none" },
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <MatchIcon />
                        </Grid>

                        {/* Right Droppable Fields */}
                        <Grid item sm={5.7} xs={12} sx={{ mt: 1 }}>
                          <Droppable
                            droppableId={`input-${index}`}
                            isDropDisabled={rightOptions[index]?.length === 1}
                          >
                            {(provided) => (
                              <Box
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                sx={{
                                  border: "1px dashed #707070",
                                  borderRadius: "10px",
                                  opacity: 1,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: rightOptions[index]?.length ? "left" : "center",
                                  width: "100%",
                                  height: 80,
                                  background: "#F6F6F6 0% 0% no-repeat padding-box",
                                  //backgroundColor: "#ced7ce",
                                }}
                              >
                                {rightOptions[index]?.length ? (
                                  rightOptions[index]?.map((rightOption) => (
                                    <>
                                      <Paper
                                        sx={{
                                          cursor: "pointer",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          borderRadius: "10px",
                                          width: "100%",
                                          height: 70,
                                          background: "#E4EEFA 0% 0% no-repeat padding-box",
                                          boxShadow: "0px 2px 3px #00000029",
                                          opacity: 1,
                                          m: 1,
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column", // Stack items vertically
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "28px",
                                            height: "100%",
                                            backgroundColor: "#B1C6DF", // White background for the handle
                                            borderRadius: "4px 0 0 4px", // Rounded corners on the left
                                            mr: 1, // Margin to separate handle from text
                                          }}
                                        >
                                          <DragDrop />
                                        </Box>

                                        {/* Image */}
                                        {rightOption?.image?.m && (
                                          <img
                                            onClick={() =>
                                              handleImageClick(
                                                rightOption?.image?.m?.includes(cloudfrontUrl)
                                                  ? rightOption?.image?.m
                                                  : cloudfrontUrl + rightOption?.image?.m,
                                              )
                                            }
                                            src={
                                              rightOption?.image?.m?.includes(cloudfrontUrl)
                                                ? rightOption?.image?.m
                                                : cloudfrontUrl + rightOption?.image?.m
                                            }
                                            alt={rightOption?.text}
                                            style={{
                                              width: rightOption?.text ? "50px" : "100px", // Make the image responsive
                                              height: rightOption?.text ? "30px" : "publids",
                                              objectFit: "contain", // Ensure the image fits within the container
                                            }}
                                          />
                                        )}
                                        {/* Text */}
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            flex: 1,
                                            textAlign: "left",
                                            display: "-webkit-box",
                                            overflow: "hidden",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 3,
                                            lineClamp: 3,
                                            ml: "4px",
                                          }}
                                        >
                                          {rightOption?.text}
                                        </Typography>

                                        <Divider
                                          orientation="vertical"
                                          variant="middle"
                                          flexItem
                                          sx={{
                                            backgroundColor: "#B1C6DF",
                                            mr: 1,
                                            ml: "4px",
                                            mt: "18px",
                                            height: "2.1rem",
                                          }}
                                        />

                                        <CloseIcon
                                          onClick={() =>
                                            handleRemoveFromRightOptions(index, rightOption)
                                          }
                                          style={{
                                            marginRight: "10px",
                                            opacity: 1,
                                            cursor: "pointer",
                                          }}
                                        />
                                      </Paper>
                                    </>
                                  ))
                                ) : (
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: "#707070",
                                      fontStyle: "italic",
                                    }}
                                  >
                                    Please drop answer here
                                  </Typography>
                                )}
                                {provided.placeholder}
                              </Box>
                            )}
                          </Droppable>
                        </Grid>
                      </Grid>
                    ))}
                  </>
                ) : (
                  <Box sx={{ width: "100%", p: 1 }}>
                    <Paper
                      variant="outlined"
                      sx={{
                        backgroundColor: "#000",
                        width: "100%",
                        mb: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "#2F2F37 0% 0% no-repeat padding-box",
                        boxShadow: "0px 2px 3px #00000029",
                        borderRadius: "6px",
                        opacity: 1,
                      }}
                    >
                      <Grid container spacing={1} alignItems="center">
                        {/* Left Option */}

                        <Grid item sm={5.8} xs={5.7}>
                          {/* Left Option */}
                          <Typography sx={{ color: "#fff", textAlign: "right" }}>Items</Typography>
                        </Grid>
                        <Grid item sm={0.2} xs={0.2}>
                          <Typography sx={{ color: "gray", fontSize: "2rem", mx: 1 }}>/</Typography>
                        </Grid>
                        <Grid item sm={5.8} xs={5.7}>
                          <Typography
                            sx={{ color: "#fff", textAlign: "left", mx: { xs: 3, sm: 1 } }}
                          >
                            Matches
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>

                    <Grid container spacing={2} sx={{ justifyContent: "center" }}>
                      {leftOptions?.map((leftOption, index) => (
                        <Grid
                          item
                          sm={6}
                          xs={12}
                          key={index}
                          sx={{
                            display: "flex",
                            flexDirection: "column", // Stack option and input box vertically
                            alignItems: "center",
                          }}
                        >
                          <Paper
                            sx={{
                              padding: 1,
                              display: "flex",
                              background: "#EAECED 0% 0% no-repeat padding-box",
                              border: "1px solid #C0BEBE",
                              opacity: 1,
                              borderRadius: 0,
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                              height: 80,
                              overflow: "hidden",
                            }}
                          >
                            {leftOption?.image?.m && (
                              <img
                                onClick={() =>
                                  handleImageClick(
                                    leftOption?.image?.m?.includes(cloudfrontUrl)
                                      ? leftOption?.image?.m
                                      : cloudfrontUrl + leftOption?.image?.m,
                                  )
                                }
                                src={
                                  leftOption?.image?.m?.includes(cloudfrontUrl)
                                    ? leftOption?.image?.m
                                    : cloudfrontUrl + leftOption?.image?.m
                                }
                                alt={leftOption?.text}
                                style={{
                                  width: leftOption?.text ? "50px" : "110px",
                                  height: leftOption?.text ? "30px" : "70px",
                                  objectFit: "contain",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                            <Typography
                              variant="body2"
                              sx={{
                                flex: 1,
                                textAlign: "left",
                                display: "-webkit-box",
                                overflow: "hidden",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 3, // Limit to 3 lines
                                lineClamp: 3, // For broader compatibility
                              }}
                            >
                              {leftOption.text}
                            </Typography>
                          </Paper>
                          {/* Input Box Below */}
                          <Droppable
                            droppableId={`input-${index}`}
                            isDropDisabled={rightOptions[index]?.length === 1}
                          >
                            {(provided) => (
                              <Box
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                sx={{
                                  minHeight: 90,
                                  display: "flex",
                                  flexWrap: "wrap",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  overflow: "hidden",
                                  width: "100%",

                                  background: "#F9F9F9 0% 0% no-repeat padding-box",
                                  border: "1px dashed #707070",
                                  opacity: 1,
                                  //maxWidth: 410,
                                }}
                              >
                                {rightOptions[index]?.length ? (
                                  rightOptions[index]?.map((rightOption) => (
                                    <>
                                      <Paper
                                        elevation={0}
                                        key={rightOption?._id}
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          backgroundColor: "#E4EEFA",
                                          borderRadius: "4px",
                                          width: "100%",
                                          height: 80,
                                          overflow: "hidden",
                                          m: 1,
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column", // Stack items vertically
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "28px",
                                            height: "100%",
                                            backgroundColor: "#B1C6DF", // White background for the handle
                                            borderRadius: "4px 0 0 4px", // Rounded corners on the left
                                            mr: "1px",
                                          }}
                                        >
                                          <DragDrop />
                                        </Box>
                                        {rightOption?.image?.m && (
                                          <img
                                            onClick={() =>
                                              handleImageClick(
                                                rightOption?.image?.m?.includes(cloudfrontUrl)
                                                  ? rightOption?.image?.m
                                                  : cloudfrontUrl + rightOption?.image?.m,
                                              )
                                            }
                                            src={
                                              rightOption?.image?.m?.includes(cloudfrontUrl)
                                                ? rightOption?.image?.m
                                                : cloudfrontUrl + rightOption?.image?.m
                                            }
                                            alt={rightOption?.text}
                                            style={{
                                              width: rightOption?.text ? "50px" : "110px", // Make the image responsive
                                              height: rightOption?.text ? "30px" : "70px",
                                              objectFit: "contain",
                                              cursor: "pointer",
                                            }}
                                          />
                                        )}

                                        <Typography
                                          variant="body2"
                                          sx={{
                                            flex: 1,
                                            ml: "4px",
                                            textAlign: "left",
                                            display: "-webkit-box",
                                            overflow: "hidden",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 3, // Limit to 3 lines
                                            lineClamp: 3, // For broader compatibility
                                          }}
                                        >
                                          {rightOption?.text}
                                        </Typography>

                                        <Divider
                                          orientation="vertical"
                                          variant="middle"
                                          flexItem
                                          sx={{
                                            backgroundColor: "#B1C6DF",
                                            ml: 1,
                                            mr: 1,
                                            mt: "20px",
                                            height: "2.5rem",
                                          }}
                                        />

                                        <CloseIcon
                                          onClick={() =>
                                            handleRemoveFromRightOptions(index, rightOption)
                                          }
                                          style={{
                                            marginRight: "10px",
                                            opacity: 1,
                                            cursor: "pointer",
                                          }}
                                        />
                                      </Paper>
                                    </>
                                  ))
                                ) : (
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: "#707070",
                                      fontStyle: "italic",
                                    }}
                                  >
                                    Please drop answer here
                                  </Typography>
                                )}
                                {provided.placeholder}
                              </Box>
                            )}
                          </Droppable>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                )}

                {isLightboxOpen && (
                  <Lightbox
                    mainSrc={currentImage}
                    onCloseRequest={() => setIsLightboxOpen(false)}
                    reactModalStyle={{
                      overlay: {
                        zIndex: 1310, // Ensure it renders above the modal
                      },
                    }}
                  />
                )}
              </Grid>
            </DragDropContext>
            {match?.art?.m && <ArtView art={(block || match)?.art} />}
            <ButtonMain
              onClick={() => onSaveData()}
              disabled={disabled || disableBlock || !isLoggedIn}
              style={{ padding: `${loading ? "5px 25px" : ""}` }}
            >
              {loading ? <CircularProgress size={17} /> : checkUserInputs ? "Update" : "Save"}
            </ButtonMain>
            {userDetails?.role === userRoles.REVIEWER && (
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleBlockProperties}
                sx={{ float: "right" }}
              >
                <MoreVertIcon />
              </IconButton>
            )}

            <ToastNotify ref={toastRef} duration={3000} />
            {userDetails?.role === userRoles.REVIEWER && (
              <LearnerProperties
                anchorEl={anchorEl}
                open={open}
                handleClose={() => setAnchorEl(null)}
                obtainedMark={blockActivity?.credit}
                maxMark={block?.score || 0}
                blockType={block?.type}
                attempts={blockActivity?.NoOfAttempts}
                keywords={block?.keywords}
                scoreType={block?.typeOfBlock}
              />
            )}
          </Box>
        </Box>
      )}
    </>
  );
}

export default AdvancedMatchView;
