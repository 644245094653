import axios from "axios";
import { Constants } from "./Constants";
import store from "../redux/store.js";
import { logoutAction } from "../redux/action";
import { deleteCookie } from "../utils/index.js";
let display = true;
// Token Expiration interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      let { data } = store.getState().userLogin;

      if (
        error.response.data.message === "session not found" ||
        error.response.data.message === "Your session has expired!"
      ) {
        localStorage.removeItem("userData");
        localStorage.removeItem("token");
        localStorage.removeItem("preferredLanguage");
        localStorage.removeItem("sessionTokenHash");
        localStorage.removeItem("emailVerificationModal");
        let cookieName =
          process.env.REACT_APP_SERVER_TYPE === "prod" ? "fsUserData_PROD" : "fsUserData";
        let domain =
          process.env.REACT_APP_SERVER_TYPE === "local" ? "localhost" : ".framerspace.com";
        deleteCookie(cookieName, domain);
        display &&
          alert("Your session has expired or you have been logged in from another browser.");
        display = false;
        window.location.href = "/";
        return;
      }
      if (data?.isLoggedIn) {
        store.dispatch(logoutAction());
        window.location.href = "/";
      } else {
        localStorage.removeItem("token");
        window.location.reload();
      }
    } else return Promise.reject(error);
  },
);
export const getToken = async () => {
  const CryptoJS = require("crypto-js");

  const secretKey = "apikey";
  let message = secretKey.concat("__!FM!__", "string_secret_very_is_this");
  // encrypt data
  const encodedToken = CryptoJS.AES.encrypt(message, secretKey).toString();
  // get pre login token to access API's
  const response = await axios.post(Constants.getPreLoginToken, {
    encodedToken,
  });
  localStorage.setItem("token", response?.data?.accessToken);
  return response?.data?.accessToken;
};

export const POST = async (url, body, header) => {
  let token;
  let sessionToken;
  if (!localStorage.getItem("token")) {
    token = await getToken();
  } else {
    token = localStorage.getItem("token");
    sessionToken = localStorage.getItem("sessionTokenHash");
  }
  let config = {
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": header ? header : "application/json",
      "Session-Token-Hash": sessionToken,
    },
    withCredentials: "include",
  };
  return await axios.post(url, body, config);
};
export const GET = async (url) => {
  let token, sessionToken;
  if (!localStorage.getItem("token")) {
    token = await getToken();
  } else {
    token = localStorage.getItem("token");
    sessionToken = localStorage.getItem("sessionTokenHash");
  }
  let config = {
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Session-Token-Hash": sessionToken,
    },
    withCredentials: "include",
  };
  return await axios.get(url, config);
};

export const PUT = async (url, body) => {
  let token, sessionToken;
  if (!localStorage.getItem("token")) {
    token = await getToken();
  } else {
    token = localStorage.getItem("token");
    sessionToken = localStorage.getItem("sessionTokenHash");
  }
  let config = {
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Session-Token-Hash": sessionToken,
    },
    withCredentials: "include",
  };
  return await axios.put(url, body, config);
};
